import clsx from 'clsx';
import PropTypes from 'prop-types';

import RadioButton from './RadioButton';

const RadioGroup = ({ value, options, className, onChange }) => {
	return (
		<div className={clsx('flex flex-col', className.root)}>
			{options.map((o) => (
				<RadioButton
					key={o.value}
					checked={value === o.value}
					label={o.label}
					onChange={() => onChange(o.value)}
					className={
						clsx(value === o.value ? className.active : className.radio) ||
						undefined
					}
				/>
			))}
		</div>
	);
};

RadioGroup.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	),
	className: PropTypes.shape({
		root: PropTypes.string,
		radio: PropTypes.string,
		active: PropTypes.string,
	}),
	onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
	value: '',
	options: [],
	className: { root: 'space-y-1' },
	onChange: () => {},
};

export default RadioGroup;
