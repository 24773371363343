import { useQueryClient } from 'react-query';
import { constructChannelQueryKey } from '../../strategy/v2/utils';

import useChannelStore from '../data/channels';

const useChannelPrefetch = () => {
	const queryClient = useQueryClient();
	const activeChannel = useChannelStore((s) => s.activeChannel);

	return (queryKey, ...params) => {
		let key = queryKey;
		if (!Array.isArray(queryKey)) {
			key = [queryKey];
		}

		queryClient.prefetchQuery(
			constructChannelQueryKey(activeChannel, key),
			...params
		);
	};
};

export default useChannelPrefetch;
