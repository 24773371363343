import { FunctionComponent } from 'react';
import Nav from '../../../shared/components/Nav/Nav';

export interface TabLink {
	id: string;
	label: string;
	to: string;
}

interface Props {
	tabs: TabLink[];
}

const Tabs: FunctionComponent<Props> = ({ tabs }) => {
	return <Nav items={tabs} />;
};

export default Tabs;
