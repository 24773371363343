import { QueryKey } from 'react-query';
import { paths } from '../../../../types/backend-api';
import { MarkdownBucketDataType } from '../../../recommendations/types/MarkdownTransferTypes';
import useChannelQuery from '../../hooks/useChannelQuery';
import api from '../api';

export type GetMarkdownBucketsReturnType =
	paths['/v2/recommendations/{strategy_id}/markdown_buckets']['get']['responses']['200']['content']['application/json'];

// TODO: does filters need to be a urlsearchparam? I think it cause empty filters being used as a query to use the same cache.
export type GetMarkdownBucketParams = {
	filters?: URLSearchParams;
} & paths['/v2/recommendations/{strategy_id}/markdown_buckets']['get']['parameters']['path'];

export const useMarkdownBucketsQuery = (params: GetMarkdownBucketParams) => {
	return useChannelQuery<
		MarkdownBucketDataType[],
		[],
		MarkdownBucketDataType[]
	>(constructMarkdownBucketsQueryKey(params), () => GET_BUCKETS(params), {
		staleTime: 5 * 60 * 1000,
		retry: 10,
	});
};

const GET_BUCKETS: (
	params: GetMarkdownBucketParams
) => Promise<GetMarkdownBucketsReturnType> = (params) => {
	return api
		.get(`v2/recommendations/${params.strategy_id}/markdown_buckets`, {
			searchParams: params.filters,
		})
		.json();
};

export const constructMarkdownBucketsQueryKey = (
	params: GetMarkdownBucketParams
): QueryKey => {
	const querykey = [
		'buckets',
		params.strategy_id,
		Array.from(params.filters?.entries() ?? []),
	];
	return querykey;
};
