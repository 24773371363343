import PropTypes from 'prop-types';

const NextStep = ({ className, style }) => {
	return (
		<svg
			className={className}
			style={style}
			xmlns="http://www.w3.org/2000/svg"
			width="9"
			height="9"
			fill="none"
			viewBox="0 0 9 9"
		>
			<rect width="8" height="8" y="0.5" fill="currentColor" rx="4" />
		</svg>
	);
};

NextStep.propTypes = {
	className: PropTypes.string,
	style: PropTypes.any,
};

NextStep.defaultProps = {
	className: '',
};

export default NextStep;
