import * as React from 'react';

import { cn } from '../../utils/styles';
import Button, { ButtonProps } from '../Button/Button';
import TrashIcon from '../Icons/Trash';
import Tooltip from '../Tooltip/Tooltip';

type DeleteButtonProps = ButtonProps & {
	tooltip?: string;
	iconClassName?: string;
};

export const DeleteButton = React.forwardRef<
	HTMLButtonElement,
	React.PropsWithoutRef<DeleteButtonProps>
>(
	(
		{ onClick, className, tooltip, iconClassName, ...rest }: DeleteButtonProps,
		ref: any
	) => {
		const [isActive, setActive] = React.useState(false);

		const handleClick = () => {
			if (!isActive) {
				setActive(true);
				return;
			}
			onClick?.();
		};

		const handleBlur = () => {
			if (isActive) {
				setActive(false);
			}
		};

		return (
			<Tooltip content={tooltip}>
				<Button
					ref={ref}
					title="Delete"
					variant={isActive ? 'danger' : 'unstyled'}
					size="small"
					className={cn(
						'flex flex-row items-center gap-3 whitespace-nowrap !transition-all w-9 h-8 justify-center overflow-hidden',
						isActive && 'w-30',
						className
					)}
					onClick={handleClick}
					onBlur={handleBlur}
					{...rest}
				>
					{isActive ? (
						<span className="mt-0.25 text-white text-xxs transition-all">
							Are you sure?
						</span>
					) : null}
					<TrashIcon
						className={cn(
							'h-3.5 w-auto transition-all',
							iconClassName,
							isActive ? 'text-white' : 'text-ca-gray-500'
						)}
					/>
				</Button>
			</Tooltip>
		);
	}
);
