import { QueryKey, useQuery, UseQueryResult } from 'react-query';

import { constructChannelQueryKey } from '../../strategy/v2/utils';
import useChannelStore from '../data/channels';

type UseChannelQueryResult<TError = unknown, TData = unknown> = UseQueryResult<
	TData,
	TError
> & {
	channel: string;
	queryKey: QueryKey;
};

// wrapper for useQueries that ensures caching on channel level
const useChannelQuery = <
	TQueryFnData = unknown,
	TError = unknown,
	TData = unknown
>(
	queryKey: QueryKey,
	...params: any
): UseChannelQueryResult<TError, TData> => {
	const activeChannel = useChannelStore((s) => s.activeChannel);

	const queryKeyWithActiveChannel = constructChannelQueryKey(
		activeChannel,
		queryKey
	);

	return {
		...useQuery<TQueryFnData, TError, TData>(
			queryKeyWithActiveChannel,
			...params
		),
		channel: activeChannel,
		queryKey: queryKeyWithActiveChannel,
	};
};

export default useChannelQuery;
