import { GET_CUMULIO_ACTUALS_INVENTORY_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';
import filtersToSearchParams from '../../shared/utils/filters';

const ActualsInventory = ({ filters }) => {
	return (
		<FullCumulioDashboard
			queryKey={['cumulio-actuals-inventory', Object.fromEntries(filters)]}
			ssoQuery={() =>
				GET_CUMULIO_ACTUALS_INVENTORY_SSO(filtersToSearchParams(filters))
			}
			showLoading
		/>
	);
};

export default ActualsInventory;
