import PropTypes from 'prop-types';

const TriangleRight = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 8 12"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m-.97974185 2h9.95951255c.56345708 0 1.0202293.45183958 1.0202293 1.00921194 0 .234076-.08225518.460873-.23268346.64156335l-4.97975627 5.98154758c-.35819375.43025253-1.00116345.49180353-1.43611301.13747786-.050691-.04129473-.09723314-.08733427-.13897867-.13747786l-4.97975628-5.98154758c-.35819375-.43025258-.29597088-1.06627891.13897868-1.42060456.1826629-.14880381.4119358-.23017073.64856716-.23017073z"
			fill="currentColor"
			fillRule="evenodd"
			transform="matrix(0 -1 1 0 -2 10)"
		/>
	</svg>
);

TriangleRight.propTypes = {
	className: PropTypes.string,
};

TriangleRight.defaultProps = {
	className: '',
};

export default TriangleRight;
