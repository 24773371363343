import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { paths } from '../../../../types/backend-api';
import { constructChannelQueryKey } from '../../../strategy/v2/utils';
import useChannelStore from '../../data/channels';
import { UPDATE_BUSINESS_RULES_PRIORITIES } from '../business-rules';
import { LIST_BUSINESS_RULES_QUERY_KEY } from './useBusinessRulesQuery';

export const UPDATE_BUSINESS_RULES_PRIORITY_MUTATION_KEY = [
	'businessRulesPriority',
];

type MutationParameters =
	paths['/v2/business-rules/priorities']['post']['requestBody']['content']['application/json'];
type MutationResponse =
	paths['/v2/business-rules/priorities']['post']['responses'];

const useBusinessRulesPriorityMutation = (): UseMutationResult<
	MutationResponse,
	Error,
	MutationParameters
> => {
	const queryClient = useQueryClient();
	const { activeChannel } = useChannelStore();

	return useMutation({
		mutationKey: UPDATE_BUSINESS_RULES_PRIORITY_MUTATION_KEY,
		mutationFn: UPDATE_BUSINESS_RULES_PRIORITIES as (
			keys: MutationParameters
		) => Promise<MutationResponse>,

		onMutate: (params) => {
			queryClient.cancelMutations();
			const optimisticQueryKey = [
				activeChannel,
				...LIST_BUSINESS_RULES_QUERY_KEY,
			];
			const oldListBusinessRules =
				queryClient.getQueryData<
					paths['/v2/business-rules']['get']['responses']['200']['content']['application/json']
				>(optimisticQueryKey);

			if (oldListBusinessRules === undefined) {
				return oldListBusinessRules; // shouldn't this be the same as the last return statement in this function?
			}
			queryClient.setQueryData(
				optimisticQueryKey,
				/* @ts-ignore */
				(
					old: paths['/v2/business-rules']['get']['responses']['200']['content']['application/json']
				) => {
					const newItems = [...old.items]
						.map((item) => {
							const newPriority = params.findIndex((key) => key === item.id);

							if (newPriority === -1) {
								return { ...item };
							}
							return { ...item, priority: newPriority };
						})
						.sort((a, b) => {
							const aPriority = params.findIndex((key) => key === a.id);
							const bPriority = params.findIndex((key) => key === b.id);
							return aPriority > bPriority ? 1 : -1;
						});
					return { ...old, items: newItems };
				}
			);
			return { previousBusinessRules: oldListBusinessRules };
		},

		onError: (err, keys, context) => {
			// Revert the optimistic cache update
			queryClient.setQueryData(
				constructChannelQueryKey(activeChannel, LIST_BUSINESS_RULES_QUERY_KEY),
				context?.previousBusinessRules
			);
		},
		onSettled: () => {
			// Refetch query corresponding with modified cache

			queryClient.invalidateQueries([
				activeChannel,
				...LIST_BUSINESS_RULES_QUERY_KEY,
			]);
		},
	});
};

export default useBusinessRulesPriorityMutation;
