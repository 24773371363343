import { START_ENGINE } from '../shared/api/engine';
import {
	CREATE_INVENTORY_ALLOCATION_REPORT,
	UPDATE_INVENTORY_ALLOCATION_REPORT,
} from '../shared/api/inventory-allocation-reports';
import {
	CREATE_INVENTORY_ALLOCATION,
	DELETE_INVENTORY_ALLOCATION,
	SUBMIT_INVENTORY_ALLOCATION,
	UPDATE_INVENTORY_ALLOCATION,
} from '../shared/api/inventory-allocations';
import useCreateMutation from '../shared/hooks/useCreateMutation';
import useDeleteMutation from '../shared/hooks/useDeleteMutation';
import useUpdateMutation from '../shared/hooks/useUpdateMutation';
import InventoryAllocationMapper, {
	InventoryAllocationAPIDTO,
} from './inventoryAllocations/InventoryAllocationMapper';
import useInventoryAllocationsUseCases from './inventoryAllocations/useInventoryAllocationsUseCases';
import ReportMapper, { ReportAPIDTO } from './reports/ReportMapper';
import useReportsUseCases from './reports/useReportsUseCases';

export const useReports = (
	API?: ReportAPIDTO
): ReturnType<typeof useReportsUseCases> => {
	const map = new ReportMapper();

	const createMutation = useCreateMutation(
		'reports',
		CREATE_INVENTORY_ALLOCATION_REPORT,
		'inventory-allocations'
	).mutateAsync;

	const updateMutation = useUpdateMutation(
		'reports',
		UPDATE_INVENTORY_ALLOCATION_REPORT
	).mutateAsync;

	const startEngineMutation = useUpdateMutation(
		'engine',
		START_ENGINE,
		'reports'
	).mutateAsync;

	return useReportsUseCases({
		data: map.toAPI(API),
		createMutation: map.toCreateMutation(createMutation),
		updateMutation: map.toUpdateMutation(updateMutation),
		startAlgorithmMutation: map.toStartAlgorithmMutation(startEngineMutation),
		submitMutation: map.toSubmitMutation(updateMutation),
	});
};

export const useInventoryAllocations = (
	API?: InventoryAllocationAPIDTO
): ReturnType<typeof useInventoryAllocationsUseCases> => {
	const { create: createReport, submit: submitReport } = useReports();
	const map = new InventoryAllocationMapper();

	const queryKey = 'inventory-allocations';

	const createMutation = useCreateMutation(
		queryKey,
		CREATE_INVENTORY_ALLOCATION
	).mutateAsync;

	const updateMutation = useUpdateMutation(
		queryKey,
		UPDATE_INVENTORY_ALLOCATION
	).mutateAsync;

	const deleteMutation = useDeleteMutation(
		queryKey,
		DELETE_INVENTORY_ALLOCATION
	).mutateAsync;

	const submitMutation = useCreateMutation(
		'inventory-allocations',
		SUBMIT_INVENTORY_ALLOCATION
	).mutateAsync;

	return useInventoryAllocationsUseCases({
		data: map.toAPI(API),
		createMutation: map.toCreateMutation(createMutation),
		renameMutation: map.toRenameMutation(updateMutation),
		deleteMutation: map.toDeleteMutation(deleteMutation),
		submitMutation: map.toSubmitMutation(submitMutation),
		createReport,
		submitReport,
	});
};
