import { BaseEntity } from './API';
import { InventoryAllocationReport } from './inventoryAllocationReport';

export enum InventoryAllocationStatus {
	Draft = 'Draft',
	InProgress = 'In Progress',
	Proposal = 'Proposal',
	Submitted = 'Submitted',
	NoSuggestedMoves = 'No Suggested Moves',
	Error = 'Error',
	Executed = 'Executed',
	Outdated = 'Outdated',
}

export interface InventoryAllocation extends BaseEntity {
	title: string;
	status: InventoryAllocationStatus;
	updatedOn: Date;
	report?: InventoryAllocationReport['id'];
	drafts: InventoryAllocationReport['id'][];
}
