import debounce from 'lodash.debounce';
import { FunctionComponent, useCallback } from 'react';
import {
	FormProvider,
	useFieldArray,
	UseFieldArrayAppend,
	UseFieldArrayRemove,
	useForm,
} from 'react-hook-form';
import Button from '../../../shared/components/Button/Button';
import DangerIcon from '../../../shared/components/Icons/Danger';
import CircularProgress from '../../../shared/components/Progress/CircularProgress';
import { Configuration } from '../../../shared/models/configuration';
import { DeepPartial } from '../../../shared/models/deepPartial';
import { HTMLElement } from '../../../shared/models/HTMLElement';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import ConstraintsFieldset from '../components/ConstraintsFieldset';
import MarketingExpectationsFieldset from '../components/MarketingExpectationsFieldset';
import ScopeFieldset from '../components/ScopeFieldset';
import SecondaryObjectivesFieldset from '../components/SecondaryObjectivesFieldset';

interface Props
	extends Omit<HTMLElement<HTMLFormElement>, 'onSubmit' | 'onChange'> {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	onSubmit: (configuration: Configuration) => void;
	onChange: (configuration: Configuration) => void;
	isLoading?: boolean;
	validationMessage?: string;
	defaultFilters: any[];
	defaultValues?: DeepPartial<Configuration>;
}

const ConfigurationForm: FunctionComponent<Props> = ({
	onSubmit,
	onChange,
	isLoading,
	defaultFilters,
	defaultValues,
	validationMessage = '',
	inventoryAllocationId,
	reportId,
	...rest
}) => {
	const methods = useForm<Configuration>({
		defaultValues,
	});
	const { watch, getValues, handleSubmit, control, setValue } = methods;
	const constraintsArray = useFieldArray({
		control,
		name: 'constraints',
	});
	const secondaryObjectivesArray = useFieldArray({
		control,
		name: 'secondaryObjectives',
	});
	const marketingExpectationsArray = useFieldArray({
		control,
		name: 'marketingExpectations',
	});

	const debouncedOnChange = useCallback(
		debounce(() => onChange(getValues()), 400),
		[]
	);

	const appendConstraint: UseFieldArrayAppend<Configuration, 'constraints'> = (
		value
	) => {
		constraintsArray.append(value);
		onChange(getValues());
	};

	const removeConstraint: UseFieldArrayRemove = (index) => {
		constraintsArray.remove(index);
		onChange(getValues());
	};

	const appendSecondaryObjective: UseFieldArrayAppend<
		Configuration,
		'secondaryObjectives'
	> = (value) => {
		secondaryObjectivesArray.append(value);
		onChange(getValues());
	};

	const removeSecondaryObjective: UseFieldArrayRemove = (index) => {
		secondaryObjectivesArray.remove(index);
		onChange(getValues());
	};

	const appendMarketingExpectation: UseFieldArrayAppend<
		Configuration,
		'marketingExpectations'
	> = (value) => {
		marketingExpectationsArray.append(value);
		onChange(getValues());
	};

	const removeMarketingExpectation: UseFieldArrayRemove = (index) => {
		marketingExpectationsArray.remove(index);
		onChange(getValues());
	};

	return (
		<FormProvider {...methods}>
			{validationMessage && (
				<div className="flex items-center p-3 bg-opacity-20 rounded-lg font-bold text-sm bg-ca-red text-ca-red mb-4">
					<DangerIcon className="h-5 mr-3" />
					<span className="max-w-lg">{validationMessage}</span>
				</div>
			)}
			<form
				onSubmit={handleSubmit(onSubmit)}
				onChange={debouncedOnChange}
				{...rest}
			>
				<p className="text-lg text-ca-gray">
					Optimize <strong className="text-ca-purple">revenue</strong> by
					allocating inventory.
				</p>
				<ScopeFieldset
					inventoryAllocationId={inventoryAllocationId}
					defaultFilters={defaultFilters}
					reportId={reportId}
					value={watch().scope}
					onChange={(scope) => {
						setValue('scope', scope, {
							shouldValidate: true,
							shouldDirty: true,
							shouldTouch: true,
						});
						onChange(getValues());
					}}
				/>
				<ConstraintsFieldset
					inventoryAllocationId={inventoryAllocationId}
					reportId={reportId}
					fields={constraintsArray.fields}
					append={appendConstraint}
					control={control}
					remove={removeConstraint}
				/>
				{window._ENV_.REACT_APP_SECONDARY_OBJECTIVES_ENABLED && (
					<SecondaryObjectivesFieldset
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						fields={secondaryObjectivesArray.fields}
						append={appendSecondaryObjective}
						control={control}
						remove={removeSecondaryObjective}
					/>
				)}
				{window._ENV_.REACT_APP_MARKETING_ACTIONS_ENABLED && (
					<MarketingExpectationsFieldset
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						fields={marketingExpectationsArray.fields}
						append={appendMarketingExpectation}
						control={control}
						remove={removeMarketingExpectation}
						onChange={(scope, index) => {
							setValue(`marketingExpectations.${index}.scope`, scope, {
								shouldValidate: true,
								shouldDirty: true,
								shouldTouch: true,
							});
							onChange(getValues());
						}}
					/>
				)}
				<Button
					disabled={isLoading}
					type="submit"
					className="w-64 h-10 flex items-center justify-center"
				>
					{!isLoading && <span>Calculate optimal allocations</span>}
					{isLoading && (
						<CircularProgress size="text" className="text-ca-ghost-white" />
					)}
				</Button>
			</form>
		</FormProvider>
	);
};

export default ConfigurationForm;
