import { components } from '../../../types/backend-api';

export enum EUnit {
	Products = 'products',
	Stock = 'stock',
}

export enum EElementState {
	default = 'default',
	disabled = 'disabled',
	focused = 'focused',
	loading = 'loading',
	background = 'background', // TODO change this to another name...
}

export interface PhraseObject {
	highlighted: boolean;
	text: string | null;
	replacement?: string;
}

export interface CumulioFetchIds {
	unit: EUnit;
	id: string;
}

export type MarkdownBucketDataType =
	components['schemas']['MarkdownBucketOutput'];
