import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { Route } from 'react-router-dom';
import { useReports } from '../../../domains/domains';
import { GET_INVENTORY_ALLOCATION_REPORT } from '../../../shared/api/inventory-allocation-reports';
import DangerIcon from '../../../shared/components/Icons/Danger';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import {
	InventoryAllocationReport,
	InventoryAllocationReportStatus,
} from '../../../shared/models/inventoryAllocationReport';
import { ScopeTypeDTO } from '../../../shared/models/schema';
import LocationsTable from '../components/LocationsTable';
import ProductsTable from '../components/ProductsTable';
import Tabs, { TabLink } from '../components/Tabs';
import InventoryAllocationReportConfiguration from './InventoryAllocationReportConfiguration';
import InventoryAllocationReportInProgress from './InventoryAllocationReportInProgress';
import InventoryAllocationReportMovements from './InventoryAllocationReportMovements';
import InventoryAllocationReportNoMovements from './InventoryAllocationReportNoMovements';
import InventoryAllocationReportResults from './InventoryAllocationReportResults';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	id: InventoryAllocationReport['id'];
	rootURL: string;
	isCopyingConfiguration?: boolean;
	isAddingDraft?: boolean;
	onCopyConfiguration: (configuration: Configuration) => void;
	onAddDraft: () => void;
	warningMessage: string | undefined;
}

const InventoryAllocationReportDetail: FunctionComponent<Props> = ({
	inventoryAllocationId,
	id,
	rootURL,
	isCopyingConfiguration,
	isAddingDraft,
	onCopyConfiguration,
	onAddDraft,
	warningMessage,
}) => {
	const result = useQuery(['reports', id], () =>
		GET_INVENTORY_ALLOCATION_REPORT(inventoryAllocationId, id)
	);
	const { entity: report, isLoading, startAlgorithm } = useReports(result);

	const tabs: TabLink[] = [
		{
			id: 'results',
			label: 'Results',
			to: `${rootURL}/results`,
		},
		{
			id: 'movements',
			label: 'Movements',
			to: `${rootURL}/movements`,
		},
		{
			id: 'configuration',
			label: 'Configuration',
			to: `${rootURL}/configuration`,
		},
		{
			id: 'scope',
			label: 'Scope',
			to: `${rootURL}/scope`,
		},
	];

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (!report) {
		return <p>Report not found</p>;
	}

	return (
		<>
			<div className="my-6">
				<Tabs tabs={tabs} />
			</div>
			{warningMessage && (
				<div className="flex items-center p-3 bg-opacity-20 rounded-lg font-bold text-sm bg-ca-orange text-ca-black mb-4">
					<DangerIcon className="h-5 mr-3" />
					<span className="max-w-lg">{warningMessage}</span>
				</div>
			)}
			<Route
				path={tabs[0].to}
				render={() => {
					if (report.status === InventoryAllocationReportStatus.InProgress) {
						return (
							<InventoryAllocationReportInProgress
								addDraft={onAddDraft}
								isAddingDraft={isAddingDraft}
							/>
						);
					}

					if (
						report.status === InventoryAllocationReportStatus.NoSuggestedMoves
					) {
						return (
							<InventoryAllocationReportNoMovements
								addDraft={onAddDraft}
								isAddingDraft={isAddingDraft}
							/>
						);
					}

					return (
						<InventoryAllocationReportResults
							reportId={report.id}
							allocationId={inventoryAllocationId}
						/>
					);
				}}
			/>
			<Route
				path={tabs[1].to}
				render={() => {
					if (report.status === InventoryAllocationReportStatus.InProgress) {
						return (
							<InventoryAllocationReportInProgress
								addDraft={onAddDraft}
								isAddingDraft={isAddingDraft}
							/>
						);
					}

					if (
						report.status === InventoryAllocationReportStatus.NoSuggestedMoves
					) {
						return (
							<InventoryAllocationReportNoMovements
								addDraft={onAddDraft}
								isAddingDraft={isAddingDraft}
							/>
						);
					}

					return (
						<InventoryAllocationReportMovements
							reportId={report.id}
							allocationId={inventoryAllocationId}
						/>
					);
				}}
			/>
			<Route
				path={tabs[2].to}
				render={() => {
					return (
						<InventoryAllocationReportConfiguration
							inventoryAllocationId={inventoryAllocationId}
							defaultFilters={
								(
									result.data?.scopes?.find(
										({ scope_type }) => scope_type === ScopeTypeDTO.ProductScope
									) as any
								)?.filters
							}
							reportId={id}
							{...report.configuration}
							isInProgress={
								report.status === InventoryAllocationReportStatus.InProgress
							}
							isProposal={
								report.status === InventoryAllocationReportStatus.Proposal
							}
							isOutdated={
								report.status === InventoryAllocationReportStatus.Outdated
							}
							onCopyConfiguration={() =>
								onCopyConfiguration(report.configuration)
							}
							onStartAlgorithm={() =>
								startAlgorithm(inventoryAllocationId, report.id)
							}
							isCopyingConfiguration={isCopyingConfiguration}
							isAlgorithmRunning={isLoading}
						/>
					);
				}}
			/>
			<Route
				path={tabs[3].to}
				render={() => {
					return (
						<>
							<ProductsTable
								isReadOnly
								inventoryAllocationId={inventoryAllocationId}
								reportId={id}
								defaultFilters={
									(
										result.data?.scopes?.find(
											({ scope_type }) =>
												scope_type === ScopeTypeDTO.ProductScope
										) as any
									)?.filters
								}
								selectedProducts={report.configuration.scope.products}
							/>
							<LocationsTable
								inventoryAllocationId={inventoryAllocationId}
								reportId={id}
								isReadOnly
								className="mt-12"
								selectedLocations={report.configuration.scope.locations}
							/>
						</>
					);
				}}
			/>
		</>
	);
};

export default InventoryAllocationReportDetail;
