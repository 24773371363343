import Tooltip from '../../shared/components/Tooltip/Tooltip';

export type UploadsDateProps = {
	date?: string;
};

export default function UploadsDate({ date }: UploadsDateProps) {
	if (date === undefined) {
		return <span className="text-gray-400">Not uploaded yet</span>;
	}

	return (
		<Tooltip content={new Date(date).toTimeString()}>
			<span>{new Date(date).toDateString()}</span>
		</Tooltip>
	);
}
