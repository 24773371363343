import Nav from '../../shared/components/Nav/Nav';
import SeasonSettings from '../v2/SeasonSettings';

const NAV_ITEMS = [
	{
		id: 'strategies',
		label: 'Strategies',
		to: '/strategy/strategies',
	},
	{
		id: 'objective-groups',
		label: 'Objectives',
		to: '/strategy/objectives',
	},
	{
		id: 'business-rules',
		label: 'Business rules',
		to: '/strategy/business-rules',
	},
];

const StrategyNav = () => {
	return (
		<div className="flex flex-row items-center gap-4">
			<Nav items={NAV_ITEMS} />
			<SeasonSettings />
		</div>
	);
};

export default StrategyNav;
