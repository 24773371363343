import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';

import {
	Control,
	Controller,
	FieldErrorsImpl,
	useFieldArray,
	UseFieldArrayRemove,
	UseFieldArrayUpdate,
	useForm,
	UseFormReturn,
	UseFormWatch,
} from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import Button from '../../shared/components/Button/Button';
import Modal from '../../shared/components/Modal/Modal';
import useModal from '../../shared/hooks/useModal';

import InfoIcon from '../../shared/components/Icons/Info';

import Checkbox from '../../shared/components/Checkbox/Checkbox';
import IconButton from '../../shared/components/IconButton/IconButton';
import Calendar from '../../shared/components/Icons/Calendar';
import Cross from '../../shared/components/Icons/Cross';
import Plus from '../../shared/components/Icons/Plus';
import Save from '../../shared/components/Icons/Save';
import TextInput from '../../shared/components/TextInput/TextInput';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import {
	getMondayThatWeek,
	getNewPhaseWeek,
	getSelectableDates,
	SelectableDatesArgs,
	today,
	weekNumber,
} from '../../shared/utils/dates';
import {
	PhaseDataType,
	PhaseGroupType,
	PhaseType,
	SeasonDataType,
	SeasonPhasesType,
	SeasonType,
} from '../types/seasons';
import { WeekPicker } from './WeekPicker';

import { useAllLatestRunStatusesQueryKey } from '../../shared/api/queryHooks/useAllLatestRunStatusesQuery';
import { SAVE_SETTINGS } from '../../shared/api/seasons';
import CornerPinger from '../../shared/components/CornerPinger/CornerPinger';
import { DeleteButton } from '../../shared/components/DeleteButton/DeleteButton';
import Warning from '../../shared/components/Icons/Warning';
import useChannelStore from '../../shared/data/channels';
import useToast from '../../shared/data/toast';
import { isValidKey } from '../../shared/utils/general';
import { transformPhases as doPhaseQuerySelect } from './queries';
import { getPhasesWarnings, getSeasonWarnings } from './strategyWarnings';
import {
	constructChannelQueryKey,
	constructEndSeasonFakePhase,
	END_OF_SEASON_PHASE_CONSTANTS,
	transformSeason,
} from './utils';

/**
 * TODO split into separate files
 * This file is rather large and can benefit from splitting into several files
 */

// TODO used for testing
const TODAY = today();

const MAX_SCHEDULED_PHASES = window._ENV_.REACT_APP_MAX_SCHEDULED_PHASES;

type SeasonSettingsModalProps = {
	season: SeasonType;
	phases: SeasonPhasesType;
	// eslint-disable-next-line
	isLoading?: boolean;
	onSuccess?: () => Promise<void>;
	onMutate?: () => Promise<void>;
};

const COLUMNS = [
	{ id: 'line_num', className: '' },
	{ id: 'name', className: '' },
	{ id: 'week', className: '' },
	{ id: 'start_date', className: '' },
	{ id: 'actions', className: 'justify-end' },
] as const;

type ColumnIdType = typeof COLUMNS[number]['id'];
type UpdatePhaseDateLocally = (
	phaseId: PhaseType['id'],
	newDate: PhaseType['start_date']
) => void;

const cellRenderer = ({
	row,
	columnId,
	lineNum,
	rowIndex,
	control,
	groupName,
	update,
	updateDateLocally,
	remove,
	selectableDates,
	isSeasonEnded,
	errors,
	watch,
}: {
	row: PhaseType;
	columnId: ColumnIdType;
	lineNum: number;
	rowIndex: number;
	// TODO finish this
	control: Control<any>;
	groupName: PhaseGroupType;
	// TODO finish this
	update: UseFieldArrayUpdate<any>;
	updateDateLocally: UpdatePhaseDateLocally;
	remove: UseFieldArrayRemove;
	selectableDates: SelectableDatesArgs;
	isSeasonEnded?: boolean;
	errors: FieldErrorsImpl;
	watch: UseFormWatch<any>;
}) => {
	const isEndSeason = row.type === 'endseason';
	const isNew = row.type === 'new';
	const fieldPrefix = `${groupName}[${rowIndex}]`;

	// @ts-expect-error
	const nameError = errors?.[groupName]?.[rowIndex]?.name?.message;
	switch (true) {
		case columnId === 'actions': {
			if (isEndSeason) {
				return (
					<span className="relative inline-flex px-2 h-[40px] items-center" />
				);
			}
			return (
				<span className="relative inline-flex px-2 h-[40px] items-center">
					<DeleteButton
						onClick={() => {
							if (isNew) {
								// new items are just deleted
								remove(rowIndex);
								return;
							}
							update(rowIndex, { ...row, type: 'deleted' });
						}}
					/>
				</span>
			);
		}

		case columnId === 'name': {
			if (!row.hasInput) {
				return isValidKey(columnId, row) ? (
					<span className="text-sm pl-4">{String(row[columnId])}</span>
				) : (
					''
				);
			}
			return (
				<Controller
					name={`${fieldPrefix}.name`}
					control={control}
					rules={{
						required: 'Phase must have a name',
						validate: (currentValue) => {
							// TODO fix type
							const values = watch(groupName);
							const isDuplicate = values.some(
								(item: any) =>
									item.name === currentValue &&
									item.id !== row.id &&
									item.type !== 'deleted'
							);
							return isDuplicate ? 'Duplicates not allowed' : true;
						},
					}}
					render={({ field }) => {
						// TODO type this better
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { ref, ...fieldProps } = field as any;
						// TextInput requires id
						if (!fieldProps.id) {
							fieldProps.id = `${fieldPrefix}_${rowIndex}_name_input`;
						}
						return (
							<TextInput
								placeholder="Enter name"
								className="w-[340px]"
								{...fieldProps}
								error={nameError}
							/>
						);
					}}
				/>
			);
		}

		case columnId === 'start_date': {
			return (
				<span className="text-xs text-ca-gray-500">
					{row.startDate.format('D MMM YYYY')}
				</span>
			);
		}

		case columnId === 'line_num': {
			return isEndSeason ? '' : lineNum;
		}

		case columnId === 'week': {
			const hasWarning = isEndSeason && isSeasonEnded;
			const limits = getSelectableDates({
				...selectableDates,
				allowDate: row.startDate,
			});

			return (
				<WeekPicker
					fixedWeeks
					showOutsideDays
					numberOfMonths={2}
					selectableDates={limits}
					defaultValue={row.startDate.toDate()}
					onSave={(newDate) => {
						if (newDate === undefined) {
							return;
						}

						updateDateLocally(row.id, newDate.format('YYYY-MM-DD'));
					}}
				>
					<Button variant="unstyled" size="small">
						<CornerPinger hidden={!hasWarning} pingColor="red">
							<Tooltip content={hasWarning && 'Season has already ended'}>
								<div
									className={clsx(
										hasWarning
											? 'border-ca-destructive text-ca-destructive'
											: 'border-ca-silver text-ca-black',
										' bg-white rounded-lg hover:none text-xs flex flex-row items-center justify-center gap-3 h-[40px] whitespace-nowrap min-w-[100px]  border focus:outline-none focus-within:ring-4 focus-within:ring-opacity-10 focus-within:border-ca-purple focus-within:ring-ca-purple'
									)}
								>
									{`Week ${weekNumber(row.startDate)}`}
									<Calendar
										className={clsx(
											'h-3.5 w-auto ',
											hasWarning ? 'text-ca-destructive' : 'text-ca-gray-400'
										)}
									/>
								</div>
							</Tooltip>
						</CornerPinger>
					</Button>
				</WeekPicker>
			);
		}

		default: {
			// TODO we actually defined all possible ids above, after testing we can remove this
			return isValidKey(columnId, row) ? String(row[columnId]) : '';
		}
	}
};

const transformPhasesData = ({
	phases,
	season,
	type,
}: {
	phases: SeasonPhasesType;
	season: SeasonType;
	type: PhaseGroupType;
}) => {
	if (type === 'beforeNow') {
		return phases.beforeNow.map((item) => ({
			...item,
			hasInput: item.type === 'saved' || item.type === 'new',
		}));
	}

	const afterNow: PhaseType[] = phases.afterNow.map((item) => ({
		...item,
		hasInput: item.type === 'saved' || item.type === 'new',
	}));
	afterNow.push(constructEndSeasonFakePhase(season.endDate));

	return afterNow;
};

const convertPhaseTypeToPhaseDataType = (
	phaseTypes: PhaseType[]
): PhaseDataType[] => {
	return phaseTypes.map((phaseType) => {
		const phaseDataType: PhaseDataType = {
			completed: phaseType.completed,
			id: phaseType.id,
			name: phaseType.name,
			start_date: phaseType.start_date,
		};

		return phaseDataType;
	});
};

const createFormValues = (phases: SeasonPhasesType, season: SeasonType) => {
	return {
		beforeNow: transformPhasesData({ phases, season, type: 'beforeNow' }),
		afterNow: transformPhasesData({ phases, season, type: 'afterNow' }),
		includeShippingCost: season.include_shipping_cost ?? false,
		includeReturnCost: season.include_return_cost ?? false,
	};
};

/**
 *  1:1 version of props.phases BUT takes into account **local changes** (user changed start_date of a phase for example)
 * - Does not include (faked) end of season phase
 */
const constructPhasesFromForm = (
	getFormValues: UseFormReturn<ReturnType<typeof createFormValues>>['getValues']
): SeasonSettingsModalProps['phases'] => {
	const formValues = getFormValues();
	return {
		afterNow: formValues.afterNow.filter(
			(phase) => phase.type !== 'endseason' && phase.type !== 'deleted'
		),
		beforeNow: formValues.beforeNow.filter((phase) => phase.type !== 'deleted'),
	};
};

const constructSeasonFromForm = (
	getFormValues: UseFormReturn<
		ReturnType<typeof createFormValues>
	>['getValues'],
	newEndDate?: SeasonSettingsModalProps['season']['endDate']
): SeasonSettingsModalProps['season'] => {
	const formValues = getFormValues();
	const endPhase = [...formValues.afterNow, ...formValues.beforeNow].find(
		(formItem) => formItem.id === END_OF_SEASON_PHASE_CONSTANTS.id
	);
	if (!endPhase) {
		throw new Error(
			'[SeasonSettingsModal::constructSeasonFromForm] end of season phase not found in formItems (impossible)'
		);
	}

	return transformSeason({
		end_date: newEndDate?.format('YYYY-MM-DD') ?? endPhase.start_date,
		id: endPhase.id,
		name: endPhase.name,
		include_return_cost: formValues.includeReturnCost,
		include_shipping_cost: formValues.includeShippingCost,
	});
};

const SeasonSettingsModal = ({
	phases,
	season,
	onMutate,
	onSuccess,
}: SeasonSettingsModalProps) => {
	// TODO not using isLoading yet
	// can use it with the button or make a wrapper
	// used to init the form
	const initialFormValues = {
		defaultValues: createFormValues(phases, season),
	};

	const queryClient = useQueryClient();
	const { activeChannel } = useChannelStore();
	const dashboardQueryKey = constructChannelQueryKey(activeChannel, [
		'cumulio-scenario-overview',
	]);

	const allStatusesQueryKey = useAllLatestRunStatusesQueryKey();

	const { close } = useModal();
	const { show: showToast } = useToast.getState();
	const {
		control,
		watch,
		handleSubmit: doFormSubmission,
		formState: { isDirty, errors },
		getValues,
		setValue,
	} = useForm(initialFormValues);

	const {
		fields: beforeItems,
		update: updateBeforeItem,
		remove: removeBeforeItem,
	} = useFieldArray({
		control,
		name: 'beforeNow',
		keyName: '_id',
	});
	const {
		fields: afterItems,
		update: updateAfterItem,
		remove: removeAfterItem,
		insert: insertAfterItem,
	} = useFieldArray({
		control,
		name: 'afterNow',
		keyName: '_id',
	});

	const visibleBeforeItems = beforeItems
		.map((item, idx) => ({ row: item, rowIndex: idx }))
		.filter((item) => item.row.type !== 'deleted');
	const visibleAfterItems = afterItems
		.map((item, idx) => ({ row: item, rowIndex: idx }))
		.filter((item) => item.row.type !== 'deleted');

	// one item is endseason, not counting it
	const nextLineNum = visibleBeforeItems.length + visibleAfterItems.length;
	// one item is endseason, not counting it

	const localPhases = constructPhasesFromForm(getValues);
	const localSeason = constructSeasonFromForm(getValues);
	const localPhasesWithEndOfSeason = {
		beforeNow: localPhases.beforeNow,
		afterNow: [
			...localPhases.afterNow,
			constructEndSeasonFakePhase(localSeason.endDate),
		],
	};

	const phaseWarnings = getPhasesWarnings({
		phases: localPhasesWithEndOfSeason,
		expectEndOfSeason: true,
	});

	const seasonWarnings = getSeasonWarnings(localSeason);

	const selectableBefore = {
		endPeriodDate: TODAY.subtract(1, 'week'),
		selectedDates: visibleBeforeItems.map((item) => item.row.startDate),
	};
	// endSeasonItem.
	const selectableAfter = {
		// TODO fixed date for test only
		startPeriodDate: TODAY,
		endPeriodDate:
			visibleAfterItems.find((item) => item.row.type === 'endseason')?.row
				?.startDate ?? season.endDate,
		selectedDates: visibleAfterItems.map((item) => item.row.startDate),
	};

	const lastPhaseDateOrToday = visibleAfterItems
		.filter((item) => item.row.id !== END_OF_SEASON_PHASE_CONSTANTS.id)
		.reduce<Dayjs>((latest, item) => {
			const startDateFollowingMonday = getMondayThatWeek(
				item.row.startDate.add(1, 'weeks')
			);
			if (startDateFollowingMonday.isAfter(latest)) {
				return startDateFollowingMonday;
			}
			return latest;
		}, getMondayThatWeek(TODAY.add(1, 'weeks')));
	const selectableEndOfSeason = {
		startPeriodDate: lastPhaseDateOrToday,
	};

	/* FORM UPDATE */
	const updatePhaseDateLocally: UpdatePhaseDateLocally = (phaseId, newDate) => {
		const currentValues = getValues();

		// 2. all phases as frontend type (not backend type)
		const allPhases: PhaseType[] = [
			...currentValues.afterNow.filter(
				(phase) => phase.type !== END_OF_SEASON_PHASE_CONSTANTS.type
			),
			...currentValues.beforeNow,
		];

		let seasonFromFormValues: SeasonType | undefined;
		if (phaseId === END_OF_SEASON_PHASE_CONSTANTS.id) {
			// we are updating the end of season phase
			seasonFromFormValues = {
				end_date: getMondayThatWeek(dayjs(newDate)).format('YYYY-MM-DD'),
				endDate: getMondayThatWeek(dayjs(newDate)),
				include_return_cost: currentValues.includeReturnCost,
				include_shipping_cost: currentValues.includeShippingCost,
				name: season.name,
				id: season.id,
				isInPast: false,
			};
		} else {
			const seasonEndPhase = currentValues.afterNow.find(
				(phase) => phase.id === END_OF_SEASON_PHASE_CONSTANTS.id
			);
			if (seasonEndPhase === undefined) {
				throw new Error(
					`[SeasonSettingsModal::handleUpdatePhaseDateLocally] the end of season phase is not found (impossible).`
				);
			}
			seasonFromFormValues = {
				end_date: seasonEndPhase.start_date,
				endDate: dayjs(seasonEndPhase.startDate),
				include_return_cost: currentValues.includeReturnCost,
				include_shipping_cost: currentValues.includeShippingCost,
				name: season.name,
				id: season.id,
				isInPast: false,
			};
			// 3. update start date
			const phaseToUpdate = allPhases.find(
				(phaseHay) => phaseHay.id === phaseId
			);
			if (phaseToUpdate === undefined) {
				throw new Error(
					`[SeasonSettingsModal::handleUpdatePhaseDateLocally] phase not found in all phase (impossible). Value that caused error: phaseId:${phaseId}`
				);
			}

			// assuming only these two field change
			phaseToUpdate.start_date = getMondayThatWeek(dayjs(newDate)).format(
				'YYYY-MM-DD'
			);
			phaseToUpdate.completed = dayjs(phaseToUpdate.start_date).isBefore(
				getMondayThatWeek(TODAY)
			);
		}

		// 4. undo transformation done in query.select and reapply the transform so that
		// the new phases are exactly as if it was from the database.
		const updatedPhases = doPhaseQuerySelect(
			convertPhaseTypeToPhaseDataType(allPhases)
		);

		// reapply types. Otherwise deleted phases would get reset to 'saved' which is incorrect
		const reapplyTypes = (phase: PhaseType) => {
			const foundType = allPhases.find(({ id }) => id === phase.id)?.type;
			if (!foundType) {
				throw new Error(
					`[SeasonSettingsModal::handleUpdatePhaseDateLocally::reapplyTypes] phase not found in all phase (impossible). Value that caused error: phaseId:${phaseId}`
				);
			}
			return { ...phase, type: foundType };
		};
		updatedPhases.beforeNow = updatedPhases.beforeNow.map(reapplyTypes);
		updatedPhases.afterNow = updatedPhases.afterNow.map(reapplyTypes);

		// 5. transform phases to form field values
		const newFormValue = createFormValues(updatedPhases, seasonFromFormValues);

		// 6. mark the updatephase so that we can visually show some feedback when a row is updated
		const updatedPhase = [
			...newFormValue.afterNow,
			...newFormValue.beforeNow,
		].find((phase) => phase.id === phaseId);

		if (!updatedPhase) {
			throw new Error(
				`[SeasonSettingsModal::handleUpdatePhaseDateLocally] updated phase not found in all phase (impossible). Error caused by phaseId: ${phaseId}`
			);
		}
		updatedPhase.doUpdateAnimation = Date.now();

		// 7. updat form with new values
		setValue('beforeNow', newFormValue.beforeNow, { shouldDirty: true });
		setValue('afterNow', newFormValue.afterNow, { shouldDirty: true });
	};

	/*  FORM SUBMISSION */
	const { isLoading: isSaveSettingsLoading, mutate: saveSettings } =
		useMutation(SAVE_SETTINGS, {
			onMutate: async () => {
				await onMutate?.();
			},
			onSuccess: async () => {
				showToast(`Season settings have been saved.`, {
					type: 'success',
				});
				await onSuccess?.();
				close();
			},
		});

	const handleAdd = () => {
		const startDate = getNewPhaseWeek({ phases: localPhases.afterNow });
		insertAfterItem(localPhases.afterNow.length, {
			completed: false,
			name: `Phase ${nextLineNum}`,
			startDate,
			start_date: startDate.format('YYYY-MM-DD'),
			type: 'new',
			id: uuidv4(),
			hasInput: true,
		});
	};

	type SubmittedDataType = Parameters<
		Parameters<typeof doFormSubmission>[0]
	>[0];

	const handleSubmit = (data: SubmittedDataType) => {
		const updatedItems: PhaseType[] = [];
		const newItems: PhaseType[] = [];
		const deletedItems: PhaseType[] = [];
		let updatedSeason: Partial<SeasonDataType> = {
			include_shipping_cost: data.includeShippingCost,
			include_return_cost: data.includeReturnCost,
			id: season.id,
		};

		[...data.beforeNow, ...data.afterNow].forEach((item) => {
			switch (true) {
				case item.type === 'new': {
					newItems.push(item);
					break;
				}
				case item.type === 'deleted': {
					deletedItems.push(item);
					break;
				}
				case item.type === 'saved': {
					updatedItems.push(item);
					break;
				}
				case item.type === 'endseason': {
					updatedSeason = {
						...updatedSeason,
						name: season.name,
						end_date: item.startDate.format('YYYY-MM-DD'),
					};
					break;
				}
				default: {
					// why is this required?
				}
			}
		});

		const payload = {
			newItems,
			deletedItems,
			updatedItems,
			// TODO fix this
			updatedSeason: updatedSeason as any,
		};

		saveSettings(payload);
		queryClient.resetQueries({ queryKey: dashboardQueryKey });
		queryClient.resetQueries({ queryKey: allStatusesQueryKey });
	};

	return (
		<Modal.Root className="w-full max-w-4xl bg-ca-gray-100 ">
			<Modal.Content className="relative p-8">
				<span className="absolute flex right-5 top-5">
					<IconButton
						className="w-4 h-auto text-ca-gray-500"
						tooltip="Close"
						icon={Cross}
						onClick={() => {
							close();
						}}
					/>
				</span>
				<Modal.Title>
					<span className="block text-ca-purple text-2xl font-bold">
						Season settings
					</span>
					<span className="block mt-7 font-bold text-ca-black text-lg">
						Configure phases
					</span>
				</Modal.Title>
				<form
					onSubmit={doFormSubmission(handleSubmit)}
					id="season-settings-save"
					className="flex flex-col"
				>
					<div className="grid grid-cols-[80px_repeat(3,max-content)_minmax(0,1fr)] gap-y-2 mb-4">
						<h4 className="col-span-5 text-ca-gray-500 text-xs my-2">
							Completed phases
						</h4>
						{/* TODO extract this component */}
						{visibleBeforeItems.length ? (
							visibleBeforeItems.map(({ row, rowIndex }, visibleRowIndex) => {
								return COLUMNS.map(
									({ id: columnId, className: columnStyle }) => (
										<div
											key={row.id + columnId + row.doUpdateAnimation ?? ''}
											className={clsx(
												'border-b p-2 flex flex-row items-center',
												row.doUpdateAnimation && 'animate-background-hit ',
												columnStyle
											)}
										>
											{cellRenderer({
												row,
												columnId,
												rowIndex,
												control,
												lineNum: visibleRowIndex + 1,
												groupName: 'beforeNow',
												// @ts-expect-error
												update: updateBeforeItem,
												updateDateLocally: updatePhaseDateLocally,
												remove: removeBeforeItem,
												selectableDates: selectableBefore,
												isSeasonEnded: !!seasonWarnings.warnings.seasonEnded,
												errors,
												watch,
											})}
										</div>
									)
								);
							})
						) : (
							<p className="col-span-5 text-center text-ca-gray-500 text-xs border-b py-3">
								No completed phases.
							</p>
						)}
						<div className="flex items-center col-span-5 my-2">
							<h4 className="text-ca-gray-500 text-xs">Scheduled phases</h4>
							<Tooltip content={`Max ${MAX_SCHEDULED_PHASES} scheduled phases`}>
								<span className="inline-block ml-1.5">
									<InfoIcon className="h-3.5 text-ca-gray-500" />
								</span>
							</Tooltip>
						</div>
						{/* TODO extract this component */}
						{phaseWarnings.warnings.noScheduledPhases && (
							<div className="col-span-5 text-center text-ca-gray-500 text-xs py-3 border-gray-400 border border-dashed rounded-xl ">
								<div className="flex justify-center items-center gap-2 mx-auto">
									<Warning className="h-4 w-4" />
									No scheduled phases.
								</div>

								<div className="flex justify-center items-baseline mx-auto">
									You can{' '}
									<Button
										type="button"
										variant="link"
										size="small"
										onClick={handleAdd}
										disabled={!!phaseWarnings.warnings.atMaxAllowedPhases}
										className="pl-1 pr-1"
									>
										add a phase here
									</Button>{' '}
									or click the &quot;Add phase&quot; button with the blinking
									dot.
								</div>
							</div>
						)}
						{visibleAfterItems.map(({ row, rowIndex }, visibleRowIndex) => {
							return (
								<React.Fragment key={row._id}>
									{COLUMNS.map(({ id: columnId, className: columnStyle }) => (
										<div
											key={row.id + columnId + row.doUpdateAnimation ?? ''}
											className={clsx(
												'border-b p-2 flex flex-row items-center',
												row.doUpdateAnimation &&
													row.doUpdateAnimation &&
													'animate-background-hit',
												columnStyle
											)}
										>
											{cellRenderer({
												row,
												columnId,
												rowIndex,
												control,
												lineNum:
													visibleRowIndex + visibleBeforeItems.length + 1,
												groupName: 'afterNow',
												// @ts-expect-error
												update: updateAfterItem,
												updateDateLocally: updatePhaseDateLocally,
												remove: removeAfterItem,
												selectableDates:
													row.id === END_OF_SEASON_PHASE_CONSTANTS.id
														? selectableEndOfSeason
														: selectableAfter,
												isSeasonEnded: !!seasonWarnings.warnings.seasonEnded,
												errors,
												watch,
											})}
										</div>
									))}
								</React.Fragment>
							);
						})}
					</div>
					<Tooltip
						content={(() => {
							if (phaseWarnings.warnings.atMaxAllowedPhases) {
								return 'You already have the max amount of phases scheduled';
							}
							if (phaseWarnings.warnings.noScheduledPhases) {
								return 'You are not be able to run strategies without a scheduled phase';
							}
							if (phaseWarnings.warnings.noFreeWeekToAddNew) {
								return phaseWarnings.warnings.noFreeWeekToAddNew;
							}
							return undefined;
						})()}
						placement="left"
					>
						<span className="self-end">
							<CornerPinger hidden={!phaseWarnings.warnings.noScheduledPhases}>
								<Button
									variant="primary"
									size="small"
									className="flex flex-row gap-3 whitespace-nowrap items-center"
									onClick={handleAdd}
									disabled={
										!!phaseWarnings.warnings.atMaxAllowedPhases ||
										!!phaseWarnings.warnings.noFreeWeekToAddNew
									}
								>
									Add phase
									<Plus className="h-3.5 w-auto" />
								</Button>
							</CornerPinger>
						</span>
					</Tooltip>
					<h3 className="mt-10 font-bold text-ca-black text-lg">
						Configure costs
					</h3>
					<div className="mt-2 flex flex-col items-stretch w-max gap-y-3">
						<Controller
							name="includeShippingCost"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Checkbox
									swapPosition
									checked={value}
									onChange={onChange}
									label="Include shipping cost"
								/>
							)}
						/>
						<Controller
							name="includeReturnCost"
							control={control}
							render={({ field: { value, onChange } }) => (
								<Checkbox
									swapPosition
									checked={value}
									onChange={onChange}
									label="Include return cost"
								/>
							)}
						/>
					</div>
				</form>
			</Modal.Content>
			<Modal.Actions>
				<div className="flex justify-between">
					<Button
						variant="link"
						className="font-medium"
						onClick={() => close()}
					>
						Close
					</Button>
					<CornerPinger hidden={!isDirty || isSaveSettingsLoading}>
						<Button
							className="flex flex-row items-center gap-3 whitespace-nowrap"
							type="submit"
							variant="primary"
							form="season-settings-save"
							disabled={!isDirty || isSaveSettingsLoading}
						>
							{isSaveSettingsLoading ? (
								'Saving...'
							) : (
								<>
									<span>Save</span>
									<Save className="h-3.5 w-auto" />
								</>
							)}
						</Button>
					</CornerPinger>
				</div>
			</Modal.Actions>
		</Modal.Root>
	);
};

export default SeasonSettingsModal;
