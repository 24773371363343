import PropTypes from 'prop-types';

const OpenOverlay = ({ className }) => {
	return (
		<svg
			className={className}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 13L8 8M8 8H4.25M8 8V11.75"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<mask id="path-2-inside-1_814_25759" fill="currentColor">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.57143 0C5.01914 0 4.57143 0.447716 4.57143 1V6H8C9.10457 6 10 6.89543 10 8V11.4286H15C15.5523 11.4286 16 10.9809 16 10.4286V1C16 0.447715 15.5523 0 15 0H5.57143Z"
				/>
			</mask>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.57143 0C5.01914 0 4.57143 0.447716 4.57143 1V6H8C9.10457 6 10 6.89543 10 8V11.4286H15C15.5523 11.4286 16 10.9809 16 10.4286V1C16 0.447715 15.5523 0 15 0H5.57143Z"
				fill="currentColor"
			/>
			<path
				d="M4.57143 6H3.57143V7H4.57143V6ZM10 11.4286H9V12.4286H10V11.4286ZM5.57143 1L5.57143 1V-1C4.46686 -1 3.57143 -0.104567 3.57143 1H5.57143ZM5.57143 6V1H3.57143V6H5.57143ZM4.57143 7H8V5H4.57143V7ZM8 7C8.55228 7 9 7.44772 9 8H11C11 6.34315 9.65685 5 8 5V7ZM9 8V11.4286H11V8H9ZM15 10.4286H10V12.4286H15V10.4286ZM15 10.4286V10.4286V12.4286C16.1046 12.4286 17 11.5331 17 10.4286H15ZM15 1V10.4286H17V1H15ZM15 1L15 1H17C17 -0.104571 16.1046 -1 15 -1V1ZM5.57143 1H15V-1H5.57143V1Z"
				fill="currentColor"
				mask="url(#path-2-inside-1_814_25759)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.83899 0H2C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V12.161C15.7058 12.3312 15.3643 12.4286 15 12.4286L15 14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1L3.57143 1C3.57143 0.635714 3.66882 0.294174 3.83899 0ZM15 11.4286C15.5523 11.4286 16 10.9809 16 10.4286L16 2C16 1.99934 16 1.99868 16 1.99803C15.9989 0.894364 15.1039 0 14 0H5.57143C5.01914 0 4.57143 0.447716 4.57143 1L14 1C14.5523 1 15 1.44772 15 2L15 11.4286Z"
				fill="currentColor"
			/>
		</svg>
	);
};

OpenOverlay.propTypes = {
	className: PropTypes.string,
};

OpenOverlay.defaultProps = {
	className: '',
};

export default OpenOverlay;
