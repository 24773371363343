import { UseQueryOptions } from 'react-query';
import { PhasesDataType } from '../../../strategy/types/seasons';
import { SEASON_PHASES_QUERY_KEY } from '../../../strategy/v2/queries';
import useChannelQuery from '../../hooks/useChannelQuery';
import { LIST_PHASES } from '../seasons';

const usePhasesQuery = (
	options?: UseQueryOptions<PhasesDataType, Error, PhasesDataType>
) => {
	return useChannelQuery<PhasesDataType, Error, PhasesDataType>(
		SEASON_PHASES_QUERY_KEY,
		{
			queryFn: LIST_PHASES,
			...options,
		}
	);
};

export default usePhasesQuery;
