import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import StackedGrid from '../../StackedGrid/StackedGrid';

const colorVariants: Record<'purple' | 'red', string> = {
	purple: 'bg-ca-purple',
	red: 'bg-red-600',
} as const;

const CornerPinger = (
	props: PropsWithChildren & {
		hidden?: boolean;
		fakeCutoutBorderClassName?: string /* 'border-ca-ghost-white' */;
		pingColor?: keyof typeof colorVariants;
	}
) => {
	return (
		<div className="relative">
			{props.children}
			{!props.hidden === true && (
				<StackedGrid className="absolute top-0 right-0 translate-x-[35%] -translate-y-[35%] place-items-center">
					<div
						className={clsx(
							'w-3 h-3 rounded-full border-2',
							colorVariants[props.pingColor ?? 'purple'],
							props.fakeCutoutBorderClassName ?? 'border-ca-ghost-white'
						)}
					/>
					<div
						className={clsx(
							'w-3 h-3 rounded-full animate-ping opacity-75',
							colorVariants[props.pingColor ?? 'purple']
						)}
					/>
				</StackedGrid>
			)}
		</div>
	);
};

export default CornerPinger;
