import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import CheckmarkIcon from '../Icons/Checkmark';
import Minus from '../Icons/Minus';
import Text from '../Text/Text';

// add styling variant: outline & filled

// add third state - unchecked, mixed, checked => will this mess anything up? yes, onChange(bool) changes to onChange(checkboxValue)
export enum CheckboxValue {
	unchecked = 'unchecked',
	mixed = 'mixed',
	checked = 'checked',
}

/**
 * Ambiguous value (=`bool` or `CheckboxValue`) to `CheckboxValue`
 */
const ambiguousToCheckboxValue = (
	from: boolean | CheckboxValue
): CheckboxValue => {
	if (typeof from === 'boolean') {
		return from ? CheckboxValue.checked : CheckboxValue.unchecked;
	}

	return from;
};

type CheckboxProps = {
	checked: CheckboxValue | boolean;
	label?: string | React.ReactNode;
	swapPosition?: boolean;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
	/**
	 * override on default onClick behavior. Meant for special case where you can click the checkbox but the value doesn't change.
	 */
	overrideOnClick?: () => void;
	variant?: 'original' | 'filled' | 'outline';
};

type Variant = {
	iconClassName: Record<CheckboxValue, { disabled: string; enabled: string }>;
	checkboxClassName: Record<
		CheckboxValue,
		{ disabled: string; enabled: string }
	>;
};

const variantStyling: Record<NonNullable<CheckboxProps['variant']>, Variant> = {
	original: {
		checkboxClassName: {
			checked: {
				enabled: 'border border-ca-silver focus:border-ca-purple bg-white',
				disabled: 'pointer-events-none',
			},
			mixed: {
				enabled: 'border border-ca-silver focus:border-ca-purple bg-white',
				disabled: 'pointer-events-none',
			},
			unchecked: {
				enabled: 'border border-ca-silver focus:border-ca-purple bg-white',
				disabled: 'pointer-events-none',
			},
		},
		iconClassName: {
			checked: {
				enabled: 'text-ca-purple',
				disabled: 'text-ca-gray',
			},
			mixed: {
				enabled: 'text-ca-purple',
				disabled: 'text-ca-gray',
			},
			unchecked: {
				enabled: 'text-ca-purple',
				disabled: 'text-ca-gray',
			},
		},
	},
	filled: {
		checkboxClassName: {
			checked: {
				enabled:
					'bg-ca-purple hover:bg-checkbox-purple-hover transition-all hover:shadow-checkbox-hover shadow-checkbox-hover-off',
				disabled: 'text-ca-gray',
			},
			mixed: {
				enabled:
					'bg-checkbox-purple-bg hover:bg-checkbox-purple-bg-hover border border-ca-purple hover:border-checkbox-purple-hover  transition-all hover:shadow-checkbox-hover shadow-checkbox-hover-off ',
				disabled: 'text-ca-gray',
			},
			unchecked: {
				enabled:
					'bg-checkbox-purple-bg hover:bg-checkbox-purple-bg-hover border border-ca-purple hover:border-checkbox-purple-hover transition-all hover:shadow-checkbox-hover shadow-checkbox-hover-off ',
				disabled: 'text-ca-gray',
			},
		},
		iconClassName: {
			checked: {
				enabled: 'text-white',
				disabled: 'text-ca-gray',
			},
			mixed: {
				enabled: 'text-ca-purple',
				disabled: 'text-ca-gray',
			},
			unchecked: {
				enabled: '',
				disabled: 'text-ca-gray',
			},
		},
	},
	outline: {
		checkboxClassName: {
			checked: {
				enabled:
					'bg-transparent border-ca-purple border transition-all hover:shadow-checkbox-hover',
				disabled: 'pointer-events-none',
			},
			mixed: {
				enabled:
					'bg-transparent border-ca-purple border transition-all hover:shadow-checkbox-hover',
				disabled: 'pointer-events-none',
			},
			unchecked: {
				enabled:
					'bg-transparent border-ca-purple border transition-all hover:shadow-checkbox-hover',
				disabled: 'pointer-events-none',
			},
		},
		iconClassName: {
			checked: {
				enabled: 'text-ca-purple',
				disabled: 'text-ca-gray',
			},
			mixed: {
				enabled: 'text-ca-purple',
				disabled: 'text-ca-gray',
			},
			unchecked: {
				enabled: 'text-ca-purple',
				disabled: 'text-ca-gray',
			},
		},
	},
} as const;

const Checkbox = (props: CheckboxProps) => {
	const [hitAnimation, setHitAnimation] = useState(false);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (!mounted) {
			setMounted(true);
			return () => {};
		}

		setHitAnimation(true);
		const hitAnimationDurationMs = 250;
		const timer = setTimeout(
			() => setHitAnimation(false),
			hitAnimationDurationMs
		);
		return () => clearTimeout(timer);
	}, [props.checked]);
	const safeVariant = props.variant ?? 'original';
	const transformedChecked: CheckboxValue =
		typeof props.checked === 'boolean' || typeof props.checked === 'undefined'
			? ambiguousToCheckboxValue(props.checked)
			: props.checked;
	return (
		<button
			disabled={props.disabled}
			type="button"
			tabIndex={-1}
			onClick={
				props.overrideOnClick ??
				(() => {
					if (props.disabled || props.onChange === undefined) {
						return;
					}

					if (
						transformedChecked === CheckboxValue.unchecked ||
						transformedChecked === CheckboxValue.mixed
					) {
						props.onChange(true);
						return;
					}

					props.onChange(false);
				})
			}
			className={clsx(
				'flex items-center transition-colors leading-none',
				props.disabled && 'cursor-not-allowed',
				props.swapPosition && 'justify-between'
			)}
		>
			<span
				tabIndex={props.disabled ? -1 : 0}
				role="button"
				className={clsx(
					' focus:ring-ca-purple focus:ring-4 focus:ring-opacity-10 rounded-[5px] inline-flex justify-center items-center shadow-md',
					hitAnimation && 'animate-hit',
					props.disabled
						? variantStyling[safeVariant].checkboxClassName[
								ambiguousToCheckboxValue(props.checked)
						  ].disabled
						: variantStyling[safeVariant].checkboxClassName[
								ambiguousToCheckboxValue(props.checked)
						  ].enabled,
					/* 'inline-flex justify-center items-center w-4 h-4 bg-white rounded border border-ca-silver focus:outline-none',
					props.disabled && 'pointer-events-none',
					!props.disabled &&
						'focus:border-ca-purple focus:ring-4 focus:ring-opacity-10 focus:ring-ca-purple', */
					props.swapPosition && 'order-2 ml-10'
				)}
				style={{ minHeight: '18px', minWidth: '18px' }}
			>
				{transformedChecked === CheckboxValue.checked && (
					// @ts-ignoreignore
					<CheckmarkIcon
						className={clsx(
							'h-2',
							props.disabled
								? variantStyling[safeVariant].iconClassName[
										ambiguousToCheckboxValue(props.checked)
								  ].disabled
								: variantStyling[safeVariant].iconClassName[
										ambiguousToCheckboxValue(props.checked)
								  ].enabled
						)}
					/>
				)}
				{transformedChecked === CheckboxValue.mixed && (
					<Minus
						className={clsx(
							props.disabled
								? variantStyling[safeVariant].iconClassName[
										ambiguousToCheckboxValue(props.checked)
								  ].disabled
								: variantStyling[safeVariant].iconClassName[
										ambiguousToCheckboxValue(props.checked)
								  ].enabled
						)}
					/>
				)}
			</span>
			{props.label && (
				// @ts-ignoreignore
				<Text
					className={clsx('text-left ml-2', props.swapPosition && 'order-1')}
					type={transformedChecked ? 'primary' : 'secondary'}
				>
					{props.label ?? ''}
				</Text>
			)}
		</button>
	);
};

export default Checkbox;
