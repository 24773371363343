import { faker } from '@faker-js/faker';
import { manyOf, nullable, primaryKey } from '@mswjs/data';
import {
	ConstraintTypeDTO,
	FilterTypeDTO,
	InventoryAllocationStatusDTO,
	MarketingActionTypeDTO,
	ProductDTO,
	ProductFieldTypeDTO,
	ReportDTO,
	ReportStatusDTO,
	ScopeTypeDTO,
	SecondaryObjectiveTypeDTO,
	SortOrderDTO,
	StockLocationDTO,
	StockLocationFieldTypeDTO,
} from '../shared/models/schema';

const inventoryAllocation = {
	id: primaryKey(faker.datatype.uuid),
	name: faker.lorem.words,
	date_created: () => faker.date.past().toISOString(),
	report_ids: () => [] as ReportDTO['id'][],
	submitted_report: nullable(String),
	status: () => InventoryAllocationStatusDTO.Draft,
	date_updated: () => faker.date.past().toISOString(),
	timestamp_last_update: () => faker.date.past().toISOString(),
};

const report = {
	id: primaryKey(faker.datatype.uuid),
	name: faker.lorem.words,
	scopes: manyOf('scope'),
	constraints: manyOf('constraint'),
	secondary_objectives: manyOf('secondaryObjective'),
	marketing_actions: manyOf('marketingAction'),
	date_created: () => faker.date.past().toISOString(),
	date_updated: () => faker.date.past().toISOString(),
	timestamp_last_update: () => faker.date.past().toISOString(),
	status: () => ReportStatusDTO.Draft,
	possible_revenue_gain: faker.datatype.number,
	proposed_number_of_movements: faker.datatype.number,
	proposed_number_of_senders: faker.datatype.number,
	proposed_number_of_receivers: faker.datatype.number,
	allocation_id: nullable(String),
};

const constraint = {
	id: primaryKey(faker.datatype.uuid),
	constraint_type: () => ConstraintTypeDTO.CooldownPeriod,
	lost_revenue: faker.datatype.number,
	scopes: manyOf('scope'),
	maximum_number_of_movements: faker.datatype.number,
	active: () => true,
	cooldown_period_days: faker.datatype.number,
	minimum_roi: faker.datatype.number,
};

const scope = {
	id: primaryKey(faker.datatype.uuid),
	scope_type: () => ScopeTypeDTO.LocationScope,
	products_to_include: () => [] as ProductDTO['id'][],
	shops_to_include: () => [] as StockLocationDTO['stock_location_id'][],
};

const secondaryObjective = {
	id: primaryKey(faker.datatype.uuid),
	secondary_objective_type: () => SecondaryObjectiveTypeDTO.FixBrokenSizeChart,
	weight: faker.datatype.number,
	lost_revenue: faker.datatype.number,
	scopes: manyOf('scope'),
	active: () => true,
	description: faker.lorem.paragraph,
	minimum_movement_size: faker.datatype.number,
};

const marketingAction = {
	id: primaryKey(faker.datatype.uuid),
	marketing_action_type: () => MarketingActionTypeDTO.IncreaseDemand,
	products_in_marketing_action: () => [] as ProductDTO['id'][],
	expected_sales_uplift: faker.datatype.number,
	predicted_sales_uplift: faker.datatype.number,
	sales_multiplication_factor: faker.datatype.number,
	name_marketing_action: faker.lorem.words,
};

const product = {
	id: primaryKey(faker.datatype.uuid),
	product_id: faker.datatype.uuid,
	description: faker.lorem.words,
	brand: faker.lorem.word,
	collection: faker.lorem.word,
	season: faker.lorem.word,
	target: faker.lorem.word,
	product_group: () => [],
	price: faker.lorem.word,
	cost: faker.lorem.word,
	image_url: faker.image.fashion,
	sku_purchase_cost: faker.lorem.word,
	item_id: faker.datatype.uuid,
	variant_code: faker.lorem.word,
	product_year_season: faker.lorem.word,
	product_color: faker.lorem.word,
	item_no_: faker.lorem.word,
	sku_id: faker.datatype.uuid,
	sku_size: faker.lorem.word,
	product_collection_code: faker.lorem.word,
};

const location = {
	stock_location_id: primaryKey(faker.datatype.uuid),
	stock_location_name: faker.lorem.words,
	physical: faker.lorem.word,
	urbanization: faker.lorem.word,
	sender: faker.datatype.boolean,
	receiver: faker.datatype.boolean,
};

const locationColumn = {
	id: primaryKey(faker.datatype.uuid),
	column_id: faker.datatype.uuid,
	name: faker.lorem.word,
	field_type: () => StockLocationFieldTypeDTO.Id,
	order: () => SortOrderDTO.Ascending,
	order_bq_name: faker.lorem.word,
	bq_name: faker.lorem.word,
	combined_bq_name: faker.lorem.word,
	tooltip: faker.lorem.words,
	sortable: faker.datatype.boolean,
	hidden_by_default: () => false,
	unit: faker.lorem.word,
	add_unit: faker.datatype.boolean,
};

const locationFilter = {
	id: primaryKey(faker.datatype.uuid),
	name: faker.lorem.word,
	field: faker.lorem.word,
	type: () => FilterTypeDTO.List,
	list_options: () =>
		faker.helpers.arrayElements([
			faker.lorem.word(),
			faker.lorem.word(),
			faker.lorem.word(),
		]),
	checklist_options: () => [],
	range_options: () => [],
	percentage: faker.datatype.boolean,
	is_hidden: () => false,
};

const productColumn = {
	id: primaryKey(faker.datatype.uuid),
	column_id: faker.datatype.uuid,
	name: faker.lorem.word,
	field_type: () => ProductFieldTypeDTO.CurrentStock,
	order: () => SortOrderDTO.Ascending,
	order_bq_name: faker.lorem.word,
	bq_name: faker.lorem.word,
	combined_bq_name: faker.lorem.word,
	tooltip: faker.lorem.words,
	sortable: faker.datatype.boolean,
	hidden_by_default: () => false,
	unit: faker.lorem.word,
	add_unit: faker.datatype.boolean,
};

const productFilter = {
	id: primaryKey(faker.datatype.uuid),
	name: faker.lorem.word,
	field: faker.lorem.word,
	type: () => FilterTypeDTO.List,
	list_options: () =>
		faker.helpers.arrayElements([
			faker.lorem.word(),
			faker.lorem.word(),
			faker.lorem.word(),
		]),
	checklist_options: () => [],
	range_options: () => [],
	percentage: faker.datatype.boolean,
	is_hidden: () => false,
};

const dictionary = {
	inventoryAllocation,
	report,
	constraint,
	scope,
	secondaryObjective,
	marketingAction,
	product,
	location,
	locationColumn,
	locationFilter,
	productColumn,
	productFilter,
};

export default dictionary;
