import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import useJWT from '../../data/jwt';

// wrapper component for fetching the access token and writing it to the store on initialization
const JWTProvider = ({ children }) => {
	const { setJWT, jwt } = useJWT();
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

	useEffect(async () => {
		if (!isLoading && isAuthenticated && !jwt) {
			const token = await getAccessTokenSilently({ detailedResponse: true });
			setJWT(token);
		}
	}, [isLoading, isAuthenticated, getAccessTokenSilently, jwt]);

	return children;
};

JWTProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default JWTProvider;
