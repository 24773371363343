import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../shared/components/Button/Button';
import InputWithLabel from '../../../shared/components/InputWithLabel/InputWithLabel';
import Modal from '../../../shared/components/Modal/Modal';
import TextInput from '../../../shared/components/TextInput/TextInput';
import useModal from '../../../shared/hooks/useModal';
import { CreateInventoryAllocationInput } from '../models/inventoryAllocationInput';

type FormValues = CreateInventoryAllocationInput;

interface Props {
	defaultValues?: FormValues;
	onSubmit: (inventoryAllocation: FormValues) => void;
}

const CreateInventoryAllocationModal: FunctionComponent<Props> = ({
	defaultValues,
	onSubmit,
}) => {
	const { close } = useModal();
	const { formState, control, handleSubmit } = useForm<FormValues>({
		defaultValues,
	});

	function onSubmitClick(inventoryAllocation: FormValues): void {
		onSubmit(inventoryAllocation);
		close();
	}

	return (
		<Modal.Root>
			<Modal.Content>
				<Modal.Title>New inventory allocation</Modal.Title>
				<form
					onSubmit={handleSubmit(onSubmitClick)}
					id="rename-inventory-allocation"
				>
					<InputWithLabel
						label="Title"
						htmlFor="title"
						labelClassName={clsx(
							'w-64 mb-2 md:mb-0',
							formState?.errors?.title?.message && '-mt-5'
						)}
					>
						<Controller
							name="title"
							control={control}
							rules={{ required: 'Required field' }}
							render={({ field }) => (
								<TextInput
									id="title"
									className="w-full sm:w-64"
									value={field.value}
									onChange={field.onChange}
									error={formState?.errors?.title?.message}
									placeholder="Title"
									type="text"
									endAdornment={undefined}
									startAdornment={undefined}
									size="regular"
								/>
							)}
						/>
					</InputWithLabel>
				</form>
			</Modal.Content>
			<Modal.Actions>
				<div className="flex justify-between">
					<Button variant="secondary" onClick={close}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="primary"
						form="rename-inventory-allocation"
					>
						Create
					</Button>
				</div>
			</Modal.Actions>
		</Modal.Root>
	);
};

export default CreateInventoryAllocationModal;
