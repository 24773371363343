const DotsVertical = (props: { className?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			className={props.className}
			viewBox="0 0 256 256"
		>
			<path
				fill="currentColor"
				d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12Zm-12-56a12 12 0 1 0-12-12a12 12 0 0 0 12 12Zm0 112a12 12 0 1 0 12 12a12 12 0 0 0-12-12Z"
			/>
		</svg>
	);
};

export default DotsVertical;
