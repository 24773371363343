import create from 'zustand';

import id from '../utils/id';

const useToast = create((set, get) => ({
	toasts: [],
	show: (message, { type = 'success', lifeSpan = 5000 } = {}) => {
		const toast = { id: id(), message, type, visible: true };

		set(({ toasts }) => ({
			toasts: [...toasts, toast],
		}));

		if (lifeSpan >= 0) {
			setTimeout(() => {
				get().close(toast.id);
			}, lifeSpan);
		}
		return toast.id;
	},
	close: (toastId) => {
		set(({ toasts }) => ({
			toasts: toasts.map((toast) => {
				if (toast.id === toastId) {
					return { ...toast, visible: false };
				}

				return toast;
			}),
		}));
	},
	cleanup: () => {
		set(({ toasts }) => toasts.filter((toast) => toast.visible));
	},
}));

export default useToast;
