// Math.random should be unique because of its seeding algorithm.
// We generate 2 strings, convert them to base 36 (a-z + 0-9)
// and grab the first 10 characters after the decimal x2
const id = () => {
	return (
		`${Math.random().toString(36).substr(2, 10)}` +
		`${Math.random().toString(36).substr(2, 10)}`
	);
};

export default id;
