import api from './api';

export const GET_RECOMMENDATIONS_FILTERS = (strategyId) => {
	return api.get(`api/v2/recommendations/filters/${strategyId}`).json();
};

export const GET_RECOMMENDATIONS_SCHEMA = () => {
	return api.get(`api/v2/recommendations/schema`).json();
};

export const GET_RECOMMENDATIONS = (strategyId, searchParams) => {
	return api
		.get(`api/v2/recommendations/${strategyId}`, {
			searchParams,
		})
		.json();
};

export const CREATE_RECOMMENDATION = ({ strategyId, ...payload }) => {
	return api
		.post(`api/v2/recommendations/${strategyId}/overwrite`, {
			json: payload,
		})
		.json();
};

export const GET_RECOMMENDATIONS_EXPORT = (strategyId, all, searchParams) => {
	return api
		.get(`api/v2/recommendations/${strategyId}/export${all ? '/all' : ''}`, {
			searchParams,
		})
		.blob();
};

export const CREATE_MARKDOWN_TRANSFER = ({
	strategyId,
	filterSearchParams,
	...payload
}) => {
	return api
		.post(`api/v2/recommendations/${strategyId}/markdown_transfer`, {
			searchParams: filterSearchParams,
			json: payload,
		})
		.json();
};

export const GET_NUMBER_TRANSFERS_BUSINESS_RULES = ({
	strategyId,
	filters,
	from,
	to,
	unit,
}) => {
	return api
		.post(
			`api/v2/recommendations/${strategyId}/nr_products_transfer_business_rules`,
			{
				searchParams: filters,
				json: {
					from_bucket: from,
					to_bucket: to,
					units: unit,
					amount: 0,
				},
			}
		)
		.json();
};

export const GET_BUCKETS = (strategyId, searchParams = {}) => {
	return api
		.get(`api/v2/recommendations/${strategyId}/markdown_buckets`, {
			searchParams,
		})
		.json();
};
