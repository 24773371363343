import { FunctionComponent } from 'react';
import { UseFieldArrayAppend } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReportMapper from '../../../domains/reports/ReportMapper';
import { GET_INVENTORY_ALLOCATION_REPORT_CONSTRAINTS } from '../../../shared/api/inventory-allocation-reports';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import {
	Configuration,
	Constraint,
	ConstraintType,
} from '../../../shared/models/configuration';
import {
	GetReportConstraintsParametersDTO,
	GetReportConstraintsResponseDTO,
} from '../../../shared/models/schema';

interface Props {
	configuredTypes: ConstraintType[];
	append: UseFieldArrayAppend<Configuration, 'constraints'>;
}

const LABEL: Record<ConstraintType, string> = {
	[ConstraintType.MaximumVolumeMoved]: 'Move no more than a total of n SKUs.',
	[ConstraintType.MinimumShipmentAmount]:
		"Don't create shipments with less than n SKUs.",
	[ConstraintType.MaximumShipmentAmount]:
		"Don't create shipments with more than n SKUs.",
	[ConstraintType.DoNotBreakSizeChart]: "Don't break size charts.",
	[ConstraintType.CooldownPeriod]:
		"Don't move products within n days after they have been delivered to a location.",
	[ConstraintType.MinimumROI]:
		"Don't move products unless we benefit at least n per SKU.",
	[ConstraintType.MaximumSKUAmount]:
		'Enforce maximum allowed amount of units per SKU per location.',
	[ConstraintType.OnlyRestoreSizeCharts]:
		'Only allow moves which can repair broken size charts.',
	[ConstraintType.MinimumProductAmount]:
		'Require minimum amount of units per product per location.',
	[ConstraintType.Unknown]: 'Unknown constraint.',
};

const CONSTRAINTS: Record<ConstraintType, Constraint> = {
	[ConstraintType.MaximumVolumeMoved]: {
		type: ConstraintType.MaximumVolumeMoved,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
		maxMovementCount: 1,
	},
	[ConstraintType.MinimumShipmentAmount]: {
		type: ConstraintType.MinimumShipmentAmount,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
		minimumShipmentAmount: 1,
	},
	[ConstraintType.MaximumShipmentAmount]: {
		type: ConstraintType.MaximumShipmentAmount,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
		maximumShipmentAmount: 1,
	},
	[ConstraintType.DoNotBreakSizeChart]: {
		type: ConstraintType.DoNotBreakSizeChart,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
	},
	[ConstraintType.CooldownPeriod]: {
		type: ConstraintType.CooldownPeriod,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
		minCooldownDays: 21,
	},
	[ConstraintType.MinimumROI]: {
		type: ConstraintType.MinimumROI,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
		minimumROI: 0.8,
	},
	[ConstraintType.MaximumSKUAmount]: {
		type: ConstraintType.MaximumSKUAmount,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
	},
	[ConstraintType.OnlyRestoreSizeCharts]: {
		type: ConstraintType.OnlyRestoreSizeCharts,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
	},
	[ConstraintType.Unknown]: {
		type: ConstraintType.MinimumROI,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
		minimumROI: 0.01,
	},
	[ConstraintType.MinimumProductAmount]: {
		type: ConstraintType.MinimumProductAmount,
		minimumProductAmount: 1,
		scope: { locations: [], products: [] },
		estimatedMissedRevenue: 0,
	},
};

const isNotUnknown = (type: ConstraintType): boolean =>
	type !== ConstraintType.Unknown;

const ConstraintPicker: FunctionComponent<Props> = ({
	append,
	configuredTypes,
}) => {
	const { isLoading, data } = useQuery<
		GetReportConstraintsParametersDTO,
		unknown,
		GetReportConstraintsResponseDTO
	>('constraints', GET_INVENTORY_ALLOCATION_REPORT_CONSTRAINTS);
	const mapper = new ReportMapper();
	const constraintTypes = (data?.items || [])
		.map(({ constraint_type }) => constraint_type)
		.map(mapper.constraintType.toInternal);

	console.log(data?.items, constraintTypes);
	if (isLoading) {
		return <p>Loading...</p>;
	}

	const isNotAlreadyConfigured = (type: ConstraintType): boolean =>
		!configuredTypes.some((configuredType) => configuredType === type);

	const onChange = (type: ConstraintType): void => {
		append(CONSTRAINTS[type]);
	};

	const appendableTypes = constraintTypes
		.filter(isNotUnknown)
		.filter(isNotAlreadyConfigured);

	if (appendableTypes.length === 0) {
		return null;
	}

	return (
		<Dropdown
			onChange={onChange}
			className="max-w-sm w-full -ml-4"
			placeholder="Add a constraint"
			options={appendableTypes.map((type) => ({
				label: LABEL[type],
				value: type,
			}))}
		/>
	);
};

export default ConstraintPicker;
