import useStrategyStore from '../../data/strategies';

import Dropdown from '../Dropdown/Dropdown';

const StrategySelector = ({ disabled = false }) => {
	const { strategies, activeStrategy, setActiveStrategy } = useStrategyStore();

	const options = strategies.map(({ id, name }) => ({
		value: id,
		label: name,
	}));

	if (disabled) {
		return <Dropdown className="w-64" placeholder="N/A" />;
	}

	return (
		<Dropdown
			onChange={setActiveStrategy}
			value={activeStrategy}
			options={options}
			className="w-64"
		/>
	);
};

export default StrategySelector;
