import PropTypes from 'prop-types';
import Button from '../Button/Button';
import PlusIcon from '../Icons/Plus';
import Text from '../Text/Text';

import CircularProgress from '../Progress/CircularProgress';

const FilterButton = ({ disabled, loading, onClick, noText }) => (
	<div className="inline-flex items-center">
		{!disabled && (
			<Button
				className="flex gap-2"
				type="button"
				size="regular"
				variant="link"
				onClick={onClick}
			>
				<PlusIcon className="w-3" />
				{!noText && (
					<Text size="text-sm" className="font-bold">
						Add filter
					</Text>
				)}
			</Button>
		)}
		{loading && <CircularProgress size="small" />}
	</div>
);

FilterButton.propTypes = {
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	noText: PropTypes.bool,
};

FilterButton.defaultProps = {
	disabled: false,
	loading: false,
	onClick: () => {},
	noText: false,
};

export default FilterButton;
