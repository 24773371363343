import clsx from 'clsx';
import PropTypes from 'prop-types';

const Title = ({ type, className, children }) => {
	if (type === 'section') {
		return (
			<h2
				className={clsx('text-ca-section text-ca-black font-bold', className)}
			>
				{children}
			</h2>
		);
	}

	if (type === 'subsection') {
		return (
			<h3
				className={clsx(
					'text-ca-subsection text-ca-gray font-medium',
					className
				)}
			>
				{children}
			</h3>
		);
	}

	return (
		<h1 className={clsx('text-ca-screen text-ca-purple font-bold', className)}>
			{children}
		</h1>
	);
};

Title.propTypes = {
	type: PropTypes.oneOf(['screen', 'section', 'subsection']),
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

Title.defaultProps = {
	className: '',
	type: 'screen',
};

export default Title;
