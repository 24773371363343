import { QueryKey, useMutation, useQueryClient } from 'react-query';
import { MutationDTO } from '../models/API';

const useCreateMutation = <Params, RequestBody, Response>(
	queryKey: QueryKey,
	mutation: MutationDTO<Params, RequestBody, Response>,
	invalidateQueries?: QueryKey
) => {
	const queryClient = useQueryClient();

	const result = useMutation(mutation, {
		onMutate: async (newEntity) => {
			await queryClient.cancelQueries(queryKey);
			const previousEntities = queryClient.getQueryData(queryKey);

			queryClient.setQueryData<Response>(queryKey, (old: any) => {
				return {
					...old,
					items: [...(old?.items || []), newEntity],
					total: [...(old?.items || []), newEntity].length,
				};
			});

			return { previousEntities };
		},
		onError: (err, newEntity, context) => {
			queryClient.setQueryData(queryKey, context?.previousEntities);
		},
		onSettled: () => {
			queryClient.invalidateQueries(queryKey);
			if (invalidateQueries) {
				queryClient.invalidateQueries(invalidateQueries);
			}
		},
	});

	return result;
};

export default useCreateMutation;
