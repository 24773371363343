import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';
import Greeting from '../../shared/components/Greeting/Greeting';
import Title from '../../shared/components/Title/Title';

const UploadsHeader = () => (
	<div className="flex justify-between">
		<div className="flex flex-col gap-2">
			<Title>Uploads</Title>
			<Greeting />
		</div>
		<div className="flex flex-col gap-2">
			<ChannelSelector />
		</div>
	</div>
);

export default UploadsHeader;
