import { FunctionComponent } from 'react';
import { QueryKey, useQueries, UseQueryOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import { GET_INVENTORY_ALLOCATION_REPORT } from '../../../shared/api/inventory-allocation-reports';
import Table from '../../../shared/components/NewTable/Table';
import Tag, { TagColor } from '../../../shared/components/Tag/Tag';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import { ReportDTO, ReportStatusDTO } from '../../../shared/models/schema';
import capitalize from '../../../shared/utils/capitalize';

interface Props {
	id: InventoryAllocation['id'];
	drafts: InventoryAllocationReport['id'][];
}

enum Column {
	Title = 'inventory-allocation-report.title',
	Status = 'inventory-allocation-report.status',
}

const HEADINGS = [
	{ id: Column.Title, label: 'Title' },
	{ id: Column.Status, label: 'Status' },
];

interface StatusCellProps {
	status: ReportStatusDTO;
}

const StatusCell: FunctionComponent<StatusCellProps> = ({ status }) => {
	switch (status) {
		case ReportStatusDTO.Draft:
			return <Tag label={capitalize(status)} color={TagColor.White} />;
		case ReportStatusDTO.Error:
			return <Tag label={capitalize(status)} color={TagColor.Red} />;
		case ReportStatusDTO.Executed:
			return <Tag label={capitalize(status)} color={TagColor.Blue} />;
		case ReportStatusDTO.InProgress:
			return <Tag label={capitalize(status)} color={TagColor.Orange} />;
		case ReportStatusDTO.NoSuggestedMoves:
			return <Tag label={capitalize(status)} color={TagColor.Grey} />;
		case ReportStatusDTO.Proposal:
			return <Tag label={capitalize(status)} color={TagColor.Green} />;
		case ReportStatusDTO.Submitted:
			return <Tag label={capitalize(status)} color={TagColor.Purple} />;
		case ReportStatusDTO.Outdated:
			return <Tag label={capitalize(status)} color={TagColor.Black} />;
		default:
			return <Tag label="Unknown" color={TagColor.White} />;
	}
};

const toQuery = (
	id: InventoryAllocation['id'],
	reportId: InventoryAllocationReport['id'],
	queryKey: QueryKey
): UseQueryOptions => ({
	queryKey,
	queryFn: () => GET_INVENTORY_ALLOCATION_REPORT(id, reportId),
});

export const DraftsTable: FunctionComponent<Props> = ({ id, drafts }) => {
	const history = useHistory();
	const reportsPayload: {
		inventoryAllocationId: InventoryAllocation['id'];
		reportId: InventoryAllocationReport['id'];
	}[] = drafts.map((draft) => ({ inventoryAllocationId: id, reportId: draft }));

	const reportsResult = useQueries(
		reportsPayload.map(({ inventoryAllocationId, reportId }) =>
			toQuery(inventoryAllocationId, reportId as string, ['reports', reportId])
		)
	);

	const reports: ReportDTO[] = reportsResult.map(
		({ data }) => data
	) as ReportDTO[];

	const onRowClick = (row: ReportDTO): void => {
		history.push(`/stock/inventory-allocations/${id}/${row.id}/results`);
	};

	return (
		<Table
			loading={reportsResult.map(({ isLoading }) => isLoading).includes(true)}
			itemsLoading={5}
			headings={HEADINGS}
			rows={reports}
			rowKey="id"
			isInline
			onRowClick={onRowClick}
			renderCell={(row: ReportDTO, columnId: Column) => {
				switch (columnId) {
					case Column.Title:
						return <p className="w-128">{row.name}</p>;
					case Column.Status:
						return <StatusCell status={row.status} />;
					default:
						return null;
				}
			}}
		/>
	);
};
