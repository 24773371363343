import { useEffect, useState } from 'react';

type IsLoading = boolean;
type SetIsLoading = (isLoading: IsLoading) => void;
type UseIsLoading = (initial: IsLoading) => [IsLoading, SetIsLoading];

export const useIsLoading: UseIsLoading = (initial) => {
	const [isLoading, setIsLoading] = useState(initial);

	useEffect(() => {
		setIsLoading(initial);
	}, [initial]);

	return [isLoading, setIsLoading];
};
