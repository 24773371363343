import { InfoIcon } from 'lucide-react';

import clsx from 'clsx';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';

type StatusIndicatorProps = {
	status: 'warning' | 'success' | 'running' | 'fail' | 'inactive';
};

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
	switch (status) {
		case 'success':
			return (
				<span className="relative flex h-2 w-2 ml-1 rounded-full bg-ca-green" />
			);
		case 'fail':
			return (
				<span className="relative flex h-2 w-2 ml-1 rounded-full bg-ca-red" />
			);
		case 'running':
			return (
				<span className="relative flex h-2 w-2 ml-2">
					<span className="absolute inline-flex h-full w-full rounded-full opacity-80 animate-ping bg-ca-purple" />
					<span className="relative inline-flex rounded-full h-2 w-2 bg-ca-purple" />
				</span>
			);
		case 'warning':
			return (
				<span className="relative flex h-2 w-2 ml-2">
					<span className="absolute inline-flex h-full w-full rounded-full opacity-80 animate-ping bg-ca-orange" />
					<span className="relative inline-flex rounded-full h-2 w-2 bg-ca-orange" />
				</span>
			);
		default:
			return (
				<span className="relative flex h-2 w-2 ml-1 rounded-full bg-ca-gray" />
			);
	}
};

export type StatusLineProps = {
	tooltip: string | null;
	text: string;
	indicator: StatusIndicatorProps['status'];
	className?: string;
};

const StatusLine = ({
	tooltip,
	text,
	indicator,
	className,
}: StatusLineProps) => {
	return (
		<Tooltip content={tooltip}>
			<div className={clsx('flex items-center gap-1 pointer', className)}>
				{tooltip !== null && (
					<Text type="secondary">
						<InfoIcon className="w-3 h-3" />
					</Text>
				)}
				<span className="flex items-center">
					<Text type="secondary" size="text-xs">
						{text}
					</Text>
					<StatusIndicator status={indicator} />
				</span>
			</div>
		</Tooltip>
	);
};

export default StatusLine;
