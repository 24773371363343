import Tippy from '@tippyjs/react/headless';
import PropTypes from 'prop-types';

const Tooltip = ({ content, placement, children, isVisible }) => {
	if (!content) return children;

	return (
		<Tippy
			placement={placement}
			visible={isVisible}
			render={(attrs) => (
				<div
					className="bg-white text-ca-black text-xs p-2.5 rounded-lg shadow-ca max-w-sm"
					tabIndex="-1"
					{...attrs}
				>
					{content}
				</div>
			)}
		>
			{children}
		</Tippy>
	);
};

Tooltip.propTypes = {
	content: PropTypes.node,
	placement: PropTypes.oneOf([
		'top',
		'top-start',
		'top-end',
		'right',
		'right-start',
		'right-end',
		'bottom',
		'bottom-start',
		'bottom-end',
		'left',
		'left-start',
		'left-end',
	]),
	isVisible: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
	content: '',
	placement: 'top',
};

export default Tooltip;
