import PropTypes from 'prop-types';

const Product = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="13"
			fill="none"
			viewBox="0 0 12 13"
		>
			<path
				fill="currentColor"
				d="M11.606 3.37L9.18 2.047A.375.375 0 009 2H7.5a.375.375 0 00-.375.375 1.125 1.125 0 11-2.25 0A.375.375 0 004.5 2H3a.375.375 0 00-.18.047L.395 3.37a.739.739 0 00-.31.996L.99 6.092a.767.767 0 00.683.408h.953v3.75a.75.75 0 00.75.75h5.25a.75.75 0 00.75-.75V6.5h.954a.767.767 0 00.683-.408l.903-1.726a.739.739 0 00-.309-.996z"
			/>
		</svg>
	);
};

Product.propTypes = {
	className: PropTypes.string,
};

Product.defaultProps = {
	className: '',
};

export default Product;
