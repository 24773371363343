import { ModelAPI, ModelDictionary } from '@mswjs/data/lib/glossary';
import { RestRequest } from 'msw';
import { BaseEntity } from '../../shared/models/API';
import { Params } from '../../shared/models/params';
import {
	PaginatedParametersDTO,
	PaginatedResponseDTO,
} from '../../shared/models/schema';

function paginatedResponse<
	EntityDTO extends BaseEntity,
	Dictionary extends ModelDictionary,
	ModelName extends keyof Dictionary
>(
	request: RestRequest<never, Params<PaginatedParametersDTO>>,
	db: ModelAPI<Dictionary, ModelName>
): PaginatedResponseDTO<EntityDTO> {
	const DEFAULT_SIZE: Params<PaginatedParametersDTO['size']> = '50';
	const DEFAULT_PAGE: Params<PaginatedParametersDTO['page']> = '1';

	const searchParams = new URLSearchParams(request.url.searchParams);
	const size = parseInt(searchParams.get('size') || DEFAULT_SIZE, 10);
	const page = parseInt(searchParams.get('page') || DEFAULT_PAGE, 10);
	const take: number = size;
	const skip: number = (page - 1) * size;

	return {
		items: db.findMany({ take, skip }) as EntityDTO[],
		total: db.count(),
		page,
		size,
		pages: db.count() / size,
	};
}

export default paginatedResponse;
