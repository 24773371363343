import { useEffect, useState } from 'react';

const useKeyPress = (
	targetKey,
	onPressDown = () => {},
	onPressUp = () => {}
) => {
	const [keyPressed, setKeyPressed] = useState(false);

	const downHandler = ({ key }) => {
		if (key === targetKey) {
			setKeyPressed(true);
			onPressDown();
		}
	};

	const upHandler = ({ key }) => {
		if (key === targetKey) {
			setKeyPressed(false);
			onPressUp();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', downHandler);
		window.addEventListener('keyup', upHandler);

		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	});

	return keyPressed;
};

export default useKeyPress;
