import { FunctionComponent, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { useInventoryAllocations } from '../../../domains/domains';
import { GET_INVENTORY_ALLOCATION } from '../../../shared/api/inventory-allocations';
import ChannelSelector from '../../../shared/components/ChannelSelector/ChannelSelector';
import Page from '../../../shared/components/Page/Page';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';
import Title from '../../../shared/components/Title/Title';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import InventoryAllocationDetail from './InventoryAllocationDetail';

interface Params {
	id: InventoryAllocation['id'];
}

const InventoryAllocationView: FunctionComponent = () => {
	const { id } = useParams<Params>();

	const {
		entity: inventoryAllocation,
		isLoading,
		refetch,
	} = useInventoryAllocations(
		useQuery(['inventory-allocations', id], () => GET_INVENTORY_ALLOCATION(id))
	);

	useEffect(() => {
		refetch();
	}, [id]);

	return (
		<Page>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress visible={isLoading} />
			</div>
			<div className="relative flex justify-between">
				<Title>
					{isLoading
						? 'Loading...'
						: inventoryAllocation?.title || 'Inventory allocation not found'}
				</Title>
				<ChannelSelector />
			</div>
			<Link
				to="/stock/inventory-allocations"
				className="text-ca-gray hover:text-ca-purple hover:font-bold transition-colors"
			>
				{'<- Inventory Allocations'}
			</Link>
			{inventoryAllocation?.id && (
				<InventoryAllocationDetail
					{...inventoryAllocation}
					isLoading={isLoading}
				/>
			)}
		</Page>
	);
};

export default InventoryAllocationView;
