import { FunctionComponent } from 'react';
import Button from '../../../shared/components/Button/Button';
import Plus from '../../../shared/components/Icons/Plus';
import CircularProgress from '../../../shared/components/Progress/CircularProgress';

interface Props {
	addDraft: () => void;
	isAddingDraft?: boolean;
}

const InventoryAllocationReportNoMovements: FunctionComponent<Props> = ({
	addDraft,
	isAddingDraft,
}) => (
	<div>
		<p className="text-ca-gray mt-10">
			There are no movements which could improve the current situation
		</p>
		<p className="text-ca-gray">under these conditions.</p>
		<p className="text-ca-gray mb-10">
			Try to adapt your constraints and secondary objectives.
		</p>
		<Button className="flex gap-3" onClick={addDraft} disabled={isAddingDraft}>
			{isAddingDraft ? (
				<CircularProgress size="text" />
			) : (
				<Plus className="h-3" />
			)}
			{isAddingDraft ? 'Adding draft' : 'Add draft'}
		</Button>
	</div>
);

export default InventoryAllocationReportNoMovements;
