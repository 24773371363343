import PropTypes from 'prop-types';

const Info = (props) => (
	<svg
		className={props.className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 256 256"
		{...props}
	>
		<path
			fill="currentColor"
			d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88Zm16-40a8 8 0 0 1-8 8a16 16 0 0 1-16-16v-40a8 8 0 0 1 0-16a16 16 0 0 1 16 16v40a8 8 0 0 1 8 8Zm-32-92a12 12 0 1 1 12 12a12 12 0 0 1-12-12Z"
		/>
	</svg>
);

Info.propTypes = {
	className: PropTypes.string,
};

Info.defaultProps = {
	className: '',
};

export default Info;
