import { FunctionComponent } from 'react';
import Title from '../../../shared/components/Title/Title';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import MarketingExpectationOption, {
	NoMarketingExpectations,
} from './MarketingExpectationOption';

interface Props extends Pick<Configuration, 'marketingExpectations'> {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
}

const MarketingInfluenceWizardSection: FunctionComponent<Props> = ({
	marketingExpectations,
	inventoryAllocationId,
	reportId,
}) => {
	return (
		<div className="my-16">
			<Title type="section">Marketing influence</Title>
			<p className="text-ca-gray max-w-lg pt-2 pb-8">
				Marketing campaigns might influence the prospected sales. This section
				allows you to estimate the effect of those campaigns.
			</p>
			<div className="flex flex-col gap-2">
				{marketingExpectations.length === 0 && <NoMarketingExpectations />}
				{marketingExpectations.map((marketingExpectation) => (
					<MarketingExpectationOption
						key={marketingExpectation.campaignName}
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						marketingExpectation={marketingExpectation}
					/>
				))}
			</div>
		</div>
	);
};

export default MarketingInfluenceWizardSection;
