const Checkmark = ({ className = '' }) => (
	<svg
		width="10"
		height="8"
		viewBox="0 0 10 8"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M0.5 4L3.5 7L9.5 1"
			stroke="currentcolor"
			strokeWidth="1.1"
			fill="transparent"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Checkmark;
