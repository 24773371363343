import { faker } from '@faker-js/faker';
import { rest, RestHandler } from 'msw';
import { Params } from '../shared/models/params';
import {
	ConstraintTypeDTO,
	CreateInventoryAllocationBodyDTO,
	CreateInventoryAllocationParametersDTO,
	CreateInventoryAllocationResponseDTO,
	CreateReportBodyDTO,
	CreateReportParametersDTO,
	CreateReportResponseDTO,
	DeleteInventoryAllocationBodyDTO,
	DeleteInventoryAllocationParametersDTO,
	DeleteInventoryAllocationResponseDTO,
	GetInventoryAllocationBodyDTO,
	GetInventoryAllocationParametersDTO,
	GetInventoryAllocationResponseDTO,
	GetInventoryAllocationsBodyDTO,
	GetInventoryAllocationsParametersDTO,
	GetInventoryAllocationsResponseDTO,
	GetReportBodyDTO,
	GetReportConstraintsBodyDTO,
	GetReportConstraintsParametersDTO,
	GetReportConstraintsResponseDTO,
	GetReportParametersDTO,
	GetReportResponseDTO,
	GetReportsBodyDTO,
	GetReportSecondaryObjectivesBodyDTO,
	GetReportSecondaryObjectivesParametersDTO,
	GetReportSecondaryObjectivesResponseDTO,
	GetReportsParametersDTO,
	GetReportsResponseDTO,
	InventoryAllocationDTO,
	PaginatedParametersDTO,
	ReportStatusDTO,
	SecondaryObjectiveTypeDTO,
	UpdateInventoryAllocationBodyDTO,
	UpdateInventoryAllocationParametersDTO,
	UpdateInventoryAllocationResponseDTO,
	UpdateReportBodyDTO,
	UpdateReportParametersDTO,
	UpdateReportResponseDTO,
} from '../shared/models/schema';
import db from './db';
import paginatedResponse from './utils/paginatedResponse';

function api(path: string): string {
	return `${window._ENV_.REACT_APP_VULCAN_API}${path}`;
}

const DELAY: number | undefined = undefined;

const handlers: RestHandler[] = [
	/**
	 * ---------- REPORTS ----------
	 */

	rest.post<
		CreateReportBodyDTO,
		Params<CreateReportParametersDTO>,
		CreateReportResponseDTO
	>(api('api/v1/reports/:allocation_id'), async (req, res, ctx) => {
		const body: CreateReportBodyDTO = await req.json();

		const report: any = db.report.create({
			name: body.name,
			scopes: (body.scopes || []).map(db.scope.create),
			constraints: (body.constraints || []).map(({ scopes, ...constraint }) =>
				db.constraint.create({
					...constraint,
					scopes: scopes.map(db.scope.create),
				})
			),
			secondary_objectives: (body.secondary_objectives || []).map(
				({ scopes, ...secondaryObjective }) =>
					db.secondaryObjective.create({
						...secondaryObjective,
						scopes: scopes.map(db.scope.create),
					})
			),
			marketing_actions: (body.marketing_actions || []).map(
				db.marketingAction.create
			),
			date_created: new Date().toISOString(),
			date_updated: new Date().toISOString(),
			timestamp_last_update: new Date().toISOString(),
			status: ReportStatusDTO.Draft,
			allocation_id: req.params.allocation_id,
			possible_revenue_gain: 0,
			proposed_number_of_movements: 0,
			proposed_number_of_receivers: 0,
			proposed_number_of_senders: 0,
		});

		return res(ctx.status(200), ctx.delay(DELAY), ctx.json(report));
	}),

	rest.get<
		GetReportConstraintsBodyDTO,
		Params<GetReportConstraintsParametersDTO>,
		GetReportConstraintsResponseDTO
	>(api('api/v1/reports/constraints'), (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json({
				items: [
					{
						constraint_type: ConstraintTypeDTO.CooldownPeriod,
						lost_revenue: 0,
					},
					{
						constraint_type: ConstraintTypeDTO.DoNotBreakSizeCharts,
						lost_revenue: 0,
					},
					{
						constraint_type: ConstraintTypeDTO.ExcludeProducts,
						lost_revenue: 0,
					},
					{
						constraint_type: ConstraintTypeDTO.MaximumVolumeMoved,
						lost_revenue: 0,
					},
					{ constraint_type: ConstraintTypeDTO.MinimumROI, lost_revenue: 0 },
				],
				total: 5,
				page: 1,
				size: 50,
				pages: 1,
			})
		);
	}),

	rest.get<
		GetReportSecondaryObjectivesBodyDTO,
		Params<GetReportSecondaryObjectivesParametersDTO>,
		GetReportSecondaryObjectivesResponseDTO
	>(api('api/v1/reports/secondary_objectives'), (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json({
				items: [
					{
						secondary_objective_type:
							SecondaryObjectiveTypeDTO.FixBrokenSizeChart,
						lost_revenue: 0,
					},
					{
						secondary_objective_type:
							SecondaryObjectiveTypeDTO.PenalizeBrokenSizeCharts,
						lost_revenue: 0,
					},
				],
				total: 3,
				page: 1,
				size: 50,
				pages: 1,
			})
		);
	}),

	rest.get<
		GetReportsBodyDTO,
		Params<GetReportsParametersDTO>,
		GetReportsResponseDTO
	>(api('api/v1/allocations/reports/:allocation_id'), (req, res, ctx) => {
		const DEFAULT_SIZE: Params<PaginatedParametersDTO['size']> = '50';
		const DEFAULT_PAGE: Params<PaginatedParametersDTO['page']> = '1';

		const searchParams = new URLSearchParams(req.url.searchParams);
		const size = parseInt(searchParams.get('size') || DEFAULT_SIZE, 10);
		const page = parseInt(searchParams.get('page') || DEFAULT_PAGE, 10);
		const take: number = size;
		const skip: number = (page - 1) * size;

		const items = db.report.findMany({
			take,
			skip,
			where: {
				allocation_id: { equals: req.params.allocation_id },
			},
		});

		const total = db.report.findMany({
			where: {
				allocation_id: { equals: req.params.allocation_id },
			},
		}).length;

		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json({
				items,
				total,
				page,
				size,
				pages: total / size,
			} as any)
		);
	}),

	rest.get<
		GetReportBodyDTO,
		Params<GetReportParametersDTO>,
		GetReportResponseDTO
	>(api('api/v1/reports/:report_id'), (req, res, ctx) => {
		const report = db.report.findFirst({
			where: { id: { equals: req.params.report_id } },
		});

		if (!report) {
			return res(ctx.status(404), ctx.delay(DELAY));
		}

		return res(ctx.status(200), ctx.delay(DELAY), ctx.json(report as any));
	}),

	rest.put<
		UpdateReportBodyDTO,
		Params<UpdateReportParametersDTO>,
		UpdateReportResponseDTO
	>(api('api/v1/reports/:report_id'), async (req, res, ctx) => {
		const body: UpdateReportBodyDTO = await req.json();

		const constraints = (body.constraints || []).map((constraint) =>
			db.constraint.create({
				...constraint,
				scopes: constraint.scopes.map(db.scope.create),
			})
		);

		const secondaryObjectives = (body.secondary_objectives || []).map(
			(secondaryObjective) =>
				db.secondaryObjective.create({
					...secondaryObjective,
					scopes: secondaryObjective.scopes.map(db.scope.create),
				})
		);

		if (body.status === ReportStatusDTO.InProgress) {
			setTimeout(() => {
				db.report.update({
					where: { id: { equals: req.params.report_id } },
					data: {
						status: ReportStatusDTO.Proposal,
						proposed_number_of_movements: faker.datatype.number(),
						proposed_number_of_receivers: faker.datatype.number(),
						proposed_number_of_senders: faker.datatype.number(),
						date_updated: new Date().toISOString(),
						timestamp_last_update: new Date().toISOString(),
						possible_revenue_gain: faker.datatype.number(),
					},
				});
			}, 25000);
		}

		const report = db.report.update({
			where: { id: { equals: req.params.report_id } },
			data: {
				...(body.name ? { name: body.name } : {}),
				...(body.scopes ? { scopes: body.scopes.map(db.scope.create) } : {}),
				...(body.constraints ? { constraints } : {}),
				...(body.secondary_objectives
					? { secondary_objectives: secondaryObjectives }
					: {}),
				...(body.marketing_actions
					? {
							marketing_actions: body.marketing_actions.map(
								db.marketingAction.create
							),
					  }
					: {}),
				...(body.status ? { status: body.status } : {}),
				date_updated: new Date().toISOString(),
				timestamp_last_update: new Date().toISOString(),
			},
		});

		if (!report) {
			return res(ctx.status(422), ctx.delay(DELAY));
		}

		return res(ctx.status(200), ctx.delay(DELAY), ctx.json(report as any));
	}),

	/**
	 * ---------- INVENTORY ALLOCATIONS ----------
	 */

	rest.get<
		GetInventoryAllocationsBodyDTO,
		Params<GetInventoryAllocationsParametersDTO>,
		GetInventoryAllocationsResponseDTO
	>(api('api/v1/allocations'), (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json(paginatedResponse(req, db.inventoryAllocation))
		);
	}),

	rest.get<
		GetInventoryAllocationBodyDTO,
		Params<GetInventoryAllocationParametersDTO>,
		GetInventoryAllocationResponseDTO
	>(api('api/v1/allocations/:allocation_id'), (req, res, ctx) => {
		const inventoryAllocation: InventoryAllocationDTO | null =
			db.inventoryAllocation.findFirst({
				where: { id: { equals: req.params.allocation_id } },
			});

		if (!inventoryAllocation) {
			return res(ctx.status(404), ctx.delay(DELAY));
		}

		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json(inventoryAllocation)
		);
	}),

	rest.post<
		CreateInventoryAllocationBodyDTO,
		Params<CreateInventoryAllocationParametersDTO>,
		CreateInventoryAllocationResponseDTO
	>(api('api/v1/allocations'), async (req, res, ctx) => {
		const body: CreateInventoryAllocationBodyDTO = await req.json();

		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json(
				db.inventoryAllocation.create({
					name: body.name,
					date_created: new Date().toISOString(),
					date_updated: new Date().toISOString(),
					timestamp_last_update: new Date().toISOString(),
					submitted_report: null,
					report_ids: [],
				})
			)
		);
	}),

	rest.put<
		UpdateInventoryAllocationBodyDTO,
		Params<UpdateInventoryAllocationParametersDTO>,
		UpdateInventoryAllocationResponseDTO
	>(api('api/v1/allocations/:allocation_id'), async (req, res, ctx) => {
		const body: UpdateInventoryAllocationBodyDTO = await req.json();
		const inventoryAllocation = db.inventoryAllocation.findFirst({
			where: { id: { equals: req.params.allocation_id } },
		});

		const updatedInventoryAllocation = db.inventoryAllocation.update({
			where: { id: { equals: req.params.allocation_id } },
			data: {
				...(body.name ? { name: body.name } : {}),
				...(body.status ? { status: body.status } : {}),
				...(body.submitted_report
					? { submitted_report: body.submitted_report }
					: {}),
				...(body.report_id_to_add
					? {
							report_ids: [
								...(inventoryAllocation?.report_ids || []),
								body.report_id_to_add,
							],
					  }
					: {}),
				...(body.report_id_to_remove
					? {
							report_ids: (inventoryAllocation?.report_ids || []).filter(
								(report_id) => report_id === body.report_id_to_remove
							),
					  }
					: {}),
			},
		});

		if (!updatedInventoryAllocation) {
			return res(ctx.status(422), ctx.delay(DELAY));
		}

		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json(updatedInventoryAllocation)
		);
	}),

	rest.delete<
		DeleteInventoryAllocationBodyDTO,
		Params<DeleteInventoryAllocationParametersDTO>,
		DeleteInventoryAllocationResponseDTO
	>(api('api/v1/allocations/:allocation_id'), (req, res, ctx) => {
		if (!req.params.allocation_id) {
			console.error('Bad user input');
			return res(
				ctx.status(400),
				ctx.delay(DELAY),
				ctx.json({
					success: false,
				})
			);
		}

		if (
			!db.inventoryAllocation.findFirst({
				where: { id: { equals: req.params.allocation_id } },
			})
		) {
			console.error('Inventory allocation not found');
			return res(
				ctx.status(404),
				ctx.delay(DELAY),
				ctx.json({
					success: false,
				})
			);
		}

		db.inventoryAllocation.delete({
			where: { id: { equals: req.params.allocation_id } },
		});

		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json({
				success: true,
			})
		);
	}),

	/**
	 * ---------- DEPRECATED ----------
	 */

	rest.get(api('api/v1/products'), (req, res, ctx) => {
		const searchParams = new URLSearchParams(req.url.searchParams);
		const size = parseInt(searchParams.get('size') || '', 10);
		const page = parseInt(searchParams.get('page') || '', 10);
		const take: number = size;
		const skip: number = (page - 1) * size;

		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json({
				items: db.product.findMany({ take, skip }),
				total: db.product.count(),
			})
		);
	}),

	rest.get(api('api/v1/locations'), (req, res, ctx) => {
		const searchParams = new URLSearchParams(req.url.searchParams);
		const size = parseInt(searchParams.get('size') || '', 10);
		const page = parseInt(searchParams.get('page') || '', 10);
		const take: number = size;
		const skip: number = (page - 1) * size;

		return res(
			ctx.status(200),
			ctx.delay(DELAY),
			ctx.json({
				items: db.location.findMany({ take, skip }),
				total: db.location.count(),
			})
		);
	}),
];

export default handlers;
