import { PayloadWithId } from '../../strategy/types/helpers';
import {
	ObjectiveCreateParamsType,
	ObjectivesDataType,
	ObjectivesOrderParamsType,
	ObjectiveUpdateParamsType,
	ProductFiltersDataType,
	ProductsSchemaDataType,
} from '../../strategy/types/objectives';
import api from './api';

/**
 * new file to avoid conflicts with existing components
 */

export const LIST_OBJECTIVES = async (): Promise<ObjectivesDataType> => {
	const res: any = await api.get(`api/v2/objective-groups`).json();
	// we are only interested in items
	return res.items;
};

export const ORDER_OBJECTIVES = (payload: ObjectivesOrderParamsType) => {
	return api
		.post(`api/v2/objective-groups/priorities`, {
			json: payload,
		})
		.json();
};

export const CREATE_OBJECTIVE = (payload: ObjectiveCreateParamsType) => {
	return api
		.post('api/v2/objective-groups', {
			json: payload,
		})
		.json();
};

export const UPDATE_OBJECTIVE = ({
	id: objective_group_id,
	...payload
}: PayloadWithId<ObjectiveUpdateParamsType>) => {
	return api
		.put(`api/v2/objective-groups/${objective_group_id}`, {
			json: payload,
		})
		.json();
};

export const DELETE_OBJECTIVE = (objective_group_id: string) => {
	return api.delete(`api/v2/objective-groups/${objective_group_id}`).json();
};

export const GET_ALL_PRODUCTS = (searchParams: URLSearchParams) => {
	return api
		.get('api/v2/objective-groups/products', {
			searchParams,
		})
		.json();
};

export const GET_PRODUCTS_SCHEMA = (): Promise<ProductsSchemaDataType> => {
	return api.get('api/v2/objective-groups/schema').json();
};

export const GET_PRODUCT_FILTERS = (): Promise<ProductFiltersDataType> => {
	return api.get('api/v2/objective-groups/filters').json();
};
