import { useHistory } from 'react-router-dom';
import { useIsLoading } from '../../shared/hooks/useIsLoading';
import { Mutation, QueryResult } from '../../shared/models/API';
import { InventoryAllocation } from '../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../shared/models/inventoryAllocationReport';
import {
	CreateReportInput,
	StartAlgorithmInput,
	SubmitReportInput,
	UpdateReportInput,
} from './models/reportInput';

export type ReportAPI = QueryResult<InventoryAllocationReport>;

export type CreateReportMutation = Mutation<
	CreateReportInput,
	InventoryAllocationReport
>;

export type UpdateReportMutation = Mutation<
	UpdateReportInput,
	InventoryAllocationReport
>;

export type StartAlgorithmMutation = Mutation<StartAlgorithmInput>;

export type SubmitReportMutation = Mutation<
	SubmitReportInput,
	InventoryAllocationReport
>;

interface Props {
	data: ReportAPI;
	createMutation: CreateReportMutation;
	updateMutation: UpdateReportMutation;
	startAlgorithmMutation: StartAlgorithmMutation;
	submitMutation: SubmitReportMutation;
}

interface Result extends ReportAPI {
	create: (
		input: CreateReportInput
	) => Promise<InventoryAllocationReport | undefined>;
	update: (
		input: UpdateReportInput
	) => Promise<InventoryAllocationReport | undefined>;
	startAlgorithm: (
		inventoryAllocationId: InventoryAllocation['id'],
		id: InventoryAllocationReport['id']
	) => Promise<void>;
	submit: (
		inventoryAllocationId: InventoryAllocation['id'],
		id: InventoryAllocationReport['id'],
		title: InventoryAllocationReport['title']
	) => Promise<void>;
	isLoading: boolean;
}

const useReportsUseCases = ({
	data,
	createMutation,
	updateMutation,
	startAlgorithmMutation,
	submitMutation,
}: Props): Result => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useIsLoading(data.isLoading);

	const create: Result['create'] = async (input) => {
		try {
			return await createMutation(input);
		} catch (error) {
			console.error(error);
		}

		return undefined;
	};

	const update: Result['update'] = async (input) => {
		try {
			return await updateMutation(input);
		} catch (error) {
			console.error(error);
		}

		return undefined;
	};

	const startAlgorithm: Result['startAlgorithm'] = async (
		inventoryAllocationId,
		id
	) => {
		try {
			setIsLoading(true);
			await startAlgorithmMutation({
				inventoryAllocationId,
				id,
			});

			history.push(
				`/stock/inventory-allocations/${inventoryAllocationId}/${id}/results`
			);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const submit: Result['submit'] = async (inventoryAllocationId, id, title) => {
		try {
			await submitMutation({ inventoryAllocationId, id, title });
		} catch (error) {
			console.error(error);
		}
	};

	return {
		...data,
		create,
		update,
		startAlgorithm,
		submit,
		isLoading,
	};
};

export default useReportsUseCases;
