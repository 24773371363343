import Page from '../../shared/components/Page/Page';
import UploadsHeader from '../components/UploadsHeader';
import UploadsTable from '../components/UploadsTable';

const UploadsView = () => {
	return (
		<Page>
			<UploadsHeader />
			<div className="mt-10">
				<UploadsTable />
			</div>
		</Page>
	);
};

export default UploadsView;
