import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Fragment, useRef, useState } from 'react';
import PlusIcon from '../Icons/Plus';

import useKeyPress from '../../hooks/useKeyPress';
import useOnClickOutside from '../../hooks/useOnClickOutside';

import search from '../../utils/search';
import CheckmarkIcon from '../Icons/Checkmark';
import CrossIcon from '../Icons/Cross';
import TriangleDownIcon from '../Icons/TriangleDown';

const Dropdown = ({
	value,
	placeholder,
	options,
	size,
	contentClassName,
	multiple,
	error,
	className,
	onChange,
	onNewButtonClick,
	direction,
	dropUpDown,
	createNewButton,
	allowEmpty,
	searchable = false,
}) => {
	const ref = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [query, setQuery] = useState('');

	useOnClickOutside(ref, () => setIsOpen(false));
	useKeyPress('Escape', () => setIsOpen(false));

	const internalValue = (Array.isArray(value) ? value : [value]).filter(
		(v) => v !== undefined && v !== null
	);

	const activeOptions = options.filter((option) =>
		internalValue.includes(option.value)
	);

	const handleChange = (val) => {
		if (multiple) {
			if (internalValue.includes(val)) {
				onChange(internalValue.filter((v) => v !== val));
			} else {
				onChange([...internalValue, val]);
			}
		} else {
			onChange(val);
			setIsOpen(false);
		}
	};

	const clearChoices = (event) => {
		onChange(null);
		setIsOpen(false);
		event.stopPropagation();
	};

	const newButtonClick = () => {
		setIsOpen(false);
		onNewButtonClick();
	};

	return (
		<div className={clsx('inline-block text-left', className)} ref={ref}>
			<div
				role="button"
				tabIndex="0"
				onClick={() => setIsOpen(!isOpen)}
				onKeyDown={null}
				className={clsx(
					'relative inline-flex justify-between items-center w-full border  bg-white leading-none transition-colors focus:outline-none focus:ring-4 focus:ring-opacity-10',

					size === 'regular' && 'py-3 px-4 rounded-lg text-sm',
					size === 'small' && 'py-1.5 px-2 rounded text-xs',
					{
						'text-ca-black': !!activeOptions.length && !error,
						'text-ca-gray': !activeOptions.length && !error,
					},
					isOpen && 'border-ca-purple ring-4 ring-opacity-10 ring-ca-purple',
					!error &&
						'focus:border-ca-purple focus:ring-ca-purple border-ca-silver',
					error && 'text-ca-red border-ca-red focus:ring-ca-red'
				)}
			>
				<span
					className={clsx({
						'text-ca-black': !!activeOptions.length && !error,
						'text-ca-gray': !activeOptions.length && !error,
						'text-ca-red': error,
					})}
				>
					{!activeOptions?.length
						? placeholder
						: activeOptions.map(({ label }) => label).join(', ')}
				</span>
				<span className="flex justify-between">
					{allowEmpty && activeOptions?.length ? (
						<button onClick={clearChoices} type="button">
							<CrossIcon className="shrink-0 w-4 text-ca-gray h-2 pointer-events-none" />
						</button>
					) : null}
					<TriangleDownIcon className="shrink-0 text-ca-gray h-2 pointer-events-none" />
				</span>
				{!!options.length && (
					<Transition
						show={isOpen}
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<div
							className={clsx(
								`absolute ${direction}-0  min-w-full w-auto py-1 bg-white rounded-lg shadow-ca overflow-y-auto focus:outline-none z-20`,
								dropUpDown === 'down' && 'origin-top-right mt-2 top-full',
								dropUpDown === 'up' && 'origin-bottom-right bottom-full mb-2',
								contentClassName
							)}
						>
							{searchable && (
								<input
									className="text-sm text-black px-4 mt-2 mb-2"
									placeholder="Search"
									onChange={(event) => setQuery(event.target.value)}
								/>
							)}
							{options
								.filter((option) => {
									if (query === '') {
										return option;
									}
									if (search(option.value, query)) {
										return option;
									}
									return null;
								})
								.map((option) => (
									<div
										{...(!option?.disabled && {
											tabIndex: '0',
											role: 'button',
											onClick: () => handleChange(option.value),
											className: 'cursor-pointer',
										})}
										key={option.value}
										onKeyDown={null}
									>
										<div
											className={clsx(
												option?.disabled
													? 'text-ca-gray cursor-not-allowed'
													: 'text-ca-black hover:bg-ca-silver',
												'flex justify-between items-center px-4 py-2 text-sm transition-colors '
											)}
										>
											<span>{option.label}</span>
											{internalValue.includes(option.value) && (
												<CheckmarkIcon className="ml-2 h-2 text-ca-purple" />
											)}
										</div>
									</div>
								))}
							{createNewButton && (
								<div
									tabIndex="0"
									role="button"
									key="add_new_scenario"
									onClick={newButtonClick}
									onKeyDown={null}
								>
									<div className="flex justify-start gap-2 items-center text-ca-black px-4 py-2 text-sm transition-colors hover:bg-ca-silver">
										<PlusIcon className="w-5 inline rounded-full text-white bg-ca-purple ring-1 p-1 ring-ca-purple" />{' '}
										<span>New</span>
									</div>
								</div>
							)}
						</div>
					</Transition>
				)}
			</div>
			{error && typeof error === 'string' && (
				<div className="w-full mt-1 pr-1 text-ca-red text-xs text-right">
					{error}
				</div>
			)}
		</div>
	);
};

Dropdown.propTypes = {
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.array,
	]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			disabled: PropTypes.bool,
		})
	),
	size: PropTypes.oneOf(['regular', 'small']),
	contentClassName: PropTypes.string,
	multiple: PropTypes.bool,
	error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	className: PropTypes.string,
	onChange: PropTypes.func,
	onNewButtonClick: PropTypes.func,
	direction: PropTypes.oneOf(['left', 'right']),
	dropUpDown: PropTypes.oneOf(['up', 'down']),
	createNewButton: PropTypes.bool,
	allowEmpty: PropTypes.bool,
};

Dropdown.defaultProps = {
	placeholder: 'Select',
	value: '',
	options: [],
	size: 'regular',
	contentClassName: 'max-h-40',
	multiple: false,
	error: false,
	className: '',
	onChange: () => {},
	onNewButtonClick: () => {},
	direction: 'left',
	dropUpDown: 'down',
	allowEmpty: false,
};

export default Dropdown;
