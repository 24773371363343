import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const LinearProgress = ({ visible, onAnimationEnded }) => {
	const [loaderRef, setLoaderRef] = useState();
	const [isMounted, setIsMounted] = useState(true);
	const [isVisible, setIsVisible] = useState(visible);

	useEffect(() => {
		if (!isVisible && visible) {
			setIsVisible(true);
		}
	}, [visible]);

	useEffect(() => {
		return () => setIsMounted(false);
	}, []);

	useEffect(() => {
		if (loaderRef) {
			const onAnimationIteration = () => {
				if (isMounted) {
					if (onAnimationEnded) {
						onAnimationEnded();
					}

					if (visible === false) {
						setIsVisible(false);
					}
				}
			};

			loaderRef.addEventListener('animationiteration', onAnimationIteration);

			return () => {
				loaderRef.removeEventListener(
					'animationiteration',
					onAnimationIteration
				);
			};
		}

		return () => {};
	}, [visible, loaderRef]);

	if (!isVisible) return null;

	return (
		<div className="relative h-1 overflow-hidden pointer-events-none">
			<div
				className="absolute top-0 left-0 w-full h-full bg-ca-purple animate-linear-progress"
				ref={setLoaderRef}
			/>
		</div>
	);
};

LinearProgress.propTypes = {
	visible: PropTypes.bool.isRequired,
	onAnimationEnded: PropTypes.func,
};

LinearProgress.defaultProps = {
	onAnimationEnded: null,
};

export default LinearProgress;
