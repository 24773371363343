import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

interface Props {
	items: Array<{
		id: string;
		to: string;
		label: string;
	}>;
}

const Nav = ({ items }: Props) => (
	<div className="w-full flex border-b border-ca-silver gap-10 py-1 ">
		{items.map((item) => (
			<NavLink
				key={item.id}
				to={item.to}
				className={(isActive) =>
					clsx(
						'py-2 hover:text-ca-purple font-semibold transition-colors border-b -mb-[5px]',
						isActive
							? 'text-ca-purple  border-b-ca-purple'
							: 'text-ca-gray border-b-transparent'
					)
				}
			>
				{item.label}
			</NavLink>
		))}
	</div>
);

export default Nav;
