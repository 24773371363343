import classnames from 'classnames';
import {
	FunctionComponent,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import { useQuery } from 'react-query';
import {
	GET_LOCATIONS,
	GET_LOCATIONS_IDS,
	GET_LOCATIONS_SCHEMA,
} from '../../../shared/api/locations';
import Table from '../../../shared/components/NewTable/Table';
import { useDebouncedQuery } from '../../../shared/hooks/useDebouncedQuery';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import {
	FilterDTO,
	GetLocationsIdsResponseDTO,
	GetLocationsParametersDTO,
	GetLocationsResponseDTO,
	GetLocationsSchemaParametersDTO,
	GetLocationsSchemaResponseDTO,
	StockLocationDTO,
	StockLocationFieldTypeDTO,
} from '../../../shared/models/schema';
import LocationsFilter from './LocationsFilter';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	selectedLocations?: StockLocationDTO['stock_location_id'][];
	isReadOnly?: boolean;
	className?: string;
	setSelectedLocations?: (
		locations: StockLocationDTO['stock_location_id'][]
	) => void;
	setShouldAskForConfirmation?: (shouldAskForConfirmation: boolean) => void;
}

interface Pagination {
	page: number;
	size: number;
}

const DEFAULT_PAGINATION: Pagination = {
	size: 50,
	page: 1,
};

const LocationsTable: FunctionComponent<Props> = ({
	selectedLocations = [],
	isReadOnly = false,
	className,
	setSelectedLocations,
	setShouldAskForConfirmation,
	inventoryAllocationId,
	reportId,
}) => {
	const [internalSelectedLocations, setInternalSelectedLocations] =
		useState<StockLocationDTO['stock_location_id'][]>(selectedLocations);
	const [filters, setFilters] = useState<Map<string, FilterDTO>>(new Map());
	const [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION);
	const [isShowingOnlySelected, toggleShowSelected] = useReducer((value) => {
		setPagination({ ...pagination, page: 1 });
		return !value;
	}, isReadOnly);
	const { isLoading: isSchemaLoading, data: schema } = useQuery<
		GetLocationsSchemaParametersDTO,
		unknown,
		GetLocationsSchemaResponseDTO
	>(['locations-schema'], GET_LOCATIONS_SCHEMA);

	const {
		isLoading: isLocationsLoading,
		isFetching: isLocationsFetching,
		data,
	} = useDebouncedQuery<
		GetLocationsParametersDTO,
		unknown,
		GetLocationsResponseDTO
	>(
		[
			'locations',
			pagination,
			Object.fromEntries(filters),
			isShowingOnlySelected ? internalSelectedLocations : [],
		],
		() =>
			GET_LOCATIONS(
				new URLSearchParams([
					['allocation_id', inventoryAllocationId],
					['report_id', reportId],
					['sort', 'stock_location_id:asc'],
					['page', pagination.page.toString()],
					['size', pagination.size.toString()],
					...(isShowingOnlySelected
						? [
								[
									'filter',
									`"id": "stock_location_id","value": ${JSON.stringify(
										internalSelectedLocations
									)}`,
								],
						  ]
						: []),
					...Array.from(filters.entries()).map(([id, value]) => [
						'filter',
						`"id":"${id}","value":${JSON.stringify(value)}`,
					]),
				])
			),
		{
			staleTime: 5 * 60 * 1000,
			keepPreviousData: true,
			enabled: true,
			debounceDelay: 1000,
		}
	);

	const { isLoading: isFilteredIdsLoading, data: filteredIds } = useQuery<
		{},
		unknown,
		GetLocationsIdsResponseDTO
	>(
		[
			'location-ids',
			Object.fromEntries(filters),
			isShowingOnlySelected ? internalSelectedLocations : [],
		],
		() =>
			GET_LOCATIONS_IDS(
				new URLSearchParams([
					['allocation_id', inventoryAllocationId],
					['report_id', reportId],
					...(isShowingOnlySelected
						? [
								[
									'filter',
									`"id": "stock_location_id","value": ${JSON.stringify(
										internalSelectedLocations
									)}`,
								],
						  ]
						: []),
					...Array.from(filters.entries()).map(([id, value]) => [
						'filter',
						`"id":"${id}","value":${JSON.stringify(value)}`,
					]),
				])
			)
	);

	const headings = useMemo(
		() =>
			schema?.map((s) => ({
				id: s.id,
				label: s.name,
				tooltip: s.tooltip,
				sortable: s.sortable,
			})),
		[schema]
	);

	const isLoading =
		isSchemaLoading ||
		isLocationsLoading ||
		isFilteredIdsLoading ||
		isLocationsFetching;

	const onSelect = useCallback(
		(id: string) => {
			const isNotId = (selectedId: string): boolean => selectedId !== id;

			if (internalSelectedLocations.includes(id)) {
				setSelectedLocations?.([...internalSelectedLocations.filter(isNotId)]);
				setInternalSelectedLocations?.([
					...internalSelectedLocations.filter(isNotId),
				]);
			} else {
				setSelectedLocations?.([...internalSelectedLocations, id]);
				setInternalSelectedLocations?.([...internalSelectedLocations, id]);
			}
		},
		[
			setSelectedLocations,
			setInternalSelectedLocations,
			internalSelectedLocations,
		]
	);

	const onDeselectAll = useCallback(() => {
		setSelectedLocations?.([]);
		setInternalSelectedLocations?.([]);
	}, [setSelectedLocations, setInternalSelectedLocations]);

	const onSelectAll = useCallback(() => {
		const areAllIdsAlreadySelected: boolean = (filteredIds || []).every((id) =>
			internalSelectedLocations.includes(id)
		);

		if (areAllIdsAlreadySelected) {
			setSelectedLocations?.([
				...internalSelectedLocations.filter(
					(id) => !(filteredIds || []).includes(id)
				),
			]);
			setInternalSelectedLocations?.([
				...internalSelectedLocations.filter(
					(id) => !(filteredIds || []).includes(id)
				),
			]);
		} else {
			setSelectedLocations?.([
				...new Set([...internalSelectedLocations, ...(filteredIds || [])]),
			]);
			setInternalSelectedLocations?.([
				...new Set([...internalSelectedLocations, ...(filteredIds || [])]),
			]);
		}
	}, [
		filteredIds,
		internalSelectedLocations,
		setSelectedLocations,
		setInternalSelectedLocations,
	]);

	const onFiltersChange = (newFilters: any) => {
		setFilters(newFilters);
		setPagination((p) => ({ ...p, page: 1 }));
		onDeselectAll();
	};

	const renderCell = useCallback(
		(row: StockLocationDTO, columnId: keyof StockLocationDTO) => {
			const fieldType = schema?.find(({ id }) => id === columnId)?.field_type;

			switch (fieldType) {
				case StockLocationFieldTypeDTO.Normal:
				case StockLocationFieldTypeDTO.Id:
					return row?.[columnId];
				default:
					return row?.[columnId];
			}
		},
		[data]
	);

	useEffect(() => {
		setShouldAskForConfirmation?.(internalSelectedLocations.length === 0);
	}, [internalSelectedLocations]);

	return (
		<>
			<div
				className={classnames('flex flex-row justify-between mb-4', className)}
			>
				<LocationsFilter filters={filters} onChange={onFiltersChange} />
				<span />
				{isReadOnly ? (
					<div className="flex items-center gap-8">
						<span className="text-ca-black">
							<strong>{internalSelectedLocations.length}</strong> Locations in
							scope
						</span>
					</div>
				) : (
					<div className="flex items-center gap-8">
						<button onClick={toggleShowSelected} className="text-ca-purple">
							<strong>{internalSelectedLocations.length}</strong> Locations in
							scope
						</button>
						<button onClick={onDeselectAll} className="text-ca-purple">
							Deselect all
						</button>
					</div>
				)}
			</div>
			<Table
				className="block overflow-x-scroll max-h-[32rem]"
				loading={isLoading}
				itemsLoading={10}
				rowKey="stock_location_id"
				headings={headings}
				rows={data?.items || []}
				emptyState="No locations in scope"
				disabled={!setSelectedLocations ? filteredIds : []}
				selectable={!isReadOnly}
				selected={internalSelectedLocations}
				onSelectedChange={(ids: string[]) => {
					const longestArray =
						ids.length > internalSelectedLocations.length
							? ids
							: internalSelectedLocations;
					const shortestArray =
						ids.length < internalSelectedLocations.length
							? ids
							: internalSelectedLocations;
					const difference = longestArray.filter(
						(id) => !shortestArray.includes(id)
					);

					if (difference.length > 1) {
						onSelectAll();
					} else {
						onSelect(difference[0]);
					}
				}}
				pagination={{
					currentPage: pagination.page,
					itemsPerPage: pagination.size,
					items: data?.total ?? 0,
				}}
				onPageChange={(page) => {
					setPagination({ ...pagination, page });
				}}
				renderCell={renderCell}
			/>
		</>
	);
};

export default LocationsTable;
