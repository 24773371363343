import Icon from './Icon';

const Experiment: Icon = ({ className = '' }) => (
	<svg
		className={className}
		viewBox="0 0 20 20"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.4606 12C15.0906 13.7543 13.6582 15.1584 12 16.4485C13.3738 17.2407 16.597 18.6766 17.6413 17.634C18.3541 16.9223 18.0265 14.6961 16.4606 12ZM10.0005 15C11.8896 13.618 13.6189 11.8889 15 10C13.6246 8.11775 11.8962 6.38672 10.0005 5C8.09807 6.39053 6.37259 8.12345 5 10C6.38115 11.8899 8.11139 13.618 10.0005 15ZM8 16.4494C6.33797 15.1552 4.90555 13.7508 3.53942 12C1.97346 14.6967 1.64586 16.9233 2.35871 17.6351C3.39339 18.6674 6.58872 17.2648 8 16.4494ZM3.53942 8C4.90843 6.24585 6.34085 4.84272 8 3.55181C6.62811 2.75962 3.40396 1.32292 2.35871 2.3664C1.64586 3.07803 1.97346 5.30404 3.53942 8ZM12 3.54185C13.6523 4.83758 15.0754 6.2448 16.4345 8C19.89 2.00102 17.2769 0.471714 12 3.54185ZM17.6642 10.0062C23.0983 18.4971 18.4277 23.0648 10.0015 17.6739C1.59529 23.0518 -3.1143 18.525 2.33881 10.0062C-3.05841 1.57413 1.4925 -3.10535 10.0015 2.33841C18.4247 -3.05047 23.1033 1.50828 17.6642 10.0062Z"
			fill="currentColor"
		/>
		<circle cx="10" cy="10" r="1.5" fill="currentColor" />
	</svg>
);

export default Experiment;
