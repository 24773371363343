import dayjs from 'dayjs';
import { Badge, BadgeProps } from '../../shared/components/Badge/Badge';
import Checkmark from '../../shared/components/Icons/Checkmark';
import Cross from '../../shared/components/Icons/Cross';
import Icon from '../../shared/components/Icons/Icon';
import Info from '../../shared/components/Icons/Info';
import Progress from '../../shared/components/Icons/Progress';
import TimeSince from '../../strategy/v2/TimeSince';
import { UploadStatus, UploadValidationErrors } from '../types/uploads';
import UploadsValidationTooltip from './UploadsValidationTooltip';

export type UploadStatusBadgeProps = {
	status?: UploadStatus;
	validationErrors?: UploadValidationErrors;
	createdAt?: string;
	updatedAt?: string;
};

function mapBadge(
	status: UploadStatusBadgeProps['status'],
	createdAt?: string,
	updatedAt?: string
): {
	variant: BadgeProps['variant'];
	message: string;
	icon?: Icon;
	sinceDate?: string;
} {
	switch (status) {
		case undefined:
			return { variant: 'neutral', message: 'No upload' };
		case 'failed':
			return {
				variant: 'destructive',
				message: 'Internal error',
				icon: Cross,
				sinceDate: updatedAt,
			};
		case 'data_validation_failed':
			return {
				variant: 'destructive',
				message: 'Validation failed',
				icon: Info,
				sinceDate: updatedAt,
			};
		case 'finished':
			return {
				variant: 'success',
				message: 'Success',
				sinceDate: updatedAt,
				icon: Checkmark,
			};
		default:
			return {
				variant: 'colorPurple',
				message: 'Processing',
				icon: Progress,
				sinceDate: createdAt,
			};
	}
}

export default function UploadsStatusBadge({
	status,
	validationErrors,
	createdAt,
	updatedAt,
}: UploadStatusBadgeProps) {
	const { variant, message, icon, sinceDate } = mapBadge(
		status,
		createdAt,
		updatedAt
	);
	// Tooltip doesn't work with <Badge /> directly (reason unknown) so we wrap it with a div.
	return (
		<div className="flex flex-col justify-center p-2 gap-1">
			<UploadsValidationTooltip validationErrors={validationErrors}>
				<div className="w-fit">
					<Badge
						className="flex flex-row justify-center items-center gap-1 w-40"
						variant={variant}
					>
						{icon !== undefined && icon({ className: 'max-w-4' })}
						<span>{message}</span>
					</Badge>
				</div>
			</UploadsValidationTooltip>
			{sinceDate !== undefined && (
				<span className="text-xxs text-ca-gray-500 -mb-1.5">
					<TimeSince time={dayjs(new Date(sinceDate))} />
				</span>
			)}
		</div>
	);
}
