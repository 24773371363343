import PropTypes from 'prop-types';

const TriangleLeft = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 8 12"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m8 10.9797418v-9.9595125c0-.56345708-.45183958-1.0202293-1.00921194-1.0202293-.234076 0-.460873.08225518-.64156335.23268346l-5.98154758 4.97975627c-.43025258.35819375-.49180351 1.00116345-.13747786 1.43611301.04129473.050691.08733427.09723314.13747786.13897867l5.98154758 4.97975629c.43025258.3581937 1.06627891.2959709 1.42060456-.1389787.14880381-.1826629.23017073-.4119358.23017073-.6485672z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

TriangleLeft.propTypes = {
	className: PropTypes.string,
};

TriangleLeft.defaultProps = {
	className: '',
};

export default TriangleLeft;
