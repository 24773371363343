import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { EElementState } from '../types/MarkdownTransferTypes';

interface Props {
	state: EElementState;
}

const MarkdownTransferBrace: FunctionComponent<Props> = (props) => {
	return (
		<div
			className={clsx(
				props.state === 'disabled' ? 'opacity-30' : 'opacity-100',
				props.state === 'focused' ? 'border-ca-purple' : '',
				'flex flex-col items-center mt-4 transition-opacity duration-500'
			)}
		>
			<div
				className={clsx(
					props.state === 'focused' ? 'border-ca-purple' : 'border-gray-300',
					'border border-t-0 rounded-b h-4 w-full'
				)}
			/>
			<div
				className={clsx(
					props.state === 'focused' ? 'border-ca-purple' : 'border-gray-300',
					'h-4 border-l'
				)}
			/>
		</div>
	);
};

export default MarkdownTransferBrace;
