import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '../../utils/styles';

// FINISHTHISs

const badgeVariants = cva(
	'inline-flex items-center rounded border border-slate-200 px-2.5 py-0.5 text-xs font-normal transition-colors focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2',
	{
		variants: {
			variant: {
				default: 'border-transparent bg-ca-purple-bg text-ca-purple',
				success: 'border-transparent bg-ca-green-bg text-ca-green',
				neutral: 'border-transparent bg-ca-gray-bg text-ca-gray-500',
				destructive:
					'border-transparent bg-ca-destructive-bg text-ca-destructive',
				outline: 'text-ca-black',
				colorGreen: 'border-transparent bg-badge-green text-badge-green',
				colorBlue: 'border-transparent bg-badge-blue text-badge-blue',
				colorIndigo: 'border-transparent bg-badge-indigo text-badge-indigo',
				colorPurple: 'border-transparent bg-badge-purple text-badge-purple',
				colorAppelblauwzeegroen:
					'border-transparent bg-badge-appelblauwzeegroen text-badge-appelblauwzeegroen',
				colorPink: 'border-transparent bg-badge-pink text-badge-pink',
				colorFuchsia: 'border-transparent bg-badge-fuchsia text-badge-fuchsia',
				colorOrange: 'border-transparent bg-badge-orange text-badge-orange',
				colorYellow: 'border-transparent bg-badge-yellow text-badge-yellow',
				colorGray: 'border-transparent bg-badge-gray text-badge-gray',
				colorLime: 'border-transparent bg-badge-lime text-badge-lime',
				colorIce: 'border-transparent bg-badge-ice text-badge-ice',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	}
);

export interface BadgeProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
	return (
		<div className={cn(badgeVariants({ variant }), className)} {...props} />
	);
}

export { Badge, badgeVariants };
