/*eslint-disable */
// dropdown als child meegeven
// state of hij actief is 3 states : previous, current, next (disabked)
// css aanpassen vervaging
// input = titel, uitleg
// dropdown als child

import clsx from 'clsx';

const MarkdownTransferCard = ({
	title,
	text,
	children,
	className,
	onClick,
	state = 'default',
}) => {
	const jsx = (
		<div
			className={clsx(
				state === 'background' && 'opacity-70',
				state === 'focused' && 'border-ca-purple shadow-ca-glow',
				state === 'disabled' && 'pointer-events-none',
				state === 'default' && 'text-ca-gray-500',
				state !== 'focused' && 'border-gray-200 shadow-ca-glow-off',
				'border text-black flex flex-col gap-0 rounded-xl p-5 box-border h-full w-full transition-shadow duration-500 bg-white',
				className
			)}
			onClick={onClick}
		>
			<div className="flex gap-2">
				<h1 className="font-bold text-lg"> {title} </h1>
				{state === 'defualt' && (
					<div className="w-6 h-6 bg-green-600 rounded-full" />
				)}
			</div>
			<span className="text-ca-gray-500 text-base mb-6">{text}</span>
			{children}
		</div>
	);
	return (
		<div
			key={title}
			className={clsx(
				state === 'disabled' && 'opacity-30 cursor-not-allowed',
				'transition-opacity duration-300'
			)}
		>
			{jsx}
		</div>
	);
};

export default MarkdownTransferCard;
