import clsx from 'clsx';

const MarkdownTransferRadios = ({
	options,
	value,
	onChange,
	disabled,
	className = '',
}) => {
	const optionsExtended = [];
	options.forEach((option) =>
		optionsExtended.push({ value: option, isComingSoon: false })
	);

	const createLabelInner = (
		isActive,
		optionValue,
		htmlFor,
		labelIsDisabled
	) => {
		return (
			<label
				className={clsx(
					isActive
						? 'border border-green-500 text-green-500 bg-green-100 cursor-pointer'
						: 'text-gray-500 bg-gray-200 border border-gray-200  hover:border hover:border-gray-300 cursor-pointer',
					labelIsDisabled ? 'opacity-40' : 'opacity-100',
					'select-none py-1.5 px-3 transition-all capitalize rounded-lg'
				)}
				htmlFor={htmlFor}
			>
				{optionValue}
			</label>
		);
	};

	return (
		<div className={clsx('flex gap-2 mb-4', className)}>
			{optionsExtended.map((optionObject, index) => (
				<div key={optionObject.value}>
					<input
						disabled={disabled}
						className="hidden"
						type="radio"
						value={optionObject.value}
						name="units"
						id={`unit-${index}`}
						checked={value === optionObject.value}
						onChange={(e) => onChange(e.currentTarget.value)}
					/>
					{createLabelInner(
						value === optionObject.value,
						optionObject.value,
						`unit-${index}`,
						disabled
					)}
				</div>
			))}
		</div>
	);
};

export default MarkdownTransferRadios;
