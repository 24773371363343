import Icon from './Icon';

const Kebab: Icon = ({ className }) => (
	<svg
		className={className}
		width="4px"
		height="20px"
		viewBox="0 0 4 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>More actions...</title>
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="icon-/-kebab-/-gray"
				transform="translate(-8.000000, 0.000000)"
				fill="#8B8B8F"
			>
				<path
					d="M11.0612871,8.9394963 C11.6462376,9.5255014 11.6462376,10.4755034 11.0612871,11.0604962 C10.4753357,11.6465013 9.52541497,11.6465013 8.93946356,11.0604962 C8.35351215,10.4755034 8.35351215,9.5255014 8.93946356,8.9394963 C9.52541497,8.35350123 10.4753357,8.35350123 11.0612871,8.9394963 M11.0612871,17.4394963 C11.6462376,18.0255014 11.6462376,18.9755034 11.0612871,19.5604962 C10.4753357,20.1465013 9.52541497,20.1465013 8.93946356,19.5604962 C8.35351215,18.9755034 8.35351215,18.0255014 8.93946356,17.4394963 C9.52541497,16.8535012 10.4753357,16.8535012 11.0612871,17.4394963 M11.0612871,0.439496303 C11.6462376,1.0255014 11.6462376,1.97550336 11.0612871,2.56049618 C10.4753357,3.14650127 9.52541497,3.14650127 8.93946356,2.56049618 C8.35351215,1.97550336 8.35351215,1.0255014 8.93946356,0.439496303 C9.52541497,-0.146498768 10.4753357,-0.146498768 11.0612871,0.439496303"
					id="Combined-Shape"
				/>
			</g>
		</g>
	</svg>
);

export default Kebab;
