import { useMemo, useRef, useState } from 'react';
import useChannelQuery from '../../hooks/useChannelQuery';
import useKeyPress from '../../hooks/useKeyPress';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Button from '../Button/Button';
import CornerPinger from '../CornerPinger/CornerPinger';
import PlusIcon from '../Icons/Plus';
import FilterFieldMenu from './FilterFieldMenu';
import FilterTag from './FilterTag';
import FilterValueMenu from './FilterValueMenu';
import {
	Filter,
	FilterMap,
	FilterText,
	SelectedType,
} from './FullFilterMenu.types';

// TODO: upgrade / fix eslint so these destructured types are correctly recognized as used
/* eslint-disable react/no-unused-prop-types */
export type FullFilterMenuProps = {
	disableFilter?: (f: Filter) => boolean;
	disableFilterText?: string;
	filterQuery: () => ReturnType<
		typeof useChannelQuery<Filter[], unknown, Filter[]>
	>;
	filters: FilterMap;
	setFilters: (filters: FilterMap) => void;
	applyOnChange?: boolean;
};

function findFilter(filters: Filter[], id: string): Filter {
	const filter = filters.find((f) => f.id === id);

	if (filter === undefined) {
		throw new Error('Filter not in data.');
	}

	return filter;
}

/* eslint-enable react/no-unused-prop-types */
// TODO: The type of `value` can be an array of floats, an array of strings and who knows what else. It being mistyped
//			already causes console errors. Getting around to typing it correctly, while probably not easy, would help a lot.
function mapFilterToFilterText(filter: Filter, val: any): FilterText {
	const base = {
		id: filter.id,
		name: filter.name,
		isDefault: filter.default_options !== null,
		filterMeta: filter,
	};

	if (
		filter.type === 'Checkbox' &&
		filter.checklist_options!.length === val.length
	) {
		return { ...base, text: 'all' };
	}

	if (filter.type === 'Range') {
		const [min, max] = val;

		if (filter.percentage) {
			const text = `${(parseFloat(min) * 100).toFixed(filter.decimals!)}% - ${(
				parseFloat(max) * 100
			).toFixed(filter.decimals!)}%`;

			return { ...base, text };
		}

		const text = `${parseFloat(min).toFixed(filter.decimals!)} - ${parseFloat(
			max
		).toFixed(filter.decimals!)}`;
		return { ...base, text };
	}

	return { ...base, text: val };
}

const FullFilterMenu = (props: FullFilterMenuProps) => {
	const {
		disableFilter,
		disableFilterText,
		filterQuery,
		filters,
		setFilters,
		applyOnChange,
	} = {
		disableFilter: () => false,
		applyOnChange: false,
		...props,
	};

	const ref = useRef(null);
	const [filtersVisible, setFiltersVisible] = useState(false);
	const [[selectedField, selectedType], setSelectedField] = useState<
		[selectedField: Filter | null, selectedType: SelectedType]
	>([null, null]);
	const { isLoading: isFiltersLoading, data: allFilters = [] } = filterQuery();
	// Constructing a new map is very important to ensure that the variable is not a reference to the
	// original.
	const [dirtyFilters, setDirtyFilters] = useState(new Map(filters));
	// Here the opposite is true; we _want_ the reference to the original so we can do reference comparisons.
	const [prevFilters, setPrevFilters] = useState(filters);

	// Update dirtyFilter state if filters change (likely because a query only now succeeded)
	if (prevFilters !== filters) {
		setDirtyFilters(new Map(filters));
		setPrevFilters(filters);
	}

	const handleFiltersClose = () => {
		setSelectedField([null, null]);
		setFiltersVisible(false);
	};

	const handleFiltersOpen = (e?: React.MouseEvent<HTMLButtonElement>) => {
		e!.preventDefault(); // Don't trigger submit on any parent forms
		setSelectedField([null, null]);
		setFiltersVisible(true);
	};

	useOnClickOutside(ref, handleFiltersClose);
	useKeyPress('Escape', handleFiltersClose);

	const handleFieldChange = (id: string, type: SelectedType) => {
		const filter = findFilter(allFilters, id);
		setSelectedField([filter, type]);
	};

	const handleFilterChange = (id: string, value: unknown) => {
		const filter = findFilter(allFilters, id);

		const d = new Map(dirtyFilters).set(id, {
			id,
			name: filter.name,
			value,
			isDefault: filter.default_options !== null,
			filterMeta: filter,
		});

		setDirtyFilters(d);
		if (applyOnChange) {
			setFilters(d);
		}
		handleFiltersClose();
	};

	const handleFilterRemove = (id: string) => {
		const d = new Map(dirtyFilters);
		d.delete(id);
		setDirtyFilters(d);
		if (applyOnChange) {
			setFilters(d);
		}
		handleFiltersClose();
	};

	const handleApplyFilters = () => {
		setFilters(dirtyFilters);
	};

	const handleResetFilters = () => {
		setFilters(new Map());
		setDirtyFilters(new Map());
	};

	const defaultFilters = useMemo(
		() => allFilters.filter((f) => f.default_options !== null),
		[allFilters]
	);

	const { activeFilters, availableFilters } = useMemo(() => {
		if (allFilters.length === 0) {
			return { activeFilters: [], availableFilters: [] };
		}

		// Map filters to their textual representations
		const actives = Array.from(dirtyFilters.entries()).map(([id, filterText]) =>
			mapFilterToFilterText(findFilter(allFilters, id), filterText.value)
		);

		// Add textual representations of the default filters
		defaultFilters.forEach((filterOption) => {
			if (!dirtyFilters.has(filterOption.id)) {
				actives.push({
					id: filterOption.id,
					name: filterOption.name,
					text: 'all',
					isDefault: true,
					filterMeta: filterOption,
				});
			}
		});

		// filter out currently active filters and potentially strategy specific filters
		// for the available filters in the dropdown
		const availables = allFilters.filter(
			(f) => !actives.map(({ id }) => id).includes(f.id) && !disableFilter(f)
		);

		// Sort the active filters for cleanliness and predictability
		actives.sort((a, b) => (a.name > b.name ? 1 : -1));

		return { activeFilters: actives, availableFilters: availables };
	}, [allFilters, filters, dirtyFilters, disableFilter]);

	// Are `dirtyFilters` and `filters` the same?
	const canApply = useMemo(
		() =>
			dirtyFilters.size === filters.size &&
			[...dirtyFilters.entries()].every(
				([key, value]) => filters.has(key) && filters.get(key) === value
			),
		[filters, dirtyFilters]
	);

	return (
		<div className="flex flex-col gap-2">
			<div className="flex items-center" ref={ref}>
				<div className="relative">
					<Button
						className="flex gap-2 items-center whitespace-nowrap"
						type="submit"
						size="regular"
						disabled={false}
						variant="link"
						onClick={handleFiltersOpen}
					>
						<PlusIcon className="w-3" />
						<p className="text-xs">Add filter</p>
					</Button>
					<FilterFieldMenu
						options={availableFilters}
						loading={isFiltersLoading}
						visible={filtersVisible}
						onChange={(fieldId) => handleFieldChange(fieldId, 'menu')}
					/>
					<FilterValueMenu
						key={selectedField?.id}
						id={selectedField?.id}
						field={selectedField?.name ?? ''}
						/* @ts-ignore */
						value={dirtyFilters.get(selectedField?.id)?.value}
						options={
							selectedField?.checklist_options ||
							selectedField?.list_options ||
							selectedField?.range_options
						}
						type={selectedField?.type}
						decimals={selectedField?.decimals}
						percentage={selectedField?.percentage}
						// only show dropdown if there is an active field, and the type is 'menu'
						visible={!!selectedField && selectedType === 'menu'}
						onApply={(v) => handleFilterChange(selectedField!.id, v)}
						onCancel={handleFiltersClose}
						className="left-[268px] mt-7"
					/>
				</div>
				<div className="flex flex-wrap gap-3">
					{activeFilters.map((f) => (
						<div key={f.id} className="relative inline">
							<FilterTag
								onClick={() => {
									setFiltersVisible(false);
									handleFieldChange(f.id, 'tag');
								}}
								warning={disableFilter(f.filterMeta)}
								warningText={disableFilterText}
								field={f.name}
								value={f.text}
							/>
							<FilterValueMenu
								key={selectedField?.id}
								id={selectedField?.id}
								field={selectedField?.name || ''}
								// @ts-ignore
								value={dirtyFilters.get(selectedField?.id)?.value}
								options={
									selectedField?.checklist_options ||
									selectedField?.list_options ||
									selectedField?.range_options
								}
								type={selectedField?.type}
								decimals={selectedField?.decimals}
								percentage={selectedField?.percentage}
								visible={
									// only show dropdown if currently active field is this one, and the type is 'tag'
									selectedField?.id === f.id && selectedType === 'tag'
								}
								isDefault={f.isDefault}
								onApply={(v) => handleFilterChange(f.id, v)}
								onRemove={() => handleFilterRemove(f.id)}
								className="mt-10"
							/>
						</div>
					))}
				</div>
			</div>
			{!applyOnChange && (
				<div className="flex ml-3">
					<CornerPinger hidden={canApply}>
						<Button
							className="text-xs"
							type="submit"
							size="regular"
							disabled={canApply}
							onClick={handleApplyFilters}
						>
							Apply new filters
						</Button>
					</CornerPinger>

					<Button
						className="text-xs"
						type="submit"
						size="regular"
						variant="link"
						onClick={handleResetFilters}
					>
						Clear filters
					</Button>
				</div>
			)}
		</div>
	);
};

export default FullFilterMenu;
