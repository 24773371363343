import PropTypes from 'prop-types';

const LogOut = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 16 16"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m11.329 3.102-1.454 1.348 2.428 2.55h-6.303v2h6.303l-2.428 2.586 1.454 1.362 4.671-4.948zm-3.329-3.102v5h-2v-3h-4v12h4v-3h2v5h-8v-16z"
			fill="currentColor"
		/>
	</svg>
);

LogOut.propTypes = {
	className: PropTypes.string,
};

LogOut.defaultProps = {
	className: '',
};

export default LogOut;
