import { FunctionComponent, useState } from 'react';
import { QueryKey, useQueries, UseQueryOptions } from 'react-query';
import { Route, useLocation } from 'react-router-dom';
import ReportMapper from '../../../domains/reports/ReportMapper';
import { GET_INVENTORY_ALLOCATION_REPORT } from '../../../shared/api/inventory-allocation-reports';
import Button from '../../../shared/components/Button/Button';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import {
	InventoryAllocationReport,
	InventoryAllocationReportStatus,
} from '../../../shared/models/inventoryAllocationReport';
import { ReportDTO } from '../../../shared/models/schema';
import { DraftTabLink } from '../components/DraftTab';
import DraftTabs from '../components/DraftTabs';
import DraftReport from './DraftReport';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	drafts: InventoryAllocationReport['id'][];
	rootURL: string;
	isAddingDraft?: boolean;
	addDraft: (configuration?: Configuration) => void;
	submit: (report: InventoryAllocationReport) => void;
}

function toTab(
	inventoryAllocationId: InventoryAllocation['id'],
	draft: InventoryAllocationReport['id'],
	rootURL: string
): DraftTabLink {
	return {
		inventoryAllocationId,
		id: draft,
		to: `${rootURL}/${draft}`,
	};
}

const toQuery = (
	inventoryAllocationId: InventoryAllocation['id'],
	id: InventoryAllocationReport['id'],
	queryKey: QueryKey
): UseQueryOptions => ({
	queryKey,
	queryFn: () => GET_INVENTORY_ALLOCATION_REPORT(inventoryAllocationId, id),
});

const isProposal = (report: InventoryAllocationReport): boolean =>
	report.status === InventoryAllocationReportStatus.Proposal;

const InventoryAllocationDrafts: FunctionComponent<Props> = ({
	inventoryAllocationId,
	drafts,
	rootURL,
	isAddingDraft,
	addDraft,
	submit,
}) => {
	const mapper = new ReportMapper();
	const [selectedDraft, setSelectedDraft] = useState<
		InventoryAllocationReport | undefined
	>();
	const location = useLocation();
	const currentTab: string =
		location.pathname.match(/movements|results|configuration/)?.[0] || '';
	const tabs: DraftTabLink[] = drafts.map((draft) =>
		toTab(inventoryAllocationId, draft, rootURL)
	);
	const reportsResult = useQueries(
		drafts.map((report) =>
			toQuery(inventoryAllocationId, report as string, ['reports', report])
		)
	);

	const reports: InventoryAllocationReport[] = (
		reportsResult
			.map(({ data }) => data)
			.filter((report) => !!report) as ReportDTO[]
	).map(mapper.toReport);

	const toOption = (
		draft: InventoryAllocationReport
	): { value: string; label: string } => ({
		value: draft.id,
		label: draft.title,
	});

	const onSubmit = () => {
		if (selectedDraft) {
			submit(selectedDraft);
		}
	};

	const onChange = (id: InventoryAllocationReport['id']) => {
		const draft = (reports || []).find((report) => report.id === id);
		setSelectedDraft(draft);
	};

	return (
		<>
			<div className="flex justify-between">
				<DraftTabs
					tabs={tabs}
					addDraft={addDraft}
					currentTab={currentTab}
					isAddingDraft={isAddingDraft}
				/>
				<div>
					<Dropdown
						options={reports.filter(isProposal).map(toOption)}
						value={selectedDraft?.id}
						onChange={onChange}
						placeholder={
							reports.filter(isProposal).length === 0
								? 'Finish a draft to submit'
								: 'Select a draft to submit'
						}
						className="max-w-32 whitespace-nowrap overflow-ellipsis"
					/>
					<Button onClick={onSubmit} className="ml-4">
						Submit
					</Button>
				</div>
			</div>
			<section className="bg-ghost-white bg-opacity-50 w-[calc(100%+5rem)] h-full min-h-screen -mx-10 px-10 pt-4">
				{tabs.map((tab) => {
					const draft = drafts.find((draftId) => draftId === tab.id);

					if (!draft) {
						return null;
					}

					return (
						<Route
							key={tab.id}
							path={tab.to}
							render={() => (
								<DraftReport
									inventoryAllocationId={inventoryAllocationId}
									id={draft}
									rootURL={tab.to}
									addDraft={addDraft}
									isAddingDraft={isAddingDraft}
								/>
							)}
						/>
					);
				})}
			</section>
		</>
	);
};

export default InventoryAllocationDrafts;
