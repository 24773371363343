/* eslint-disable class-methods-use-this */
import { UseQueryResult } from 'react-query';
import { MutationDTO } from '../../shared/models/API';
import { DTOMap } from '../../shared/models/DTOMap';
import {
	InventoryAllocation,
	InventoryAllocationStatus,
} from '../../shared/models/inventoryAllocation';
import { IOMap } from '../../shared/models/IOMap';
import {
	CreateInventoryAllocationBodyDTO,
	CreateInventoryAllocationParametersDTO,
	CreateInventoryAllocationResponseDTO,
	DeleteInventoryAllocationBodyDTO,
	DeleteInventoryAllocationParametersDTO,
	DeleteInventoryAllocationResponseDTO,
	GetInventoryAllocationResponseDTO,
	GetInventoryAllocationsResponseDTO,
	InventoryAllocationDTO,
	InventoryAllocationStatusDTO,
	SubmitInventoryAllocationBodyDTO,
	SubmitInventoryAllocationParametersDTO,
	SubmitInventoryAllocationResponseDTO,
	UpdateInventoryAllocationBodyDTO,
	UpdateInventoryAllocationParametersDTO,
	UpdateInventoryAllocationResponseDTO,
} from '../../shared/models/schema';
import {
	CreateInventoryAllocationMutation,
	DeleteInventoryAllocationMutation,
	InventoryAllocationAPI,
	RenameInventoryAllocationMutation,
	SubmitInventoryAllocationMutation,
} from './useInventoryAllocationsUseCases';

export type InventoryAllocationAPIDTO = UseQueryResult<
	GetInventoryAllocationsResponseDTO | GetInventoryAllocationResponseDTO
>;

export type CreateInventoryAllocationMutationDTO = MutationDTO<
	CreateInventoryAllocationParametersDTO,
	CreateInventoryAllocationBodyDTO,
	CreateInventoryAllocationResponseDTO
>;

export type UpdateInventoryAllocationMutationDTO = MutationDTO<
	UpdateInventoryAllocationParametersDTO,
	UpdateInventoryAllocationBodyDTO,
	UpdateInventoryAllocationResponseDTO
>;

export type DeleteInventoryAllocationMutationDTO = MutationDTO<
	DeleteInventoryAllocationParametersDTO,
	DeleteInventoryAllocationBodyDTO,
	DeleteInventoryAllocationResponseDTO
>;

export type SubmitInventoryAllocationMutationDTO = MutationDTO<
	SubmitInventoryAllocationParametersDTO,
	SubmitInventoryAllocationBodyDTO,
	SubmitInventoryAllocationResponseDTO
>;

interface Mapper {
	toAPI: (API?: InventoryAllocationAPIDTO) => InventoryAllocationAPI;
	toInventoryAllocation: (DTO: InventoryAllocationDTO) => InventoryAllocation;
	status: DTOMap<InventoryAllocationStatusDTO, InventoryAllocationStatus>;
	toCreateMutation: (
		mutation: CreateInventoryAllocationMutationDTO
	) => CreateInventoryAllocationMutation;
	toRenameMutation: (
		mutation: UpdateInventoryAllocationMutationDTO
	) => RenameInventoryAllocationMutation;
	toDeleteMutation: (
		mutation: DeleteInventoryAllocationMutationDTO
	) => DeleteInventoryAllocationMutation;
	toSubmitMutation: (
		mutation: SubmitInventoryAllocationMutationDTO
	) => SubmitInventoryAllocationMutation;
}

class InventoryAllocationMapper implements Mapper {
	isSingleEntity = (
		data: GetInventoryAllocationResponseDTO | GetInventoryAllocationsResponseDTO
	): data is GetInventoryAllocationResponseDTO => 'id' in (data || {});

	toAPI: Mapper['toAPI'] = (API) => {
		const getDTOs = (
			data: InventoryAllocationAPIDTO['data']
		): InventoryAllocationDTO[] => {
			if (data && this.isSingleEntity(data)) return [data];
			if (data && !this.isSingleEntity(data)) return data.items;
			return [];
		};

		const entities = getDTOs(API?.data).map(this.toInventoryAllocation);

		return {
			entities,
			entity: entities[0],
			count: entities.length,
			isLoading:
				API?.isLoading || API?.isFetching || API?.isRefetching || false,
			refetch: API?.refetch || (() => {}),
		};
	};

	toInventoryAllocation: Mapper['toInventoryAllocation'] = (DTO) => ({
		id: DTO.id,
		title: DTO.name,
		updatedOn: new Date(DTO.date_updated),
		report: DTO.submitted_report || undefined,
		drafts: (DTO.report_ids || []).filter((id) => id !== DTO.submitted_report),
		status: this.status.toInternal(DTO.status),
	});

	status: Mapper['status'] = {
		toInternal: (DTO) => {
			switch (DTO) {
				case InventoryAllocationStatusDTO.Draft:
					return InventoryAllocationStatus.Draft;
				case InventoryAllocationStatusDTO.Error:
					return InventoryAllocationStatus.Error;
				case InventoryAllocationStatusDTO.Executed:
					return InventoryAllocationStatus.Executed;
				case InventoryAllocationStatusDTO.InProgress:
					return InventoryAllocationStatus.InProgress;
				case InventoryAllocationStatusDTO.NoSuggestedMoves:
					return InventoryAllocationStatus.NoSuggestedMoves;
				case InventoryAllocationStatusDTO.Proposal:
					return InventoryAllocationStatus.Proposal;
				case InventoryAllocationStatusDTO.Submitted:
					return InventoryAllocationStatus.Submitted;
				default:
					return InventoryAllocationStatus.Draft;
			}
		},
		toDTO: (internal) => {
			switch (internal) {
				case InventoryAllocationStatus.Draft:
					return InventoryAllocationStatusDTO.Draft;
				case InventoryAllocationStatus.Error:
					return InventoryAllocationStatusDTO.Error;
				case InventoryAllocationStatus.Executed:
					return InventoryAllocationStatusDTO.Executed;
				case InventoryAllocationStatus.InProgress:
					return InventoryAllocationStatusDTO.InProgress;
				case InventoryAllocationStatus.NoSuggestedMoves:
					return InventoryAllocationStatusDTO.NoSuggestedMoves;
				case InventoryAllocationStatus.Proposal:
					return InventoryAllocationStatusDTO.Proposal;
				case InventoryAllocationStatus.Submitted:
					return InventoryAllocationStatusDTO.Submitted;
				default:
					return InventoryAllocationStatusDTO.Draft;
			}
		},
	};

	toCreateMutation: Mapper['toCreateMutation'] = (mutation) => {
		const map: IOMap<
			CreateInventoryAllocationMutationDTO,
			CreateInventoryAllocationMutation
		> = {
			toInput: (input) => ({
				name: input.title,
			}),
			toOutput: (output) => this.toInventoryAllocation(output),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	toRenameMutation: Mapper['toRenameMutation'] = (mutation) => {
		const map: IOMap<
			UpdateInventoryAllocationMutationDTO,
			RenameInventoryAllocationMutation
		> = {
			toInput: (input) => ({
				allocation_id: input.id,
				status: this.status.toDTO(input.status),
				name: input.title,
			}),
			toOutput: (output) => this.toInventoryAllocation(output),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	toDeleteMutation: Mapper['toDeleteMutation'] = (mutation) => {
		const map: IOMap<
			DeleteInventoryAllocationMutationDTO,
			DeleteInventoryAllocationMutation
		> = {
			toInput: (input) => ({
				allocation_id: input.id,
			}),
			toOutput: () => {},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	toSubmitMutation: Mapper['toSubmitMutation'] = (mutation) => {
		const map: IOMap<
			SubmitInventoryAllocationMutationDTO,
			SubmitInventoryAllocationMutation
		> = {
			toInput: (input) => ({
				allocation_id: input.id,
				report_id: input.reportId,
			}),
			toOutput: this.toInventoryAllocation,
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}

export default InventoryAllocationMapper;
