import { FunctionComponent } from 'react';
import Title from '../../../shared/components/Title/Title';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import ConstraintOption, { NoConstraints } from './ConstraintOption';

interface Props extends Pick<Configuration, 'constraints'> {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	isInProgress?: boolean;
	isProposal?: boolean;
}

const ConstraintsWizardSection: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	constraints,
	isInProgress,
	isProposal,
}) => {
	return (
		<div className="my-16">
			<Title type="section">Constraints</Title>
			<p className="text-ca-gray max-w-lg pt-2 pb-6">
				Constraints are requirements which should be met. Breaking these
				constraints would render the solution infeasible.
			</p>
			<div className="flex flex-col gap-2">
				{constraints.length === 0 && <NoConstraints />}
				{constraints.map((constraint) => (
					<ConstraintOption
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						key={constraint.type}
						constraint={constraint}
						isInProgress={isInProgress}
						isProposal={isProposal}
					/>
				))}
			</div>
		</div>
	);
};

export default ConstraintsWizardSection;
