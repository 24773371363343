import { BaseEntity } from './API';
import { Configuration } from './configuration';

export enum InventoryAllocationReportStatus {
	Draft = 'Draft',
	InProgress = 'In Progress',
	Proposal = 'Proposal',
	Submitted = 'Submitted',
	NoSuggestedMoves = 'No Suggested Moves',
	Error = 'Error',
	Executed = 'Executed',
	Outdated = 'Outdated',
}

export interface InventoryAllocationReport extends BaseEntity {
	title: string;
	movementCount: number;
	senderCount: number;
	receiverCount: number;
	configuration: Configuration;
	status: InventoryAllocationReportStatus;
	inventoryAllocationId?: string;
	message: string;
}
