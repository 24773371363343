import { FunctionComponent } from 'react';
import useModal from '../../../shared/hooks/useModal';
import { Scope } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import LocationsTableModal from '../modals/LocationsTableModal';
import ProductsTableModal from '../modals/ProductsTableModal';

interface Props extends Scope {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
}

export function getProductsScopeDescription(
	products: Scope['products']
): string {
	switch (products.length) {
		case 1:
			return '1 product';
		default:
			return `${products.length} products`;
	}
}

export function getLocationsScopeDescription(
	locations: Scope['locations']
): string {
	switch (locations.length) {
		case 1:
			return '1 location';
		default:
			return `${locations.length} locations`;
	}
}

const ScopeDescription: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	products,
	locations,
}) => {
	const { open } = useModal();
	const isScopeDefined = products.length !== 0 || locations.length !== 0;

	function openProductsModal(): void {
		open(
			<ProductsTableModal
				inventoryAllocationId={inventoryAllocationId}
				reportId={reportId}
				selectedProducts={products}
			/>
		);
	}

	function openLocationsModal(): void {
		open(
			<LocationsTableModal
				inventoryAllocationId={inventoryAllocationId}
				reportId={reportId}
				selectedLocations={locations}
			/>
		);
	}

	if (!isScopeDefined) {
		return null;
	}

	return (
		<div className="border-t border-gray-200 mt-3">
			<p className="text-ca-gray mt-2">
				For{' '}
				<button type="button" onClick={openProductsModal}>
					<strong className="text-ca-purple">
						{getProductsScopeDescription(products)}
					</strong>
				</button>{' '}
				over{' '}
				<button type="button" onClick={openLocationsModal}>
					<strong className="text-ca-purple">
						{getLocationsScopeDescription(locations)}
					</strong>
				</button>
				.
			</p>
		</div>
	);
};

export default ScopeDescription;
