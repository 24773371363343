import PropTypes from 'prop-types';

const Save = ({ className }) => {
	return (
		<svg
			className={className}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.6092 4L12 0.39084C11.8766 0.26649 11.7298 0.167901 11.568 0.100806C11.4062 0.0337107 11.2327 -0.000552866 11.0575 6.74579e-06H1.33333C0.979711 6.74579e-06 0.640572 0.140482 0.390524 0.390531C0.140476 0.640579 0 0.979718 0 1.33334V14.6667C0 15.0203 0.140476 15.3594 0.390524 15.6095C0.640572 15.8595 0.979711 16 1.33333 16H14.6667C15.0203 16 15.3594 15.8595 15.6095 15.6095C15.8595 15.3594 16 15.0203 16 14.6667V4.9425C16.0006 4.76734 15.9663 4.59382 15.8992 4.43202C15.8321 4.27022 15.7335 4.12337 15.6092 4ZM14.6667 14.6667H12.6667V10C12.6667 9.64638 12.5262 9.30724 12.2761 9.05719C12.0261 8.80715 11.687 8.66667 11.3333 8.66667H4.66666C4.31304 8.66667 3.9739 8.80715 3.72386 9.05719C3.47381 9.30724 3.33333 9.64638 3.33333 10V14.6667H1.33333V1.33334H11.0575L14.6667 4.9425V14.6667ZM10.6667 3.33334C10.6667 3.51015 10.5964 3.67972 10.4714 3.80474C10.3464 3.92977 10.1768 4 10 4H5.33333C5.15652 4 4.98695 3.92977 4.86193 3.80474C4.7369 3.67972 4.66666 3.51015 4.66666 3.33334C4.66666 3.15653 4.7369 2.98696 4.86193 2.86193C4.98695 2.73691 5.15652 2.66667 5.33333 2.66667H10C10.1768 2.66667 10.3464 2.73691 10.4714 2.86193C10.5964 2.98696 10.6667 3.15653 10.6667 3.33334Z"
				fill="currentColor"
			/>
		</svg>
	);
};

Save.propTypes = {
	className: PropTypes.string,
};

Save.defaultProps = {
	className: '',
};

export default Save;
