import { FunctionComponent } from 'react';
import {
	Constraint,
	ConstraintType,
	CooldownPeriodConstraint,
	DoNotBreakSizeChartConstraint,
	MaximumShipmentAmountConstraint,
	MaximumSKUAmountConstraint,
	MaximumVolumeMovedConstraint,
	MinimumProductAmountConstraint,
	MinimumROIConstraint,
	MinimumShipmentAmountConstraint,
	OnlyRestoreSizeChartsConstraint,
} from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import ScopeDescription from './ScopeDescription';

type WithIds<Type> = Type & {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
};

interface Props {
	constraint: Constraint;
	isInProgress?: boolean;
	isProposal?: boolean;
}

export const NoConstraints: FunctionComponent = () => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			No constraints. Calculate the most optimal inventory allocations possible.
		</p>
	</div>
);

const MaximumVolumeMovedConstraintOption: FunctionComponent<
	WithIds<MaximumVolumeMovedConstraint>
> = ({ maxMovementCount, scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			Move no more than a total of{' '}
			<strong className="text-ca-purple">{maxMovementCount}</strong> SKUs.
		</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MinimumShipmentAmountConstraintOption: FunctionComponent<
	WithIds<MinimumShipmentAmountConstraint>
> = ({ minimumShipmentAmount, scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			Don&apos;t create shipments with less than{' '}
			<strong className="text-ca-purple">{minimumShipmentAmount}</strong> SKUs.
		</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MaximumShipmentAmountConstraintOption: FunctionComponent<
	WithIds<MaximumShipmentAmountConstraint>
> = ({ maximumShipmentAmount, scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			Don&apos;t create shipments with less than{' '}
			<strong className="text-ca-purple">{maximumShipmentAmount}</strong> SKUs.
		</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const DoNotBreakSizeChartConstraintOption: FunctionComponent<
	WithIds<DoNotBreakSizeChartConstraint>
> = ({ scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">Don&apos;t break size charts.</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const CooldownPeriodConstraintOption: FunctionComponent<
	WithIds<CooldownPeriodConstraint>
> = ({ minCooldownDays, scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			Don&apos;t move products within{' '}
			<strong className="text-ca-purple">{minCooldownDays}</strong> days after
			they have been delivered to a location.
		</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MinimumROIConstraintOption: FunctionComponent<
	WithIds<MinimumROIConstraint>
> = ({ minimumROI, scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			Don&apos;t move products unless we benefit at least{' '}
			<strong className="text-ca-purple">{minimumROI}</strong> per SKU.
		</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MaximumSKUAmountConstraintOption: FunctionComponent<
	WithIds<MaximumSKUAmountConstraint>
> = ({ scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			Enforce maximum allowed amount of units per SKU per location.
		</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MinimumProductAmountConstraintOption: FunctionComponent<
	WithIds<MinimumProductAmountConstraint>
> = ({ scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			Require minimum amount of units for per product per location.
		</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const OnlyRestoreSizeChartsConstraintOption: FunctionComponent<
	WithIds<OnlyRestoreSizeChartsConstraint>
> = ({ scope, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			Only allow moves which can repair broken size charts.
		</p>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const ConstraintOption: FunctionComponent<WithIds<Props>> = ({
	constraint,
	...ids
}) => {
	const renderConstraintOption = () => {
		switch (constraint.type) {
			case ConstraintType.MaximumVolumeMoved:
				return <MaximumVolumeMovedConstraintOption {...constraint} {...ids} />;
			case ConstraintType.MinimumShipmentAmount:
				return (
					<MinimumShipmentAmountConstraintOption {...constraint} {...ids} />
				);
			case ConstraintType.MaximumShipmentAmount:
				return (
					<MaximumShipmentAmountConstraintOption {...constraint} {...ids} />
				);
			case ConstraintType.DoNotBreakSizeChart:
				return <DoNotBreakSizeChartConstraintOption {...constraint} {...ids} />;
			case ConstraintType.CooldownPeriod:
				return <CooldownPeriodConstraintOption {...constraint} {...ids} />;
			case ConstraintType.MinimumROI:
				return <MinimumROIConstraintOption {...constraint} {...ids} />;
			case ConstraintType.MaximumSKUAmount:
				return <MaximumSKUAmountConstraintOption {...constraint} {...ids} />;
			case ConstraintType.OnlyRestoreSizeCharts:
				return (
					<OnlyRestoreSizeChartsConstraintOption {...constraint} {...ids} />
				);
			case ConstraintType.MinimumProductAmount:
				return (
					<MinimumProductAmountConstraintOption {...constraint} {...ids} />
				);
			default:
				return null;
		}
	};

	return (
		<div className="flex gap-3 items-center">{renderConstraintOption()}</div>
	);
};

export default ConstraintOption;
