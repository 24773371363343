/* eslint-disable */

import { SaveIcon } from 'lucide-react';
import {
	ComponentProps,
	createContext,
	PropsWithChildren,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { components } from '../../../../../types/backend-api';
import Button from '../../../../shared/components/Button/Button';
import StepsSidebar, {
	StepButton,
	StepButtonProps,
	StepsSidebarProps,
} from '../../../../shared/components/CreateEditModal/StepsSidebar';
import Cross from '../../../../shared/components/Icons/Cross';
import LoadingSpinner from '../../../../shared/components/LoadingSpinner/LoadingSpinner';
import Modal from '../../../../shared/components/Modal/Modal';
import Text from '../../../../shared/components/Text/Text';
import useModal from '../../../../shared/hooks/useModal';
import { cn } from '../../../../shared/utils/styles';
import BusinessRulesCreateEdit from '../CreateEdit';

/**
 * __________________________________________________________________________________________________
 *
 * 1 <BASE MODAL>																	1 <BASE MODEL>
 * __________________________________________________________________________________________________
 */

/**
 * what I want from this context
 * let children subscribe to the onclick of the 'nextButton'
 * share the close function for that specific modal (which you can extend)
 *
 *
 */

type SteppedModalContext = {};

const defaultContextValue: SteppedModalContext = {};

const SteppedModalContext =
	createContext<SteppedModalContext>(defaultContextValue);

export const useSteppedModalContext = () => useContext(SteppedModalContext);

const SteppedModalContextProvider = (props: PropsWithChildren) => {
	return (
		<SteppedModalContext.Provider value={{}}>
			{props.children}
		</SteppedModalContext.Provider>
	);
};

/**
 * ------------------------------------------------------------------
 * 1.1 | TYPES | <BASE MODAL> 			for <BusinessRuleCreateEditModalBase/>
 * ------------------------------------------------------------------
 */
type ModalStep = {
	id: string;
	sidebarTitle: string;
	renderFn: () => React.ReactElement; // Don't this this will work because can I render a function & string with the same line of code?
	onPrevious?: () => void;
	onNext?: () => void;
};

type ModalWithStepsProps = {
	steps: ModalStep[];

	sidebarSettings: Pick<
		StepsSidebarProps,
		'badgeTitle' | 'badgeVariant' | 'title'
	> & { strictNavigation: boolean };
};

/**
 * ------------------------------------------------------------------
 * 1.2 | COMPONENT | <BASE MODAL> 		Internal component. Not for exporting.
 * ------------------------------------------------------------------
 */
const ModalWithSteps = (props: ModalWithStepsProps) => {
	const { close } = useModal();

	const [step, setStep] = useState<
		ModalWithStepsProps['steps'][number]['id'] | undefined
	>(props.steps[0]['id'] ?? undefined);

	const currentStep = props.steps.find((stepHay) => stepHay.id === step);

	// Close modal if invalid setup
	if (currentStep === undefined) {
		close();
		console.error(
			'Error in <BusinessRuleCreateEditModalBase/>. Steps property is empty.'
		);
		return <div>error</div>;
	}

	const currentStepIndex = props.steps.findIndex(
		(stepHay) => stepHay.id === currentStep.id
	);

	const stepIndexToStepState = (
		stepIndex: number,
		compareToIndex: number
	): StepButtonProps['state'] => {
		if (props.sidebarSettings.strictNavigation) {
			if (stepIndex === compareToIndex) return 'current';
			if (stepIndex > compareToIndex) return 'disabled';
			return 'checkmark';
		}
		if (stepIndex === compareToIndex) return 'current';
		return 'clickable';
	};

	return (
		<Modal.Root className="w-[1200px] max-w-none grid grid-cols-[min-content_1fr] gap-4 p-2 relative">
			<StepsSidebar
				badgeTitle={props.sidebarSettings.badgeTitle}
				badgeVariant={props.sidebarSettings.badgeVariant}
				title={props.sidebarSettings.title}
				steps={props.steps.map((step, stepIndex) => {
					return (
						<StepButton
							key={step.id}
							state={stepIndexToStepState(stepIndex, currentStepIndex)}
							onClick={() => {}}
							title={step.sidebarTitle}
						/>
					);
				})}
			/>
			<Modal.Content className="flex-grow p-0" unstyled>
				{currentStep.renderFn()}
			</Modal.Content>

			<Button
				className="absolute right-0 m-2"
				variant="ghostFill"
				squarePadding
				onClick={() => {
					close();
				}}
			>
				<Cross className="w-4 h-4 text-ca-gray-400" />
			</Button>
		</Modal.Root>
	);
};

ModalWithSteps.Actions = (props: React.PropsWithChildren) => {
	return <div className="flex justify-between">{props.children}</div>;
};

ModalWithSteps.PreviousButton = (
	props: React.PropsWithChildren & { className?: string }
) => {
	<Button variant="link" className={cn(props.className)}>
		{props.children}
	</Button>;
};

ModalWithSteps.Step = {
	Root: (props: PropsWithChildren) => {
		return (
			<section className="flex flex-col justify-around h-full">
				{props.children}
			</section>
		);
	},
	Header: (props: {
		children: NonNullable<React.ReactNode>;
		className?: string;
	}) => {
		return <header className="mb-4">{props.children}</header>;
	},
	Title: (props: {
		children: NonNullable<React.ReactNode>;
		className?: string;
	}) => {
		// text-xl block font-bold text-ca-black
		return (
			<Text
				className={(cn(props.className), 'text-2xl font-bold mt-2')}
				size={'text-2xl'}
			>
				{props.children}
			</Text>
		);
	},
	Description: (props: {
		children: NonNullable<React.ReactNode>;
		className?: string;
	}) => {
		return (
			<Text type="secondary" className={cn(props.className)}>
				{props?.children}
			</Text>
		);
	},
	Content: (props: ComponentProps<'div'>) => {
		return <div {...props} />;
	},
	NextButton: (props: ComponentProps<typeof Button>) => {
		const { className, ...allButClassName } = props;
		return (
			<Button
				variant="primary"
				className={(cn(props.className), 'flex gap-2 items-center')}
				{...allButClassName}
			/>
		);
	},
	PreviousButton: (props: ComponentProps<typeof Button>) => {
		const { className, ...rest } = props;
		return (
			<Button
				variant="link"
				className={(cn(props.className), 'flex gap-2 items-center')}
				{...rest}
			/>
		);
	},
};

/**
 * __________________________________________________________________________________________________
 *
 * 2 <EDIT MODAL>																	2 <EDIT MODAL>
 * __________________________________________________________________________________________________
 */

/**
 * ------------------------------------------------------------------
 * 2.1 | TYPES | <EDIT MODAL>
 * ------------------------------------------------------------------
 */
type EditModalProps = {
	/**
	 * Business rule id
	 */
	businessRuleId: components['schemas']['BusinessRuleAPIOutput']['id'];
};

/**
 * ------------------------------------------------------------------
 * 2.2 | COMPONENT | <EDIT MODAL>
 * ------------------------------------------------------------------
 */
const EditModalBusinessRule = () => {
	const formRef = useRef<HTMLFormElement>(null);
	const [isLoading, setIsLoading] = useState(false);
	const { close } = useModal();

	return (
		<SteppedModalContextProvider>
			<ModalWithSteps
				sidebarSettings={{
					badgeTitle: 'Edit',
					badgeVariant: 'colorGray',
					title: 'Business rule',
					strictNavigation: false,
				}}
				steps={[
					{
						id: 'edit',
						renderFn: () => {
							return (
								<ModalWithSteps.Step.Root>
									<ModalWithSteps.Step.Header>
										<ModalWithSteps.Step.Title>
											Edit business rule
										</ModalWithSteps.Step.Title>
										<ModalWithSteps.Step.Description>
											Change the name, product scope and business rule to be
											applied.
										</ModalWithSteps.Step.Description>
									</ModalWithSteps.Step.Header>
									<ModalWithSteps.Step.Content>
										<BusinessRulesCreateEdit
											formRef={formRef}
											setIsLoading={setIsLoading}
										/>
									</ModalWithSteps.Step.Content>
									<ModalWithSteps.Actions>
										<ModalWithSteps.Step.PreviousButton
											onClick={() => {
												close();
											}}
										>
											Cancel
										</ModalWithSteps.Step.PreviousButton>
										<ModalWithSteps.Step.NextButton
											disabled={isLoading}
											onClick={() => {
												formRef.current?.requestSubmit();
											}}
										>
											{isLoading ? (
												<LoadingSpinner />
											) : (
												<SaveIcon className="h-5 w-5" />
											)}
											Save
										</ModalWithSteps.Step.NextButton>
									</ModalWithSteps.Actions>
								</ModalWithSteps.Step.Root>
							);
						},
						sidebarTitle: 'Name, scope & business rule',
					},
				]}
			/>
		</SteppedModalContextProvider>
	);
};

// WAS HERE -- LINK PREVIOUS AND NEXT BUTTON TO BE ABLE TO TRIGGER ONSUBMIT INSIDE BUSINESSRULECREATEEDIT -> CONTEXT? REF?
// 1. context. create context in root. provide it to all children. in next & previous - set the ref to butotn (useCompoundRef? see radix dialoge source code) in side CreateEdit -> set onNextButtonClick to onSubmit but still also trigger closeEditModal if success.
// 2. Our Business Rules create edit component is too smart. The business logic is done iside of it.
// We should define the whole form inside of that component but still get the onsubmit event outside of the component I think
// => Don't want to do this right now, want to touch the code as little as possible
// 3.

/**
 * ------------------------------------------------------------------
 * 2.3 | HOOK | <EDIT MODAL>
 * ------------------------------------------------------------------
 */
export const useEditModalBusinessRule = () => {
	const history = useHistory();
	const location = useLocation();
	const { open, close } = useModal();

	const onClose = () => {
		const urlParams = new URLSearchParams(location.search);
		urlParams.delete('modal');
		urlParams.delete('business-rule-id');
		history.push(`${location.pathname}?${urlParams.toString()}`);
	};

	useEffect(() => {
		const shouldBeOpen =
			new URLSearchParams(location.search).get('modal') === 'edit';
		if (shouldBeOpen) {
			open(<EditModalBusinessRule />, '', '', 'edit', onClose);
		}
	}, []);

	return {
		open: (
			businessRuleId: components['schemas']['BusinessRuleAPIOutput']['id']
		) => {
			const urlParams = new URLSearchParams(location.search);
			urlParams.set('modal', 'edit');
			urlParams.set('business-rule-id', businessRuleId);
			history.push(`${location.pathname}?${urlParams.toString()}`);
			open(<EditModalBusinessRule />, '', '', 'edit', onClose);
		},
		close,
	};
};

/**
 * __________________________________________________________________________________________________
 *
 * 3 <CREATE MODAL>																	3 <CREATE MODAL>
 * __________________________________________________________________________________________________
 */

/**
 * ------------------------------------------------------------------
 * 3.1 | TYPES | <CREATE MODAL>
 * ------------------------------------------------------------------
 */
type CreateModalProps = {
	/**
	 * Business rule id
	 */
};

/**
 * ------------------------------------------------------------------
 * 3.2 | COMPONENT | <CREATE MODAL>
 * ------------------------------------------------------------------
 */
const CreateModalBusinessRule = (props: CreateModalProps) => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const formRef = useRef<HTMLFormElement>();

	const { close } = useModal();

	return (
		<SteppedModalContextProvider>
			<ModalWithSteps
				sidebarSettings={{
					badgeTitle: 'Create',
					badgeVariant: 'success',
					title: 'Business Rule',
					strictNavigation: true,
				}}
				steps={[
					{
						id: 'businessRule',
						sidebarTitle: 'Name, scope & business rule',
						renderFn: () => (
							<ModalWithSteps.Step.Root>
								<ModalWithSteps.Step.Header>
									<ModalWithSteps.Step.Title>
										Create new business rule
									</ModalWithSteps.Step.Title>
									<ModalWithSteps.Step.Description>
										Define the name, product scope and rule to be applied.
									</ModalWithSteps.Step.Description>
								</ModalWithSteps.Step.Header>
								<ModalWithSteps.Step.Content>
									<BusinessRulesCreateEdit
										formRef={formRef}
										setIsLoading={setIsLoading}
									/>
								</ModalWithSteps.Step.Content>
								<ModalWithSteps.Actions>
									<ModalWithSteps.Step.PreviousButton
										onClick={() => {
											close();
										}}
									>
										Cancel
									</ModalWithSteps.Step.PreviousButton>
									<ModalWithSteps.Step.NextButton
										disabled={isLoading}
										onClick={() => {
											formRef.current?.requestSubmit();
										}}
									>
										{isLoading ? (
											<LoadingSpinner />
										) : (
											<SaveIcon className="h-5 w-5" />
										)}
										Save
									</ModalWithSteps.Step.NextButton>
								</ModalWithSteps.Actions>
							</ModalWithSteps.Step.Root>
						),
					},
				]}
			/>
		</SteppedModalContextProvider>
	);
};

/**
 * ------------------------------------------------------------------
 * 3.3 | HOOK | <CREATE MODAL>
 * ------------------------------------------------------------------
 */
export const useCreateModalBusinessRule = () => {
	const history = useHistory();
	const location = useLocation();
	const { open, close } = useModal();

	const onClose = () => {
		const urlParams = new URLSearchParams(location.search);
		urlParams.delete('modal');
		history.push(`${location.pathname}?${urlParams.toString()}`);
	};

	useEffect(() => {
		const shouldBeOpen =
			new URLSearchParams(location.search).get('modal') === 'create';

		if (shouldBeOpen) {
			open(<CreateModalBusinessRule />, '', '', 'create', onClose);
		}
	}, []);

	return {
		open: () => {
			const urlParams = new URLSearchParams(location.search);
			urlParams.set('modal', 'create');
			history.push(`${location.pathname}?${urlParams.toString()}`);
			open(<CreateModalBusinessRule />, '', '', 'create', onClose);
		},
		close,
	};
};
