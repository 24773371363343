import clsx from 'clsx';
import {
	cloneElement,
	FunctionComponent,
	isValidElement,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import ClassWatcher from '../../utils/classWatcher';
import { IconProps } from '../Icons/Icon';
import Tooltip from '../Tooltip/Tooltip';

export interface SidebarLinkProps {
	label: string;
	to: string;
	icon: ReactNode;
	disabled?: boolean;
	tooltip?: string;
}

function iconWithClassname(icon: ReactNode, className: string): ReactNode {
	if (isValidElement(icon)) {
		return cloneElement<IconProps>(icon as any, { className });
	}

	return icon;
}

const SidebarLink: FunctionComponent<SidebarLinkProps> = ({
	label,
	to,
	icon,
	disabled,
	tooltip,
}) => {
	const ref = useRef<HTMLAnchorElement>(null);
	const [isActive, setIsActive] = useState<boolean>(false);

	useEffect(() => {
		if (ref.current) {
			if (ref.current.classList.contains('isActive') && !isActive) {
				setIsActive(true);
			}
			// eslint-disable-next-line no-new
			new ClassWatcher(
				ref.current,
				'isActive',
				() => setIsActive(true),
				() => setIsActive(false)
			);
		}
	}, [ref]);

	const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (disabled === true) {
			e.preventDefault();
		}
	};

	return (
		<Tooltip content={tooltip} placement="right">
			<NavLink to={to} activeClassName="isActive" ref={ref} onClick={onClick}>
				<li
					className={clsx(
						'relative flex flex-col flex-wrap items-center py-5 text-ca-gray  hover:border-ca-purple transition-all before:transition-all before:absolute before:left-0 before:top-0 before:h-full before:bg-ca-purple',
						isActive ? 'before:w-1' : 'before:w-0',
						disabled === true
							? 'opacity-50 cursor-not-allowed'
							: 'hover:text-ca-purple cursor-pointer'
					)}
				>
					{iconWithClassname(
						icon,
						clsx('w-5 transition-colors', isActive && 'text-ca-purple')
					)}
					<span
						className={clsx(
							'mt-4 max-w-4/5 text-center text-xs uppercase font-semibold tracking-tighter transition-all group-hover:opacity-100 group-hover:h-4',
							isActive ? 'text-ca-purple h-4' : 'opacity-0 h-0'
						)}
					>
						{label}
					</span>
				</li>
			</NavLink>
		</Tooltip>
	);
};

export default SidebarLink;
