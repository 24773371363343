import { Redirect, Route } from 'react-router-dom';
import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import StrategyHeader from '../components/StrategyHeader';

import Page from '../../shared/components/Page/Page';
import { ObjectivesPage } from '../v2/ObjectivesPage';
import { StrategiesPage } from '../v2/StrategiesPage';
import BusinessRulesOverview from './BusinessRules/OverviewV2';

// TODO WARD: rework redirects from legacy paths during react 18 upgrade ticket
const StrategyView = () => {
	return (
		<Page>
			<StrategyHeader />
			<RouterSwitch>
				<Redirect exact to="/strategy/strategies" from="/strategy" />
				<Redirect to="/strategy/strategies" from="/strategy/components" />
				<Redirect
					to="/strategy/strategies"
					from="/strategy/strategies/:strategyId"
				/>
				<Route exact path="/strategy/strategies" component={StrategiesPage} />
				<Redirect
					from="/strategy/categories/create"
					to="/strategy/objectives"
				/>
				<Route exact path="/strategy/objectives" component={ObjectivesPage} />
				<Redirect
					from="/strategy/business-rules/:businessRuleId/edit"
					to="/strategy/business-rules"
				/>
				<Redirect
					from="/strategy/business-rules/create"
					to="/strategy/business-rules"
				/>
				<Route
					exact
					path="/strategy/business-rules"
					component={BusinessRulesOverview}
				/>
			</RouterSwitch>
		</Page>
	);
};

export default StrategyView;
