import create from 'zustand';
import { persist } from 'zustand/middleware';
import { StrategiesDataType, Strategy } from '../../strategy/types/strategies';
import { FilterMap } from '../components/Filter/FullFilterMenu.types';

interface StrategyStore {
	activeStrategy: Strategy['id'] | null;
	setActiveStrategy: (strategy: Strategy['id']) => void;
	filters: FilterMap;
	setFilters: (filters: FilterMap) => void;
	strategies: StrategiesDataType;
	setStrategies: (strategies: StrategiesDataType) => void;
	reset: () => void;
}

const useStrategyStore = create<StrategyStore>(
	persist(
		(set) => ({
			filters: new Map(),
			setFilters: (filters) => set({ filters }),
			// TODO WARD/KILIAN: remove strategies from persisted state
			strategies: <StrategiesDataType>[],
			activeStrategy: <Strategy['id'] | null>null,
			setStrategies: (strategies) => set({ strategies }),
			setActiveStrategy: (activeStrategy) => set({ activeStrategy }),
			reset: () =>
				set({ filters: new Map(), strategies: [], activeStrategy: null }),
		}),
		{
			name: 'ca-strategies-store',
			whitelist: ['activeStrategy', 'strategies'],
		}
	)
);

export default useStrategyStore;
