import { components } from '../../../types/backend-api';
import { PayloadWithId } from '../../strategy/types/helpers';
import {
	AllBusinessRulesDataType,
	AllObjectivesDataType,
	AllStrategiesStatusDataType,
	NewStrategyDefaultDataType,
	ScenariosDataType,
	StrategiesDataType,
	StrategyBusinessRulesDataType,
	StrategyCreateParamsType,
	StrategyObjectivesDataType,
	StrategyUpdateBusinessRulesParams,
	StrategyUpdateObjectivesParams,
	StrategyUpdateParamsType,
} from '../../strategy/types/strategies';
import api from './api';

/**
 * new file to avoid conflicts with existing components
 */

export const LIST_STRATEGIES = (): Promise<StrategiesDataType> => {
	return api.get(`api/v2/strategies`).json();
};

export const CREATE_STRATEGY = (payload: StrategyCreateParamsType) => {
	return api
		.post('api/v2/strategies', {
			json: payload,
		})
		.json();
};

export const RUN_STRATEGY = (strategy_id: string) => {
	return api.post(`api/v2/strategies/${strategy_id}/runs`).json();
};

export const UPDATE_STRATEGY = ({
	id: strategy_id,
	...payload
}: PayloadWithId<StrategyUpdateParamsType>) => {
	return api
		.put(`api/v2/strategies/${strategy_id}`, {
			json: payload,
		})
		.json();
};

export const DELETE_STRATEGY = (strategy_id: string) => {
	return api.delete(`api/v2/strategies/${strategy_id}`).json();
};

export const LIST_DEFAULT_SCENARIOS = (): Promise<ScenariosDataType> => {
	return api.get(`api/v2/objective-groups/default-objective-scenarios`).json();
};

export const GET_NEW_STRATEGY_DEFAULT =
	(): Promise<NewStrategyDefaultDataType> => {
		return api
			.get(
				`api/v2/objective-groups/default-objective-scenarios/new-strategy-default`
			)
			.json();
	};

export const GET_STRATEGY_RUN_STATUS_LATEST = (
	strategyId: components['schemas']['Strategy']['id']
) => {
	return api.get(`api/v2/strategies/${strategyId}/runs/latest`).json();
};

export const GET_ALL_RUN_STATUSES =
	(): Promise<AllStrategiesStatusDataType> => {
		return api.get(`api/v2/strategies/runs/latest`).json();
	};

export const GET_STRATEGY_BUSINESS_RULES = (
	strategy_id: string
): Promise<StrategyBusinessRulesDataType> => {
	return api.get(`api/v2/strategies/${strategy_id}/business-rules`).json();
};

export const GET_ALL_BUSINESS_RULES = (): Promise<AllBusinessRulesDataType> => {
	return api.get(`api/v2/business-rules`).json();
};

export const UPDATE_STRATEGY_BUSINESS_RULES = ({
	id: strategy_id,
	rules: payload,
}: {
	id: string;
	rules: StrategyUpdateBusinessRulesParams;
}) => {
	return api
		.patch(`api/v2/strategies/${strategy_id}/business-rules`, {
			json: payload,
		})
		.json();
};

export const GET_STRATEGY_OBJECTIVES = (
	strategy_id: string
): Promise<StrategyObjectivesDataType> => {
	return api.get(`api/v2/strategies/${strategy_id}/objective-groups`).json();
};

export const GET_ALL_OBJECTIVES = (): Promise<AllObjectivesDataType> => {
	return api.get(`api/v2/objective-groups`).json();
};

export const UPDATE_STRATEGY_OBJECTIVES = ({
	id: strategy_id,
	objectives: payload,
}: {
	id: string;
	objectives: StrategyUpdateObjectivesParams;
}) => {
	return api
		.put(`api/v2/strategies/${strategy_id}/objective-groups`, {
			json: payload,
		})
		.json();
};

export const BATCH_CREATE_STRATEGY = async ({
	name,
	rules,
	objectives,
}: {
	name: string;
	rules: StrategyUpdateBusinessRulesParams;
	objectives: StrategyUpdateObjectivesParams;
}) => {
	const res: any = await CREATE_STRATEGY({ name });
	const strategyId = res?.id;
	return Promise.all([
		UPDATE_STRATEGY_BUSINESS_RULES({ id: strategyId, rules }),
		UPDATE_STRATEGY_OBJECTIVES({ id: strategyId, objectives }),
	]);
};

export const BATCH_UPDATE_STRATEGY = async ({
	strategyId,
	name,
	rules,
	objectives,
}: {
	strategyId: string;
	name: string;
	rules: StrategyUpdateBusinessRulesParams;
	objectives: StrategyUpdateObjectivesParams;
}) => {
	return Promise.all([
		UPDATE_STRATEGY({ id: strategyId, name }),
		UPDATE_STRATEGY_BUSINESS_RULES({ id: strategyId, rules }),
		UPDATE_STRATEGY_OBJECTIVES({ id: strategyId, objectives }),
	]);
};
