import clsx from 'clsx';
import { FunctionComponent, MouseEvent, ReactNode } from 'react';

export enum ActionType {
	Regular,
	Danger,
}

interface Props {
	onClick: () => void;
	children: ReactNode;
	type?: ActionType;
}

const ACTION_COLORS = {
	[ActionType.Regular]: 'text-ca-black',
	[ActionType.Danger]: 'text-ca-red',
};

const Action: FunctionComponent<Props> = ({
	onClick,
	children,
	type = ActionType.Regular,
}) => {
	function handleClick(event: MouseEvent<HTMLButtonElement>): void {
		event.stopPropagation();
		onClick();
	}

	return (
		<button
			onClick={handleClick}
			className={clsx(
				'flex w-full justify-between items-center text-ca-black px-4 py-2 text-sm transition-colors hover:bg-ca-silver',
				ACTION_COLORS[type]
			)}
		>
			<span>{children}</span>
		</button>
	);
};

export default Action;
