import { FunctionComponent } from 'react';
import { UseFieldArrayAppend } from 'react-hook-form';
import { Configuration } from '../../../shared/models/configuration';

interface Props {
	append: UseFieldArrayAppend<Configuration, 'marketingExpectations'>;
}

const AddMarketingExpectation: FunctionComponent<Props> = ({ append }) => {
	const onClick = () => {
		append({
			campaignName: '',
			salesModifier: 1.5,
			scope: { products: [], locations: [] },
		});
	};

	return (
		<button
			type="button"
			className="w-full border border-ca-silver bg-white text-ca-gray max-w-sm -ml-4 py-3 px-4 rounded-lg text-sm text-left"
			onClick={onClick}
		>
			Add a marketing expectation
		</button>
	);
};

export default AddMarketingExpectation;
