class ClassWatcher {
	private targetNode: HTMLElement;

	private classToWatch: string;

	private classAddedCallback: () => void;

	private classRemovedCallback: () => void;

	private observer: MutationObserver | null;

	private lastClassState: boolean;

	constructor(
		targetNode: HTMLElement,
		classToWatch: string,
		classAddedCallback: () => void,
		classRemovedCallback: () => void
	) {
		this.targetNode = targetNode;
		this.classToWatch = classToWatch;
		this.classAddedCallback = classAddedCallback;
		this.classRemovedCallback = classRemovedCallback;
		this.observer = null;
		this.lastClassState = targetNode.classList.contains(this.classToWatch);

		this.init();
	}

	init() {
		this.observer = new MutationObserver(this.mutationCallback);
		this.observe();
	}

	observe() {
		this.observer?.observe(this.targetNode, { attributes: true });
	}

	disconnect() {
		this.observer?.disconnect();
	}

	mutationCallback = (mutationsList: MutationRecord[]) => {
		// eslint-disable-next-line no-restricted-syntax
		for (const mutation of mutationsList) {
			if (
				mutation.type === 'attributes' &&
				mutation.attributeName === 'class'
			) {
				const currentClassState = (
					mutation.target as HTMLElement
				).classList.contains(this.classToWatch);
				if (currentClassState) {
					this.classAddedCallback();
				} else {
					this.classRemovedCallback();
				}
			}
		}
	};
}

export default ClassWatcher;
