import { GET_CUMULIO_SUPPORT_VALIDATION_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const SupportValidation = () => {
	return (
		<FullCumulioDashboard
			queryKey="cumulio-support-validation"
			ssoQuery={GET_CUMULIO_SUPPORT_VALIDATION_SSO}
		/>
	);
};

export default SupportValidation;
