import Nav from '../../shared/components/Nav/Nav';

const NAV_ITEMS = [
	{
		id: 'inventory',
		label: 'Inventory',
		to: '/actuals/inventory',
	},
	{
		id: 'sales',
		label: 'Sales',
		to: '/actuals/sales',
	},
	{
		id: 'yoycomparison',
		label: 'YoY Comparison',
		to: '/actuals/yoycomparison',
	},
	// Product Actuals commented out to disable it. Can be used later on
	// {
	//	id: 'product',
	//	label: 'Product Actuals',
	//	to: '/actuals/product',
	// },
];

const ActualsNav = () => {
	return <Nav items={NAV_ITEMS} />;
};

export default ActualsNav;
