import ky from 'ky';

import useChannelStore from '../data/channels';
import useJWT from '../data/jwt';

const api = ky.extend({
	prefixUrl: window._ENV_.REACT_APP_STRATOS_API,
	timeout: parseInt(window._ENV_.REACT_APP_API_TIMEOUT, 10) || 30000,
	hooks: {
		beforeRequest: [
			(request) => {
				const token = useJWT.getState()?.jwt?.access_token;
				const { activeChannel } = useChannelStore.getState();

				if (token) {
					request.headers.set('Authorization', `Bearer ${token}`);
				}

				if (activeChannel) {
					request.headers.set('channel', activeChannel);
				}
			},
		],
		afterResponse: [
			(_request, _options, response) => {
				// if no jwt is found, log the user out
				if (response.status === 401) {
					useJWT.setState({ jwt: null });
				}
			},
		],
	},
});

export const vulcanApi = ky.extend({
	prefixUrl: window._ENV_.REACT_APP_VULCAN_API,
	timeout: parseInt(window._ENV_.REACT_APP_API_TIMEOUT, 10) || 30000,
	hooks: {
		beforeRequest: [
			(request) => {
				const token = useJWT.getState()?.jwt?.access_token;
				const { activeChannel } = useChannelStore.getState();

				if (token) {
					request.headers.set('Authorization', `Bearer ${token}`);
				}

				if (activeChannel) {
					request.headers.set('channel', activeChannel);
				}
			},
		],
		afterResponse: [
			(_request, _options, response) => {
				// if no jwt is found, log the user out
				if (response.status === 401) {
					useJWT.setState({ jwt: null });
				}
			},
		],
	},
});

export default api;
