import { Dispatch, SetStateAction } from 'react';
import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';
import Greeting from '../../shared/components/Greeting/Greeting';
import Title from '../../shared/components/Title/Title';

import { FilterMap } from '../../shared/components/Filter/FullFilterMenu.types';
import ActualsFilter from './ActualsFilter';
import ActualsNav from './ActualsNav';

type ActualsHeaderProps = {
	filters: FilterMap;
	setFilters: Dispatch<SetStateAction<FilterMap>>;
};

const ActualsHeader = ({ filters, setFilters }: ActualsHeaderProps) => (
	<>
		<div className="flex justify-between">
			<div className="flex flex-col gap-2">
				<Title>Actuals</Title>
				<Greeting />
			</div>
			<div className="flex flex-col gap-2">
				<ChannelSelector />
			</div>
		</div>
		<div className="mt-6">
			<ActualsNav />
		</div>
		<div className="mt-6">
			<ActualsFilter filters={filters} setFilters={setFilters} />
		</div>
	</>
);

export default ActualsHeader;
