// generates an array within a given range of `start` and `end` (both inclusive)
// with steps equal to the increment (`inc`)
// ex: range(10, 35, 5) => [10, 15, 20, 25, 30, 35]
const range = (start, end, inc) => {
	return Array(Math.round((end - start) / inc) + 1)
		.fill()
		.map((_, index) => start + index * inc);
};

export default range;
