import dayjs from 'dayjs';

import { StatusInfo } from '../../../strategy/types/strategies';
import { mapOutdatedReasonToCopy } from '../../../strategy/v2/utils';
import { cn } from '../../utils/styles';
import Skeleton from '../Skeleton/Skeleton';
import StatusLine, { StatusLineProps } from './StatusLine';

function mapStatusToContents(strategyStatus: StatusInfo): StatusLineProps {
	if (strategyStatus.status === 'DONE') {
		return {
			tooltip: null,
			text: `Last algorithm run: ${dayjs(strategyStatus.updatedAt).format(
				'DD/MM/YYYY [at] HH:mm:ss'
			)}.`,
			indicator: 'success',
		};
	}
	if (strategyStatus.status === 'RUNNING') {
		return {
			tooltip: null,
			text: `Algorithm running, started at: ${dayjs(
				strategyStatus.updatedAt
			).format('DD/MM/YYYY [at] HH:mm:ss')}.`,
			indicator: 'running',
		};
	}
	if (strategyStatus.status === 'OUTDATED') {
		return {
			tooltip: [
				mapOutdatedReasonToCopy(strategyStatus.outdatedReason),
				`Last algorithm run: ${dayjs(strategyStatus.updatedAt).format(
					'DD/MM/YYYY [at] HH:mm:ss'
				)}`,
			].join(' '),
			text: 'These results are outdated!',
			indicator: 'warning',
		};
	}

	if (strategyStatus.status === 'FAILED') {
		return {
			tooltip: null,
			text: `Last algorithm run failed at: ${dayjs(
				strategyStatus.updatedAt
			).format('DD/MM/YYYY [at] HH:mm:ss')}. Showing previous results.`,
			indicator: 'fail',
		};
	}

	return {
		tooltip: null,
		text: 'This strategy has not been run yet!',
		indicator: 'warning', // Not 'inactive' because we want the user to get a 'I should run the strategy' vibe.
	};
}

type AlgorithmStatusProps = {
	strategyStatus?: StatusInfo;
	className?: string;
};

const AlgorithmStatus = ({
	strategyStatus,
	className,
}: AlgorithmStatusProps) => {
	if (!strategyStatus) {
		return <Skeleton className={cn('h-3 w-32', className)} />;
	}
	const content = mapStatusToContents(strategyStatus);
	content.className = className;

	return <StatusLine {...content} />;
};

export default AlgorithmStatus;
