import { Dispatch, SetStateAction } from 'react';
import { components } from '../../../types/backend-api';
import { GET_ACTUALS_FILTERS } from '../../shared/api/reports';
import FullFilterMenu from '../../shared/components/Filter/FullFilterMenu';
import { FilterMap } from '../../shared/components/Filter/FullFilterMenu.types';
import useChannelQuery from '../../shared/hooks/useChannelQuery';

type ActualsFilterProps = {
	filters: FilterMap;
	setFilters: Dispatch<SetStateAction<FilterMap>>;
};

type UIFilter = components['schemas']['UIFilterDefinition'];

const ActualsFilter = ({ filters, setFilters }: ActualsFilterProps) => {
	const filterQuery = () =>
		useChannelQuery<UIFilter[], unknown, UIFilter[]>(
			['actuals-filters'],
			() => GET_ACTUALS_FILTERS(),
			{
				staleTime: 5 * 60 * 1000,
			}
		);
	if (!filterQuery) return null;
	return (
		<FullFilterMenu
			filterQuery={filterQuery}
			filters={filters}
			setFilters={setFilters}
		/>
	);
};

export default ActualsFilter;
