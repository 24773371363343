import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { createContext, Fragment, useCallback, useState } from 'react';
import useKeyPress from '../hooks/useKeyPress';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
	const [modal, setModal] = useState();
	const [sizeClass, setSizeClass] = useState('');
	const [operationMode, setOperationMode] = useState('');
	const [identifier, setIdentifier] = useState(undefined);
	const [onCloseFn, setOnCloseFn] = useState(undefined);

	const open = (
		m,
		size = '',
		mode = '',
		id = undefined,
		onClose = undefined
	) => {
		setModal(m);
		setSizeClass(size);
		setOperationMode(mode);
		setIdentifier(id);
		setOnCloseFn(() => {
			return onClose;
		});

		document.body.style.overflow = 'hidden';
	};

	const close = useCallback(
		(id = undefined) => {
			if (id !== undefined && identifier !== id) {
				return;
			}

			console.log('should delete all params');
			if (onCloseFn) {
				onCloseFn();
			}

			setModal();
			setSizeClass('');
			setOperationMode('');
			setOnCloseFn(undefined);
			setIdentifier(undefined);
			document.body.style = '';
		},
		[onCloseFn, modal, identifier]
	);

	const nonPriorityClose = () => {
		if (operationMode !== 'priority') {
			close(identifier);
		}
	};

	useKeyPress('Escape', nonPriorityClose);

	return (
		<ModalContext.Provider value={{ open, close }}>
			{children}
			<Transition appear show={!!modal} as={Fragment}>
				<div className="z-50 fixed left-0 right-0 bottom-0 top-0">
					<div
						role="presentation"
						className="absolute left-0 right-0 bottom-0 top-0 backdrop-filter backdrop-blur-sm"
						style={{ background: 'rgba(230, 230, 230, 0.5)' }}
						onClick={nonPriorityClose}
					/>
					<div
						className={clsx(
							'absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2',
							sizeClass
						)}
					>
						<Transition.Child
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<div>{modal}</div>
						</Transition.Child>
					</div>
				</div>
			</Transition>
		</ModalContext.Provider>
	);
};

ModalProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { ModalContext, ModalProvider };
