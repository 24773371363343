import Icon from './Icon';

const Strategy: Icon = ({ className = '' }) => (
	<svg
		className={className}
		viewBox="0 0 20 19"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m3 12.6593707 6-7.6593707v11zm14 0-6 3.3406293v-11zm-5.999-11.23218674-1.001-1.42718396-1 1.42718396-9 11.86800644 9 5.1757503 1 .5290593 1.001-.5290593 8.999-5.1757503z"
			fill="currentColor"
		/>
	</svg>
);

export default Strategy;
