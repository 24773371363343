import { useHistory } from 'react-router-dom';
import useToast from '../../shared/data/toast';
import { useIsLoading } from '../../shared/hooks/useIsLoading';
import useModal from '../../shared/hooks/useModal';
import { Mutation, QueryResult } from '../../shared/models/API';
import { Configuration } from '../../shared/models/configuration';
import { InventoryAllocation } from '../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../shared/models/inventoryAllocationReport';
import { CreateReportInput } from '../reports/models/reportInput';
import ConfirmModal from './modals/ConfirmModal';
import CreateInventoryAllocationModal from './modals/CreateInventoryAllocationModal';
import RenameInventoryAllocationModal from './modals/RenameInventoryAllocationModal';
import {
	CreateInventoryAllocationInput,
	DeleteInventoryAllocationInput,
	RenameInventoryAllocationInput,
	SubmitInventoryAllocationInput,
} from './models/inventoryAllocationInput';

export type InventoryAllocationAPI = QueryResult<InventoryAllocation>;

export type CreateInventoryAllocationMutation = Mutation<
	CreateInventoryAllocationInput,
	InventoryAllocation
>;

export type RenameInventoryAllocationMutation = Mutation<
	RenameInventoryAllocationInput,
	InventoryAllocation
>;

export type DeleteInventoryAllocationMutation =
	Mutation<DeleteInventoryAllocationInput>;

export type SubmitInventoryAllocationMutation =
	Mutation<SubmitInventoryAllocationInput>;

interface Props {
	data: InventoryAllocationAPI;
	createMutation: CreateInventoryAllocationMutation;
	renameMutation: RenameInventoryAllocationMutation;
	deleteMutation: DeleteInventoryAllocationMutation;
	submitMutation: SubmitInventoryAllocationMutation;
	createReport: (
		input: CreateReportInput
	) => Promise<InventoryAllocationReport | undefined>;
	submitReport: (
		inventoryAllocationId: InventoryAllocation['id'],
		id: InventoryAllocationReport['id'],
		title: InventoryAllocationReport['title']
	) => Promise<void>;
}

interface Result extends InventoryAllocationAPI {
	create: (configuration?: Configuration) => Promise<void>;
	rename: (
		inventoryAllocation: Pick<InventoryAllocation, 'id' | 'title' | 'status'>
	) => Promise<void>;
	addDraft: (
		id: InventoryAllocation['id'],
		drafts: InventoryAllocation['drafts'],
		configuration?: Configuration
	) => Promise<void>;
	deleteInventoryAllocation: (id: InventoryAllocation['id']) => Promise<void>;
	submit: (
		id: InventoryAllocation['id'],
		reportId: InventoryAllocationReport['id'],
		reportTitle: InventoryAllocationReport['title']
	) => Promise<void>;
}

/**
 * This hook holds the `application rules` for every inventory allocation. Here we decide which actions the end user can
 * undertake. Which mutations these run in the background and what happens before or after those mutation.
 *
 * For example: When we create a new inventory allocation, we want to navigate to the newly created inventory allocation
 * detail page. It doesn't matter if we create this inventory allocation using a form on page A, or a duplicate button
 * on page B. We always navigate to the newly created inventory allocation detail page. So this logic would be
 * considered an application rule and should be defined within this hook.
 */
const useInventoryAllocationsUseCases = ({
	data,
	createMutation,
	renameMutation,
	deleteMutation,
	submitMutation,
	createReport,
	submitReport,
}: Props): Result => {
	const [isLoading, setIsLoading] = useIsLoading(data.isLoading);
	const { open } = useModal();
	const history = useHistory();
	const { show } = useToast();

	const rename: Result['rename'] = async ({ id, title, status }) => {
		open(
			<RenameInventoryAllocationModal
				defaultTitle={title}
				onSubmit={(newTitle) => renameMutation({ id, status, title: newTitle })}
			/>
		);
	};

	const addDraft: Result['addDraft'] = async (id, drafts, configuration) => {
		setIsLoading(true);
		const report = await createReport({
			inventoryAllocation: id,
			title: `Draft ${drafts.length + 1}`,
			configuration,
		});

		if (report?.id) {
			history.push(`/stock/inventory-allocations/${id}/${report.id}`);
		}
		setIsLoading(false);
	};

	const create: Result['create'] = async (configuration) => {
		setIsLoading(true);
		const createInventoryAllocation = async (
			input: CreateInventoryAllocationInput
		) => {
			const inventoryAllocation = await createMutation(input);

			if (inventoryAllocation?.id) {
				await addDraft(
					inventoryAllocation.id,
					inventoryAllocation.drafts,
					configuration
				);
			}
		};

		open(
			<CreateInventoryAllocationModal
				defaultValues={{ title: '', configuration }}
				onSubmit={createInventoryAllocation}
			/>
		);
		setIsLoading(false);
	};

	const deleteInventoryAllocation: Result['deleteInventoryAllocation'] = async (
		id
	) => {
		open(
			<ConfirmModal
				title="Delete inventory allocation"
				onAccept={() => deleteMutation({ id })}
			/>
		);
	};

	const submit: Result['submit'] = async (id, reportId, reportTitle) => {
		try {
			await submitReport(id, reportId, reportTitle);
			await submitMutation({ id, reportId });
			history.push('/stock/inventory-allocations');
			show('The inventory allocation request has been submitted.');
		} catch (error) {
			console.error(error);
		}
	};

	return {
		...data,
		isLoading,
		entity: data.entities[0] ?? undefined,
		create,
		rename,
		addDraft,
		deleteInventoryAllocation,
		submit,
	};
};

export default useInventoryAllocationsUseCases;
