import dayjs from 'dayjs';
import { today } from '../../shared/utils/dates';
import { PhasesDataType, PhaseType, SeasonPhasesType } from '../types/seasons';
import {
	AllBusinessRulesDataType,
	AllObjectivesDataType,
} from '../types/strategies';

// TODO used for testing
export const TODAY = today();

export const SEASON_PHASES_QUERY_KEY = ['season_phases'];
export const SEASON_CURRENT_QUERY_KEY = ['season_current'];
export const STRATEGY_QUERY_KEY = ['strategies'];
export const OBJECTIVE_QUERY_KEY = ['objectives'];
export const STRATEGY_RULES_KEY = ['strategy_rules'];
export const ALL_RULES_KEY = ['all_rules'];
export const PRODUCTS_SCHEMA_KEY = ['objectives_products_schema'];
export const ALL_PRODUCTS_KEY = ['all_products_filter'];
export const ALL_OBJECTIVES_KEY = ['all_objectives'];
export const ALL_PRODUCT_FILTERS_KEY = ['all_product_filters'];
export const STRATEGY_OBJECTIVES_KEY = ['strategy_objectives'];
export const DEFAULT_SCENARIOS_QUERY_KEY = ['default-scenarios'];
export const NEW_STRATEGY_DEFAULT_QUERY_KEY = ['new-strategy-default'];

export const EMPTY_PHASES: SeasonPhasesType = {
	beforeNow: [],
	afterNow: [],
};

// phases
// data: [{"id":"87654321-4321-4321-4321-cba987654321","name":"Money making time","start_date":"2024-03-18"}]
// season
// data: {"id":"12345678-1234-1234-1234-123456789abc","name":"The Season of Money Making","end_date":"2024-09-23","include_shipping_cost":true,"include_return_cost":false}

export const transformPhases = (data: PhasesDataType): SeasonPhasesType => {
	if (!Array.isArray(data)) {
		return EMPTY_PHASES;
	}
	const transformed = data.map((item): PhaseType => {
		return { ...item, type: 'saved', startDate: dayjs(item.start_date) };
	});
	// sort by startDate just to be sure
	transformed.sort((a, b) => (a.start_date > b.start_date ? 1 : -1));
	// beforeNow - completed phases
	// afterNow - scheduled phases
	return {
		beforeNow: transformed.filter((item) => item.completed),
		afterNow: transformed.filter((item) => !item.completed),
	};
};

export type ObjectiveBasicDataType = {
	name: string;
	id: string;
	products_count: number;
	priority: number;
};

export type RuleBasicDataType = {
	name: string;
	id: string;
	title: string;
};

export const transformAllObjectives = (data: AllObjectivesDataType) => {
	if (!data?.items) {
		return [];
	}
	return data.items.map(({ name, id, priority, products_count }) => ({
		name,
		id,
		products_count,
		priority,
	}));
};

export const transformAllRules = (data: AllBusinessRulesDataType) => {
	if (!data?.items) {
		return [];
	}
	return data.items.map(({ title, id }) => ({
		title,
		name: title,
		id,
	}));
};
