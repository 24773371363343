import PropTypes from 'prop-types';

const Typemark = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 215 50"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="currentColor" fillRule="evenodd">
			<path d="m19.7259 13.1073c-5.359 0-9.893 1.786-13.602 5.29-3.642 3.504-5.496 7.832-5.496 13.053s1.854 9.549 5.496 13.053c3.709 3.503 8.243 5.221 13.602 5.221 5.702 0 10.511-2.13 14.427-6.458l-6.458-6.389c-2.267 2.404-4.878 3.572-7.969 3.572-4.946 0-8.862-3.778-8.862-8.999s3.847-9.069 8.862-9.069c3.091 0 5.702 1.168 7.969 3.573l6.458-6.389c-3.916-4.328-8.725-6.458-14.427-6.458" />
			<path d="m47.6171 48.831v-16.9c0-5.702 3.435-9 9.274-9 1.237 0 2.405.138 3.435.344v-9.618c-1.099-.137-1.992-.206-2.679-.206-4.74 0-8.45 1.992-10.03 5.496v-4.74h-10.236v34.624z" />
			<path d="m160.926 13.1073c-5.359 0-9.893 1.786-13.602 5.29-3.642 3.504-5.496 7.832-5.496 13.053s1.854 9.549 5.496 13.053c3.709 3.503 8.243 5.221 13.602 5.221 5.702 0 10.511-2.13 14.427-6.458l-6.458-6.389c-2.267 2.404-4.878 3.572-7.969 3.572-4.946 0-8.862-3.778-8.862-8.999s3.847-9.069 8.862-9.069c3.091 0 5.702 1.168 7.969 3.573l6.458-6.389c-3.916-4.328-8.725-6.458-14.427-6.458" />
			<path d="m188.8172 48.831v-19.442c0-4.053 2.611-6.87 6.389-6.87 3.779 0 5.908 2.473 5.908 6.527v10.305c0 6.595 3.023 9.892 9.137 9.892 1.992 0 3.435-.137 4.397-.481v-8.656c-.069 0-.687.069-1.031.069-1.717 0-2.267-.962-2.267-3.641v-8.725c0-9-4.671-14.358-12.846-14.358-4.603 0-8.313 1.923-9.687 4.946v-17.449h-10.236v47.883z" />
			<path d="m99.9368 24.8531c-6.515 6.314-12.67 12.279-18.505 12.665-1.843.144-3.787-.637-5.2-2.098-1.287-1.328-2.006-3.077-2.023-4.877l-.024-17.092-10.072.011.024 17.14c.045 4.931 1.902 9.536 5.228 12.965 3.182 3.278 7.396 5.106 11.693 5.101.326 0 .653-.01.981-.032 9.274-.615 17.033-8.134 24.537-15.405" />
			<path d="m102.35 37.266c6.515-6.314 12.67-12.278 18.505-12.665 1.843-.144 3.787.637 5.2 2.098 1.287 1.328 2.006 3.077 2.023 4.877l.024 17.092 10.072-.011-.024-17.14c-.045-4.931-1.902-9.536-5.228-12.964-3.182-3.279-7.396-5.107-11.693-5.102-.326 0-.653.01-.981.032-9.274.615-17.033 8.134-24.537 15.405" />
			<path d="m85.678 13.4509h10.156v7.919l-10.156 9.071z" />
			<path d="m116.3132 48.831h-10.156v-7.919l10.156-9.071z" />
		</g>
	</svg>
);

Typemark.propTypes = {
	className: PropTypes.string,
};

Typemark.defaultProps = {
	className: '',
};

export default Typemark;
