import Icon from './Icon';

const BarChart: Icon = ({ className = '' }) => (
	<svg
		className={className}
		viewBox="0 0 120 120"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="-0.000175476"
			y="108"
			width="120"
			height="12"
			fill="currentColor"
		/>
		<rect x="108" width="12" height="96" fill="currentColor" />
		<rect x="81" y="36" width="12" height="60" fill="currentColor" />
		<rect y="48" width="12" height="48" fill="currentColor" />
		<rect x="27" y="24" width="12" height="72" fill="currentColor" />
		<rect x="54" y="12" width="12" height="84" fill="currentColor" />
	</svg>
);

export default BarChart;
