import Button from '../../shared/components/Button/Button';
import {
	AdvancedSettings,
	AdvancedSettingsProps,
} from '../v2/AdvancedSettingsDialog';

type ResidualValueSettingsButtonAndModalProps = {
	onSaveCustom: AdvancedSettingsProps['onSave'];
	onChangeCustom: AdvancedSettingsProps['onChange'];
	value: AdvancedSettingsProps['value'];
	title: string;
	hexColor: string;
};

const ResidualValueSettingsButtonAndModal = (
	props: ResidualValueSettingsButtonAndModalProps
) => {
	return (
		<AdvancedSettings
			onChange={props.onChangeCustom}
			onSave={props.onSaveCustom}
			value={props.value}
		>
			<Button
				variant="unstyled"
				className=" flex flex-col items-start  py-1 px-2 rounded-md text-gray-500 gap-1  justify-center group  h-12 hover:bg-[hsl(225,13%,90%)]"
			>
				<div className="flex gap-2 items-center">
					<div
						className="h-1.5 w-1.5 rounded-full"
						style={{ backgroundColor: props.hexColor }}
					/>
					<span className="text-xs group-hover:font-semibold after:content-[''] after:w-full after:border-b after:left-0 after:bottom-0 after:border-gray-400 after:absolute relative group-hover:after:border-transparent after:transition-colors after:duration-300">
						{props.title}
					</span>
				</div>
				<span className="text-xs max-h-0 group-hover:max-h-10 overflow-hidden transition-all duration-300 opacity-0 group-hover:opacity-100">
					advanced settings
				</span>
			</Button>
		</AdvancedSettings>
	);
};

export default ResidualValueSettingsButtonAndModal;
