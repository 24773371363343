/**
 * returns an object with selected keys (needed for API calls)
 */
export function pick<T extends {}, K extends keyof T>(obj: T, keys: K[]) {
	const result: Partial<Pick<T, K>> = {};
	keys.forEach((key) => {
		if (key in obj) {
			result[key] = obj[key];
		}
	});
	return result as Pick<T, K>;
}

/**
 * makes TS happy by validating object key
 */
export function isValidKey<T extends {}>(key: any, obj: T): key is keyof T {
	return key in obj;
}
