import Text from '../../shared/components/Text/Text';
import Title from '../../shared/components/Title/Title';
import Tooltip from '../../shared/components/Tooltip/Tooltip';

const ActiveFilterPill = ({ title }) => {
	return (
		<Text
			size="text-xs"
			className="text-gray-500 border border-gray-500 rounded-full px-2.5 py-0.5 block"
		>
			{title}
		</Text>
	);
};

const MarkdownTransferActiveFilters = ({ filters }) => {
	const filtersArray = Array.isArray(filters) ? filters : [];
	return (
		<section className="mb-8">
			<header className="flex gap-2 items-center mb-1">
				<Tooltip content={<>The filters you have selected for this scenario</>}>
					<span>
						<Title type="subsection">Active filters </Title>
					</span>
				</Tooltip>

				<Text
					className="bg-ca-gray text-white w-5 h-5 rounded-full flex justify-center items-center font-bold"
					size="text-xs"
				>
					{filtersArray.length}
				</Text>
			</header>
			<div className="flex items-baseline gap-2 mb-4">
				{filtersArray.length > 0 ? (
					<ul className="flex justify-center gap-2">
						{filtersArray.map((filter) => {
							return (
								<li key={filter}>
									<ActiveFilterPill title={filter} />
								</li>
							);
						})}
					</ul>
				) : (
					<Text type="secondary">No active filters...</Text>
				)}
			</div>
		</section>
	);
};

export default MarkdownTransferActiveFilters;
