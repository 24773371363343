import { FunctionComponent } from 'react';
import { useInventoryAllocations } from '../../../domains/domains';
import { Configuration } from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import InventoryAllocationDrafts from './InventoryAllocationDrafts';
import InventoryAllocationReportDetail from './InventoryAllocationReportDetail';

interface Props extends InventoryAllocation {
	isLoading?: boolean;
}

const InventoryAllocationDetail: FunctionComponent<Props> = ({
	id,
	report,
	drafts,
	isLoading,
}) => {
	const rootURL = `/stock/inventory-allocations/${id}`;
	const {
		create,
		addDraft,
		submit,
		isLoading: isAddingDraft,
	} = useInventoryAllocations();

	const copyConfigurationToNewInventoryAllocation = (
		configuration: Configuration
	): void => {
		create(configuration);
	};

	if (report) {
		return (
			<InventoryAllocationReportDetail
				inventoryAllocationId={id}
				id={report}
				rootURL={rootURL}
				isCopyingConfiguration={isAddingDraft || isLoading}
				isAddingDraft={isAddingDraft || isLoading}
				onCopyConfiguration={copyConfigurationToNewInventoryAllocation}
				onAddDraft={() => addDraft(id, drafts)}
				warningMessage={undefined}
			/>
		);
	}

	return (
		<InventoryAllocationDrafts
			inventoryAllocationId={id}
			drafts={drafts}
			rootURL={rootURL}
			isAddingDraft={isAddingDraft || isLoading}
			addDraft={(configuration) => addDraft(id, drafts, configuration)}
			submit={(draft) => submit(id, draft.id, draft.title)}
		/>
	);
};

export default InventoryAllocationDetail;
