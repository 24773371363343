import Icon from './Icon';

const Progress: Icon = ({ className = '' }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			fill="none"
			viewBox="0 0 12 12"
		>
			<path
				fill="currentColor"
				d="M9.265 8.735a.375.375 0 010 .53C9.215 9.315 8.007 10.5 6 10.5c-1.095 0-2.162-.427-3.107-1.238l-.753.753a.375.375 0 01-.64-.265V7.5a.375.375 0 01.375-.375h2.25a.375.375 0 01.265.64l-.964.965C4.035 9.241 4.914 9.75 6 9.75c1.7 0 2.725-1.005 2.735-1.015a.375.375 0 01.53 0zm1.003-6.831a.375.375 0 00-.408.08l-.753.754C8.162 1.927 7.095 1.5 6 1.5c-2.007 0-3.215 1.185-3.265 1.235a.375.375 0 10.53.53C3.275 3.255 4.3 2.25 6 2.25c1.086 0 1.965.509 2.574 1.02l-.964.965a.375.375 0 00.265.64h2.25A.375.375 0 0010.5 4.5V2.25a.375.375 0 00-.232-.346z"
			/>
		</svg>
	);
};

export default Progress;
