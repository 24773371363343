import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';
import Title from '../../shared/components/Title/Title';
import StrategyNav from './StrategyNav';

const StrategyHeader = () => (
	<>
		<div className="relative flex justify-between">
			<Title>Strategy</Title>
			<ChannelSelector />
		</div>
		<div className="mt-6 relative">
			<StrategyNav />
		</div>
	</>
);

export default StrategyHeader;
