import { GET_CUMULIO_STRATEGY_COMPARISON_SSO } from '../../shared/api/reports';

import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';
import useStrategyStore from '../../shared/data/strategies';
import filterMapToSearchParams from '../../shared/utils/filters';
import RecommendationsHeader from '../components/RecommendationsHeader';

const StrategyComparison = () => {
	const { filters } = useStrategyStore();
	const nonStrategySpecificFilters = new Map(
		Array.from(filters.entries()).filter(
			// @ts-ignore
			(x) => !x[1].filterMeta.strategy_specific
		)
	);
	return (
		<>
			<RecommendationsHeader disableStrategySpecific />
			<FullCumulioDashboard
				// TODO: fix type
				// @ts-ignore
				queryKey={[
					// The 'cumulio-scenario-overview' key is reset on season settings and strategy mutations
					// because the first phase name is included in the result of this query as a filter initialization.
					// If the initialization is incorrect the dashboard soft-breaks.
					'cumulio-scenario-overview',
					Object.fromEntries(nonStrategySpecificFilters),
				]}
				ssoQuery={() =>
					GET_CUMULIO_STRATEGY_COMPARISON_SSO(
						filterMapToSearchParams(nonStrategySpecificFilters)
					)
				}
				showLoading
				hardReload
			/>
		</>
	);
};

export default StrategyComparison;
