/* eslint-disable react/jsx-no-bind */
import dayjs from 'dayjs';
import { FunctionComponent, useState } from 'react';
import { QueryKey, useQueries, useQuery, UseQueryOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useInventoryAllocations } from '../../../domains/domains';
import { GET_INVENTORY_ALLOCATION_REPORT } from '../../../shared/api/inventory-allocation-reports';
import { GET_INVENTORY_ALLOCATIONS } from '../../../shared/api/inventory-allocations';
import TriangleDown from '../../../shared/components/Icons/TriangleDown';
import TriangleRight from '../../../shared/components/Icons/TriangleRight';
import Table from '../../../shared/components/NewTable/Table';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';
import Tag, { TagColor } from '../../../shared/components/Tag/Tag';
import {
	InventoryAllocation,
	InventoryAllocationStatus,
} from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import {
	GetInventoryAllocationsParametersDTO,
	GetInventoryAllocationsResponseDTO,
	ReportDTO,
} from '../../../shared/models/schema';
import capitalize from '../../../shared/utils/capitalize';
import Action, { ActionType } from './Action';
import ActionsMenu from './ActionsMenu';
import { DraftsTable } from './DraftsTable';

interface Pagination {
	page: number;
	size: number;
}

enum Column {
	IsOpen = 'inventory-allocation.isOpen',
	Title = 'inventory-allocation.title',
	Status = 'inventory-allocations.status',
	UpdatedOn = 'inventory-allocations.updatedOn',
	Actions = 'inventory-allocations.actions',
}

const HEADINGS = [
	{ id: Column.IsOpen, label: '' },
	{ id: Column.Title, label: 'Title' },
	{ id: Column.Status, label: 'Status' },
	{ id: Column.UpdatedOn, label: 'Updated on' },
	{ id: Column.Actions, label: '' },
];

const DEFAULT_PAGINATION: Pagination = {
	size: 10,
	page: 1,
};

interface StatusCellProps {
	status: InventoryAllocationStatus;
}

const StatusCell: FunctionComponent<StatusCellProps> = ({ status }) => {
	switch (status) {
		case InventoryAllocationStatus.Draft:
			return <Tag label={capitalize(status)} color={TagColor.White} />;
		case InventoryAllocationStatus.Error:
			return <Tag label={capitalize(status)} color={TagColor.Red} />;
		case InventoryAllocationStatus.Executed:
			return <Tag label={capitalize(status)} color={TagColor.Blue} />;
		case InventoryAllocationStatus.InProgress:
			return <Tag label={capitalize(status)} color={TagColor.Orange} />;
		case InventoryAllocationStatus.NoSuggestedMoves:
			return <Tag label={capitalize(status)} color={TagColor.Grey} />;
		case InventoryAllocationStatus.Proposal:
			return <Tag label={capitalize(status)} color={TagColor.Green} />;
		case InventoryAllocationStatus.Submitted:
			return <Tag label={capitalize(status)} color={TagColor.Purple} />;
		default:
			return <Tag label="Unknown" color={TagColor.White} />;
	}
};

interface ActionsCellProps
	extends Pick<InventoryAllocation, 'id' | 'title' | 'status'> {
	isVisible?: boolean;
}

const ActionsCell: FunctionComponent<ActionsCellProps> = ({
	id,
	title,
	status,
	isVisible,
}) => {
	const { rename, deleteInventoryAllocation } = useInventoryAllocations();

	return (
		<ActionsMenu isVisible={isVisible}>
			<Action onClick={() => rename({ id, status, title })}>Rename</Action>
			<Action
				onClick={() => deleteInventoryAllocation(id)}
				type={ActionType.Danger}
			>
				Delete
			</Action>
		</ActionsMenu>
	);
};

const toQuery = (
	inventoryAllocationId: InventoryAllocation['id'],
	reportId: InventoryAllocationReport['id'],
	queryKey: QueryKey
): UseQueryOptions => ({
	queryKey,
	queryFn: () =>
		GET_INVENTORY_ALLOCATION_REPORT(inventoryAllocationId, reportId),
});

const InventoryAllocationsTable: FunctionComponent = () => {
	const history = useHistory();
	const [hoveredRowId, setHoveredRowId] = useState<string>('');
	const [openId, setOpenId] = useState<string>();
	const [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION);
	const result = useQuery<
		GetInventoryAllocationsParametersDTO,
		unknown,
		GetInventoryAllocationsResponseDTO
	>(['inventory-allocations', pagination], () =>
		GET_INVENTORY_ALLOCATIONS(
			new URLSearchParams([
				['page', pagination.page.toString()],
				['size', pagination.size.toString()],
			])
		)
	);
	const { isLoading, entities: inventoryAllocations } =
		useInventoryAllocations(result);

	const reportsPayload: {
		inventoryAllocationId: InventoryAllocation['id'];
		reportId: InventoryAllocationReport['id'];
	}[] = inventoryAllocations
		.map(({ report, id }) => ({ inventoryAllocationId: id, reportId: report }))
		.filter(({ reportId }) => reportId !== undefined) as {
		inventoryAllocationId: InventoryAllocation['id'];
		reportId: InventoryAllocationReport['id'];
	}[];

	const reportsResult = useQueries(
		reportsPayload.map(({ inventoryAllocationId, reportId }) =>
			toQuery(inventoryAllocationId, reportId as string, ['reports', reportId])
		)
	);

	const reports: ReportDTO[] = reportsResult.map(
		({ data }) => data
	) as ReportDTO[];

	function onRowClick(row: InventoryAllocation): void {
		if (row.status === InventoryAllocationStatus.Submitted) {
			history.push(`/stock/inventory-allocations/${row.id}`);
		} else if (openId === row.id) {
			setOpenId('');
		} else {
			setOpenId(row.id);
		}
	}

	function onRowMouseOver(row: InventoryAllocation): void {
		setHoveredRowId(row.id);
	}

	function onRowMouseLeave(): void {
		setHoveredRowId('');
	}

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress visible={isLoading} />
			</div>
			<div className="py-6 space-y-6">
				<Table
					loading={isLoading}
					itemsLoading={10}
					headings={HEADINGS}
					rows={inventoryAllocations}
					rowKey="id"
					onRowClick={onRowClick}
					onRowMouseOver={onRowMouseOver}
					onRowMouseLeave={onRowMouseLeave}
					emptyState="No inventory allocations."
					pagination={{
						currentPage: pagination.page,
						itemsPerPage: pagination.size,
						items: result.data?.total ?? 0,
					}}
					onPageChange={(page) => {
						setPagination({ ...pagination, page });
					}}
					open={[openId]}
					renderOpen={() => {
						const allocation = inventoryAllocations.find(
							({ id }) => id === openId
						);

						if (!openId) {
							return null;
						}

						return (
							<div className="ml-8">
								<DraftsTable id={openId} drafts={allocation?.drafts || []} />
							</div>
						);
					}}
					renderCell={(row: InventoryAllocation, columnId: Column) => {
						if (!reports) {
							return null;
						}

						switch (columnId) {
							case Column.IsOpen:
								if (row.status === InventoryAllocationStatus.Submitted) {
									return null;
								}

								return openId === row.id ? (
									<TriangleDown className="w-2" />
								) : (
									<TriangleRight className="h-2" />
								);
							case Column.Title:
								return <p className="w-128">{row.title}</p>;
							case Column.Status:
								return <StatusCell status={row.status} />;
							case Column.UpdatedOn:
								return <p>{dayjs(row.updatedOn).format('D MMMM YYYY')}</p>;
							case Column.Actions:
								return (
									<ActionsCell {...row} isVisible={row.id === hoveredRowId} />
								);
							default:
								return null;
						}
					}}
				/>
			</div>
		</>
	);
};

export default InventoryAllocationsTable;
