import { ComponentProps } from 'react';
import { cn } from '../../utils/styles';
import { Badge } from '../Badge/Badge';

//  __________________________________________________________________________________________________
//
//  1 <StepsSideBar>																1 <StepsSideBar>
// ___________________________________________________________________________________________________

/**
 * ------------------------------------------------------------------
 * 1.1 | TYPES | <STEPS SIDEBAR> (default export)
 * ------------------------------------------------------------------
 */

export type StepsSidebarProps = {
	badgeTitle: React.ReactNode;
	badgeVariant: ComponentProps<typeof Badge>['variant'];
	title: string;
	steps: React.ReactElement<StepButtonProps>[];
};

/**
 * ------------------------------------------------------------------
 * 1.2 | COMPONENT | <STEPS SIDEBAR> (default export)
 * ------------------------------------------------------------------
 */

const StepsSidebar = (props: StepsSidebarProps) => {
	return (
		<div className="w-48 flex flex-col justify-center gap-8 p-4 bg-ca-gray-200 rounded-md">
			<div className="flex flex-col gap-1">
				<Badge variant={props.badgeVariant} className="w-min">
					{props.badgeTitle}
				</Badge>
				<span className="font-bold text-sm block">{props.title}</span>
			</div>
			<div className="flex flex-col gap-4">{props.steps}</div>
		</div>
	);
};

export default StepsSidebar;

//  __________________________________________________________________________________________________
//
//  2 <Step>																				2 <Step>
// ___________________________________________________________________________________________________

/**
 * ------------------------------------------------------------------
 * 2.1 | TYPES | <STEP>
 * ------------------------------------------------------------------
 */

export type StepButtonProps = {
	title: string;
	state: 'clickable' | 'current' | 'disabled' | 'checkmark';
	onClick: () => void;
};

/**
 * ------------------------------------------------------------------
 * 2.1 | COMPONENT | <STEP>
 * ------------------------------------------------------------------
 */

export const StepButton: React.FC<StepButtonProps> = (props) => {
	return (
		<button
			className={cn(
				'grid grid-cols-[min-content_1fr] gap-4 py-2 px-1',
				props.state !== 'disabled'
					? 'hover:bg-ca-silver rounded-md transition-colors'
					: 'cursor-not-allowed'
			)}
			onClick={props.onClick}
		>
			<div className="place-items-center h-full px-2 grid">
				<div
					className={cn(
						'w-2 h-2 rounded-full ',
						props.state === 'clickable' && 'bg-ca-purple',
						props.state === 'current' && 'bg-ca-purple col-start-1 row-start-1',
						props.state === 'disabled' && 'bg-ca-gray-400'
					)}
				/>
				{props.state === 'current' && (
					<div className="col-start-1 row-start-1 bg-ca-purple animate-ping w-2 h-2 rounded-full" />
				)}
			</div>
			<span
				className={cn(
					'text-xs text-left',
					props.state === 'clickable' && 'text-ca-gray-500',
					props.state === 'current' && 'text-ca-purple',
					props.state === 'disabled' && 'text-ca-gray-500'
				)}
			>
				{props.title}
			</span>
		</button>
	);
};
