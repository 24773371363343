import PropTypes from 'prop-types';

const CurrentStep = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<rect width="16" height="16" fill="#C99EFF" rx="8" />
			<rect width="8" height="8" x="4" y="4" fill="#6111C7" rx="4" />
		</svg>
	);
};

CurrentStep.propTypes = {
	className: PropTypes.string,
};

CurrentStep.defaultProps = {
	className: '',
};

export default CurrentStep;
