import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

const RouterSwitch = ({ children }) => {
	return (
		<Switch>
			{children}
			<Route path="*">
				<Redirect to={window._ENV_.REACT_APP_DEFAULT_PATH} />
			</Route>
		</Switch>
	);
};

RouterSwitch.propTypes = {
	children: PropTypes.node.isRequired,
};

export default RouterSwitch;
