import { createContext } from 'react';
import { StrategiesDataType } from '../../strategy/types/strategies';
import { STRATEGY_QUERY_KEY } from '../../strategy/v2/queries';
import { LIST_STRATEGIES } from '../api/strategies-v2';
import CircularProgress from '../components/Progress/CircularProgress';

import useStrategyStore from '../data/strategies';
import useChannelQuery from '../hooks/useChannelQuery';

const StrategyContext = createContext({});

export type StrategyProviderProps = {
	children: NonNullable<React.ReactNode>;
};

const StrategyProvider = ({ children }: StrategyProviderProps) => {
	const { setStrategies, activeStrategy, setActiveStrategy } =
		useStrategyStore();
	const IS_STRATOS_ENABLED = window._ENV_.REACT_APP_STRATOS_ENABLED;

	const query = useChannelQuery<
		StrategiesDataType,
		unknown,
		StrategiesDataType
	>(STRATEGY_QUERY_KEY, LIST_STRATEGIES, {
		enabled: IS_STRATOS_ENABLED,
		onSuccess: (strategies: StrategiesDataType) => {
			// If there are no strategies, do nothing
			if (strategies.length === 0) {
				return;
			}

			setStrategies(strategies);

			// If there is no active strategy, set the first strategy as active
			if (activeStrategy === null) {
				setActiveStrategy(strategies[0].id);
			}

			// If the active strategy is not in the list of strategies, set the first strategy as active
			const active = strategies.find(({ id }) => id === activeStrategy);
			if (active === undefined) {
				setActiveStrategy(strategies[0].id);
			}
		},
	});

	if (!IS_STRATOS_ENABLED) {
		return <>{children}</>;
	}

	if (query.isLoading) {
		return (
			<div className="min-h-screen flex justify-center items-center">
				<CircularProgress />
			</div>
		);
	}

	return <>{children}</>;
};
export { StrategyProvider, StrategyContext };
