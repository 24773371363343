import cloneDeep from 'lodash.clonedeep';
import { useReducer, useState } from 'react';
import { useMutation } from 'react-query';
import { useThrottledCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import {
	DELETE_OBJECTIVE,
	ORDER_OBJECTIVES,
	UPDATE_OBJECTIVE,
} from '../../shared/api/objectives-v2';
import { Badge } from '../../shared/components/Badge/Badge';
import Button from '../../shared/components/Button/Button';
import EditIcon from '../../shared/components/Icons/Edit';
import Plus from '../../shared/components/Icons/Plus';
import Product from '../../shared/components/Icons/Product';
import Trash from '../../shared/components/Icons/Trash';
import KebabMenu from '../../shared/components/KebabMenu/KebabMenu';
import Table, {
	CELL_PADDING_CLASSNAME,
	CHILD_ROWS_DEFAULT_STYLING,
} from '../../shared/components/NewTable/Table';
import Text from '../../shared/components/Text/Text';
import useToast from '../../shared/data/toast';
import useModal from '../../shared/hooks/useModal';
import { isValidKey, pick } from '../../shared/utils/general';
import { cn } from '../../shared/utils/styles';
import ResidualValueSettingsButtonAndModal from '../components/ResidualValueSettingsButton';
import { ObjectivesDataType, SliderOptionsType } from '../types/objectives';
import {
	GraphData,
	PresetSliderValue,
	ScenariosDataType,
} from '../types/strategies';
import { useEventCallback } from './hooks';
import { ObjectivePresetSlider } from './ObjectivePresetSlider';
import { ObjectiveScopeModal } from './ObjectiveScopeModal';
import { findMatchedScenarioIndex, getScenarioHexColor } from './utils';

type ObjectivesTableProps = {
	objectives: ObjectivesDataType;
	defaultScenarios: ScenariosDataType;
	isLoading: boolean;
	refetchObjectives: (args?: any) => Promise<any>;
};

// every 3s
const STRATEGIES_UPDATE_INTERVAL = 3 * 1000;

const HEADINGS = [
	{ id: 'dragdrop', label: '', align: 'left' },
	{
		id: 'priority',
		label: 'Priority',

		tooltip:
			'A product can only be in a single objective group. Each product will be assigned to the first group for which it matches the filters.',
		align: 'center',
		className: 'w-[115px]',
	},
	{
		id: 'name',
		label: 'Objective group',
		align: 'left',
		className: 'w-[230px]',
	},
	{
		id: 'actions',
		label: '',
		align: 'left',
		className: 'w-[100px]',
	},
	{
		id: 'objective_sliders',
		label: 'Markdown intensity',
		align: 'left',
	},
	{
		id: 'scope',
		label: 'Scope',
		align: 'left',
		className: 'w-20 2xl:w-[550px]',
	},
] as const;
type ColumnIdType = typeof HEADINGS[number]['id'];

type CustomValuesStateType = {
	[objectiveId: string]: {
		[strategyId: string]: GraphData;
	};
};

type CustomValuesAction =
	| { type: 'set'; objectiveId: string; strategyId: string; value: GraphData }
	| { type: 'reset'; initialData: ObjectivesDataType };

const updateScopeTooltip = (
	headings: typeof HEADINGS,
	newTooltip: React.ReactNode
) => {
	const newHeadings = cloneDeep(headings) as any;
	const found = newHeadings.find((item: any) => item.id === 'scope');
	if (found) {
		found.tooltip = newTooltip;
	}

	return newHeadings;
};

const initCustomValues = (
	initialData: ObjectivesDataType
): CustomValuesStateType => {
	/* eslint-disable no-param-reassign */
	return initialData.reduce<CustomValuesStateType>((acc, objective) => {
		acc[objective.id] = (objective?.strategy_objectives ?? []).reduce<
			CustomValuesStateType[string]
		>((accStra, row) => {
			accStra[row.strategy_id] = {
				max: row.max_residual_value!,
				min: row.min_residual_value!,
				sell: row.sell_through_target!,
			};
			return accStra;
		}, {});
		return acc;
	}, {});
	/* eslint-enable no-param-reassign */
};

const customValueReducer = (
	state: CustomValuesStateType,
	action: CustomValuesAction
): CustomValuesStateType => {
	switch (action.type) {
		case 'set': {
			const { objectiveId, strategyId, value } = action;
			return {
				...state,
				[objectiveId]: {
					...state[objectiveId],
					[strategyId]: value,
				},
			};
		}
		case 'reset': {
			return initCustomValues(action.initialData);
		}
		default: {
			// unexpected
		}
	}
	return state;
};

const getCellRenderer = ({
	handleDeleteClick,
	handleEditClick,
	defaultScenarios,
}: {
	handleDeleteClick: (id: string) => void;
	handleEditClick: (row: ObjectivesDataType[number]) => void;
	defaultScenarios: ScenariosDataType;
}) => {
	return (row: ObjectivesDataType[number], columnId: ColumnIdType) => {
		const isEditDisabled = row.changeable === false;

		switch (true) {
			case columnId === 'scope': {
				return (
					<div className="w-48">
						<Badge className="ml-3 bg-ca-purple-bg-2">
							<Product className="h-3 w-auto mr-2" /> {row.products_count}
						</Badge>
					</div>
				);
			}

			case columnId === 'objective_sliders': {
				// TODO fix types
				return (
					<ObjectivePresetSlider
						mode="readonly"
						options={defaultScenarios}
						value={(row.strategy_objectives as any) ?? []}
					/>
				);
			}

			case columnId === 'actions': {
				return (
					<KebabMenu
						options={[
							{
								Icon: EditIcon,
								label: 'Edit',
								onClick: () => {
									handleEditClick(row);
								},
								disabled: isEditDisabled,
							},
							{
								Icon: Trash,
								label: 'Delete',
								hasSafety: true,
								onClick: () => {
									handleDeleteClick(row.id);
								},
							},
						]}
					/>
				);
			}

			case columnId === 'priority': {
				return <span>{row.changeable ? row.priority : '-'}</span>;
			}

			default: {
				return (
					<span className="text-black text-sm">
						{isValidKey(columnId, row) ? String(row[columnId]) : ''}
					</span>
				);
			}
		}
	};
};

const renderOpenRow = (
	row: ObjectivesDataType[number],
	defaultScenarios: ScenariosDataType,
	updateObjective: (newObjective: ObjectivesDataType[number]) => void,
	customValuesState: CustomValuesStateType,
	dispatchCustomValues: React.Dispatch<CustomValuesAction>
) => {
	return (row.strategy_objectives ?? []).map((strategyForObjective) => {
		const presetSliderValue: PresetSliderValue = {
			max_residual_value: strategyForObjective.max_residual_value!,
			min_residual_value: strategyForObjective.min_residual_value,
			sell_through_target: strategyForObjective.sell_through_target,
		};

		const getChangeHandler = (strategy_id: string) => {
			return ([selectedScenario]: [
				SliderOptionsType[number],
				number,
				PresetSliderValue
			]) => {
				if (!row.strategy_objectives) {
					throw new Error('unexpected error');
				}

				const foundIndex = row.strategy_objectives.findIndex(
					(item) => item.strategy_id === strategy_id
				);
				if (foundIndex === undefined || foundIndex < 0) {
					return;
				}
				const newStrategies = [...row.strategy_objectives];
				newStrategies[foundIndex] = {
					...newStrategies[foundIndex],
					scenario_name: selectedScenario.name,
					max_residual_value: selectedScenario.max_residual_value,
					min_residual_value: selectedScenario.min_residual_value,
					sell_through_target: selectedScenario.sell_through_target,
				};

				const updatedRow: typeof row = {
					...row,
					strategy_objectives: newStrategies,
					filters: [...(row.filters ?? [])],
				};
				updateObjective(updatedRow);
				dispatchCustomValues({
					type: 'set',
					objectiveId: updatedRow.id,
					strategyId: strategy_id,
					value: {
						max: selectedScenario.max_residual_value,
						min: selectedScenario.min_residual_value!,
						sell: selectedScenario.sell_through_target!,
					},
				});
			};
		};
		const getSaveCustomHandler = (strategy_id: string) => {
			return ([selectedScenario, _, customValue]: [
				SliderOptionsType[number],
				number,
				GraphData
			]) => {
				if (!row.strategy_objectives) {
					throw new Error('unexpected error');
				}

				const foundIndex = row.strategy_objectives.findIndex(
					(item) => item.strategy_id === strategy_id
				);
				const newStrategies = [...row.strategy_objectives];
				newStrategies[foundIndex] = {
					...newStrategies[foundIndex],
					scenario_name: selectedScenario.name,
					max_residual_value: customValue.max,
					min_residual_value: customValue.min,
					sell_through_target: customValue.sell,
				};
				const updatedRow: typeof row = {
					...row,
					strategy_objectives: newStrategies,
					filters: [...(row.filters ?? [])],
				};
				updateObjective(updatedRow);
			};
		};

		const closestScenarioIndex = findMatchedScenarioIndex({
			scenarios: defaultScenarios,
			sliderValue: presetSliderValue,
		});

		const hexColor = getScenarioHexColor(
			defaultScenarios.length > 1
				? closestScenarioIndex / (defaultScenarios.length - 1)
				: 1
		);

		return (
			<tr
				className={cn(CHILD_ROWS_DEFAULT_STYLING.childRowClassName, 'h-16')}
				key={strategyForObjective.strategy_id}
			>
				<td className={CHILD_ROWS_DEFAULT_STYLING.fontClassName} colSpan={4}>
					{strategyForObjective.strategy_name}
				</td>
				<td className={CELL_PADDING_CLASSNAME}>
					<ObjectivePresetSlider
						options={defaultScenarios}
						mode="default"
						value={presetSliderValue}
						onChange={getChangeHandler(strategyForObjective.strategy_id)}
					/>
				</td>
				<td className="h-full ">
					<ResidualValueSettingsButtonAndModal
						title={strategyForObjective.scenario_name}
						hexColor={hexColor}
						onChangeCustom={(newValue) =>
							dispatchCustomValues({
								type: 'set',
								objectiveId: row.id,
								strategyId: strategyForObjective.strategy_id,
								value: newValue,
							})
						}
						onSaveCustom={(customValue) => {
							const scenarioIndex = findMatchedScenarioIndex({
								scenarios: defaultScenarios,
								sliderValue: {
									max_residual_value: customValue.max,
									min_residual_value: customValue.min,
									sell_through_target: customValue.min,
								},
							});
							const matchedScenario = defaultScenarios[scenarioIndex];
							getSaveCustomHandler(strategyForObjective.strategy_id)([
								matchedScenario,
								matchedScenario.intensity_level,
								customValue,
							]);
						}}
						value={customValuesState[row.id][strategyForObjective.strategy_id]}
					/>
				</td>
			</tr>
		);
	});
};

export const ObjectivesTable = ({
	objectives: inputObjectives,
	defaultScenarios,
	refetchObjectives,
	isLoading,
}: ObjectivesTableProps) => {
	const { open } = useModal();
	const { show: showToast } = useToast.getState();

	const [prevObjectives, setPrevObjectives] = useState(inputObjectives);
	// Mutated to mimic optimistic updates
	const [objectives, setObjectives] = useState(inputObjectives);

	const [customValuesState, dispatchCustomValues] = useReducer(
		customValueReducer,
		objectives,
		initCustomValues
	);

	if (prevObjectives !== inputObjectives) {
		dispatchCustomValues({ type: 'reset', initialData: inputObjectives });
		setPrevObjectives(inputObjectives);
		setObjectives(inputObjectives);
	}

	const totalProducts = objectives.reduce(
		(acc, item) => acc + (item.products_count ?? 0),
		0
	);

	const { mutate: updateObjectiveMutation } = useMutation(UPDATE_OBJECTIVE);

	const updateObjectiveOptimistically = useThrottledCallback(
		updateObjectiveMutation,
		STRATEGIES_UPDATE_INTERVAL
	);

	const { mutate: deleteObjective } = useMutation(DELETE_OBJECTIVE, {
		onSuccess: async () => {
			await refetchObjectives();
			showToast(`Success`, {
				type: 'success',
			});
		},
	});

	// TODO this can lead to some updates being lost/ignored
	// need to make sure they are properly persisted
	const { mutate: orderObjectives } = useMutation(ORDER_OBJECTIVES, {
		onSuccess: refetchObjectives,
		onError: refetchObjectives,
	});

	const updateObjective = useEventCallback(
		(newObjective: ObjectivesDataType[number]) => {
			const foundIndex = objectives.findIndex(
				(item) => item.id === newObjective.id
			);
			if (foundIndex < 0) {
				throw new Error('unexpected error');
			}

			updateObjectiveOptimistically(
				pick(newObjective, ['name', 'id', 'strategy_objectives', 'filters'])
			);

			const updatedObjectives = [...objectives];
			updatedObjectives.splice(foundIndex, 1, newObjective);
			setObjectives(updatedObjectives);
			refetchObjectives({ cancelRefetch: true });
		}
	);

	const handleDeleteClick = (objectiveId: string) => {
		deleteObjective(objectiveId);
	};

	const createOnSuccess = async () => {
		await refetchObjectives();
		showToast(`Success`, {
			type: 'success',
		});
	};

	const handleAddObjective = () => {
		const tempId = uuidv4();
		open(
			<ObjectiveScopeModal
				type="create"
				objective={{
					name: 'my objective',
					id: tempId,
					filters: [],
					strategy_objectives: [],
				}}
				onSuccess={createOnSuccess}
			/>,
			'',
			'priority'
		);
	};

	const updateOnSuccess = async () => {
		await refetchObjectives();
		showToast(`Success`, {
			type: 'success',
		});
	};

	const handleEditClick = (objective: ObjectivesDataType[number]) => {
		// TODO fix any
		open(
			<ObjectiveScopeModal
				type="update"
				objective={objective as any}
				onSuccess={updateOnSuccess}
			/>,
			'',
			'priority'
		);
	};

	const handleRowClick = (
		objective: ObjectivesDataType[number],
		{
			target,
			currentTarget,
		}: Parameters<React.MouseEventHandler<HTMLTableRowElement>>[0]
	) => {
		// need to make sure buttons or links can be used normally
		// do nothing if nested button or link is clicked
		if (target instanceof Element && currentTarget instanceof Element) {
			const interactableElement = target.closest('button, a');
			if (interactableElement && currentTarget.contains(interactableElement)) {
				return;
			}
		}

		toggleOpenObjective(objective.id);
	};

	// argument is array of ids
	const handleDrag = (ids: string[]) => {
		// TODO
		orderObjectives(ids);
	};

	const [openObjectiveNames, setOpenObjectiveNames] = useState<Set<string>>(
		new Set()
	);

	const toggleOpenObjective = (objectiveName: string) => {
		setOpenObjectiveNames((old) => {
			const newSet = new Set(old);

			if (newSet.has(objectiveName)) {
				newSet.delete(objectiveName);
				return newSet;
			}
			newSet.add(objectiveName);
			return newSet;
		});
	};

	return (
		<div>
			<div className="flex gap-4 items-center justify-between">
				<Text type="secondary" className="py-10">
					An objective group can be used to determine the markdown intensity for
					specific subsets of your assortment. A higher markdown intensity will
					lead to higher recommended markdowns.
				</Text>
				<span className="relative inline-flex">
					<Button
						size="page-cta"
						className="flex flex-row items-center justify-center font-normal w-[168px] max-w-[168px]"
						onClick={handleAddObjective}
						variant="primary"
					>
						<span className="ml-4 whitespace-nowrap">Add objective group</span>
						<Plus className="h-2.5 w-auto ml-2 mr-4 shrink-0" />
					</Button>
				</span>
			</div>
			<Table
				loading={isLoading}
				itemsLoading={1}
				// TODO fix type
				headings={updateScopeTooltip(
					HEADINGS,
					`${totalProducts} total products`
					// <span className="text-ca-gray-500 flex flex-row items-center">
					// 	<Product className="h-3 w-auto mr-2" />
					// 	{`${totalProducts} total products`}
					// </span>
				)}
				rows={objectives}
				emptyState="No objectives exist. Create one with the button above."
				renderCell={getCellRenderer({
					handleEditClick,
					handleDeleteClick,
					defaultScenarios,
				})}
				className="text-center"
				onDragChange={handleDrag}
				dragDisabled={objectives.reduce((acc, item, idx) => {
					if (!item.changeable) {
						acc.push(idx);
					}
					return acc;
				}, [] as number[])}
				renderOpenTr={(row: ObjectivesDataType[number]) => {
					return renderOpenRow(
						row,
						defaultScenarios,
						updateObjective,
						customValuesState,
						dispatchCustomValues
					);
				}}
				rowKey="id"
				open={Array.from(openObjectiveNames)}
				onRowClick={handleRowClick}
			/>
		</div>
	);
};
