import { StatusInfo } from '../../../strategy/types/strategies';
import { mapOutdatedReasonToCopy } from '../../../strategy/v2/utils';
import { cn } from '../../utils/styles';
import Skeleton from '../Skeleton/Skeleton';
import StatusLine, { StatusLineProps } from './StatusLine';

function mapStatusesToContents(statuses: StatusInfo[]): StatusLineProps | null {
	const outOfDateList = Object.values(statuses).filter(
		(s) => s.status === 'OUTDATED'
	);

	const firstReason = outOfDateList[0];

	if (!firstReason) {
		return null;
	}

	const andMore =
		outOfDateList.length === 1
			? null
			: `(and ${outOfDateList.length - 1} more)`;

	return {
		tooltip: [
			mapOutdatedReasonToCopy(firstReason.outdatedReason),
			andMore,
		].join(' '),
		text: `These results are outdated!`,
		indicator: 'warning',
	};
}

type MultiAlgorithmStatusProps = {
	statuses?: StatusInfo[];
	className?: string;
};

const MultiAlgorithmStatus = ({
	statuses,
	className,
}: MultiAlgorithmStatusProps) => {
	// Hide this component when no status has been received yet
	if (statuses === undefined) {
		return <Skeleton className={cn('h-3 w-32', className)} />;
	}

	const content = mapStatusesToContents(statuses);

	// No status
	if (content === null) {
		return null;
	}

	content.className = className;

	return <StatusLine {...content} />;
};

export default MultiAlgorithmStatus;
