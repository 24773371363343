import { GET_CUMULIO_GLOBAL_SSO } from '../../shared/api/reports';

import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const GlobalDashboard = () => {
	return (
		<>
			<div className="relative py-6">
				<FullCumulioDashboard
					queryKey="cumulio-global-dashboard"
					ssoQuery={GET_CUMULIO_GLOBAL_SSO}
					showLoading={false}
				/>
			</div>
		</>
	);
};

export default GlobalDashboard;
