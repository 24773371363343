import { QueryKey, useQueryClient } from 'react-query';
import Button from '../Button/Button';
import RepeatIcon from '../Icons/Repeat';

type Props =
	| {
			dashboardRef: React.RefObject<any>;
			reloadType: 'reload' | 'refreshData';
	  }
	| {
			dashboardRef: React.RefObject<any>;
			reloadType: 'hardReload';
			queryKeyToReset: QueryKey;
	  };

export type ReloadDashboardType = Props['reloadType'];

const RefreshCumulioDashboardButton: React.FC<Props> = (props) => {
	const queryClient = useQueryClient();

	return (
		<Button
			className="inline-flex items-center"
			variant="primary"
			size="small"
			onClick={() => {
				switch (props.reloadType) {
					case 'hardReload':
						queryClient.resetQueries({ queryKey: props.queryKeyToReset });
						return;
					case 'reload':
						props.dashboardRef.current?.reloadDashboard();
						return;
					case 'refreshData':
						props.dashboardRef.current?.refreshData();
						return;
					default:
						throw new Error(
							'[RefreshCumulioDashboardButton] No reload type given. Fix this'
						);
				}
			}}
		>
			Reload dashboard <RepeatIcon className="h-3.5 ml-2" />
		</Button>
	);
};

export default RefreshCumulioDashboardButton;
