import PropTypes from 'prop-types';

import { cn } from '../../utils/styles';
import Text from '../Text/Text';

const Title = ({ children }) => (
	<Text size="text-base" className="font-bold mb-8">
		{children}
	</Text>
);

const Content = ({ children, className, unstyled = false }) => (
	<div className={cn(!unstyled && 'p-10', className)}>{children}</div>
);

const Actions = ({ children, className = '', unstyled = false }) => (
	<div
		className={cn(
			!unstyled && 'py-5 px-10 rounded-b-[inherit] bg-ca-ghost-white',
			className
		)}
	>
		{children}
	</div>
);

const Root = ({ children, className, unstyled = false }) => {
	return (
		<div
			className={cn(
				!unstyled && 'max-w-lg bg-white rounded-lg shadow-2xl',
				className
			)}
		>
			{children}
		</div>
	);
};

const OnlyChildrenPropTypes = {
	children: PropTypes.node.isRequired,
};

Root.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
Title.propTypes = OnlyChildrenPropTypes;
Content.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
Actions.propTypes = OnlyChildrenPropTypes;

export default { Root, Title, Content, Actions };
