import { FilterMap } from '../components/Filter/FullFilterMenu.types';

function filterMapToSearchParams(filterMap: FilterMap): URLSearchParams {
	return new URLSearchParams([
		...Array.from(filterMap.entries()).map(([id, filterValue]) => [
			'filter',
			`"id":"${id}","value":${JSON.stringify(filterValue.value)}`,
		]),
	]);
}

export default filterMapToSearchParams;
