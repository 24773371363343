/* eslint-disable */
import { useQuery, UseQueryOptions } from 'react-query';
import { constructChannelQueryKey } from '../../../strategy/v2/utils';
import useChannelStore from '../../data/channels';
import { GET_ALL_RUN_STATUSES } from '../strategies-v2';

const ALL_LATEST_RUN_STATUSES_QUERY_KEY = [
	'algorithm_status',
	'all_strategies&most_recent',
];
export const useAllLatestRunStatusesQueryKey = () => {
	const { activeChannel } = useChannelStore();

	return constructChannelQueryKey(
		activeChannel,
		ALL_LATEST_RUN_STATUSES_QUERY_KEY
	);
};

export type UseAllLatestRunStatusesQueryParams =
	| Pick<
			// :bulb: `Pick` because some properties would change the return type of the query to `unknown`
			UseQueryOptions,
			| 'cacheTime'
			| 'refetchInterval'
			| 'enabled'
			| 'staleTime'
			| 'refetchIntervalInBackground'
	  >
	| undefined;

export const useAllLatestRunStatusesQuery = (
	options: UseAllLatestRunStatusesQueryParams = {
		refetchInterval: 30_000,
		refetchIntervalInBackground: true,
	}
) => {
	const queryKeyWitchChannel = useAllLatestRunStatusesQueryKey();

	return useQuery({
		queryKey: queryKeyWitchChannel,
		queryFn: GET_ALL_RUN_STATUSES,
		...options,
	});
};
