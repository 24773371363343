import {
	LIST_ALL_LATEST_UPLOADS,
	LIST_UPLOADERS,
} from '../../shared/api/uploads';
import Download from '../../shared/components/Icons/Download';
import Info from '../../shared/components/Icons/Info';
import MagnifyingGlass from '../../shared/components/Icons/MagnifyingGlass';
import Table from '../../shared/components/NewTable/Table';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import useChannelQuery from '../../shared/hooks/useChannelQuery';
import { AllLatestUploadsDataType, UploadersDataType } from '../types/uploads';
import UploadsDate from './UploadsDate';
import UploadsDownloadButton from './UploadsDownloadButton';
import UploadsStatusBadge from './UploadsStatusBadge';
import UploadButton from './UploadsUploadButton';

type UploadersWithUploads = UploadersDataType[number] & {
	latest?: AllLatestUploadsDataType['latest'][string];
	latest_successful?: AllLatestUploadsDataType['latest_successful'][string];
};

const renderCell = (
	row: UploadersWithUploads,
	columnId: string,
	refetchLatestUploads: () => void
) => {
	switch (columnId) {
		case 'Info':
			return (
				<Tooltip content={row.uploader_description}>
					<span>
						<Info className="w-4 text-gray-400" />
					</span>
				</Tooltip>
			);
		case 'Name':
			return <span>{row.uploader_label}</span>;
		case 'Date':
			return <UploadsDate date={row.latest_successful?.created_at} />;
		case 'Status':
			return (
				<UploadsStatusBadge
					status={row.latest?.status}
					validationErrors={row.latest?.validation_errors}
					createdAt={row.latest?.created_at}
					updatedAt={row.latest?.updated_at}
				/>
			);
		case 'Buttons':
			return (
				<div className="flex flex-row items-center gap-2">
					<UploadsDownloadButton
						type="example-file"
						uploaderId={row.uploader_id}
						tooltip="Download example file"
					>
						<MagnifyingGlass className="w-4" />
					</UploadsDownloadButton>
					<UploadsDownloadButton
						disabled={row.latest_successful == null}
						type="processed-file"
						uploaderId={row.uploader_id}
						tooltip="Download last successful upload"
					>
						<Download className="w-4" />
					</UploadsDownloadButton>
					<UploadButton
						className="px-2"
						uploaderId={row.uploader_id}
						refetchLatestUploads={refetchLatestUploads}
					/>
				</div>
			);
		default:
			return null;
	}
};

const UploadsTable = () => {
	const { isLoading: uploadersLoading, data: uploaders } = useChannelQuery<
		UploadersDataType,
		unknown,
		UploadersDataType
	>(['uploaders'], () => LIST_UPLOADERS(), {
		staleTime: 5 * 60 * 1000,
	});

	const {
		isLoading: uploadsLoading,
		data: uploads,
		refetch: refetchLatestUploads,
	} = useChannelQuery<
		AllLatestUploadsDataType,
		unknown,
		AllLatestUploadsDataType
	>(['all-latest-uploads'], () => LIST_ALL_LATEST_UPLOADS(), {
		staleTime: 1 * 60 * 1000,
		refetchInterval: 15_000,
		refetchIntervalInBackground: true,
	});

	const populatedUploaders = uploaders?.map((u) => ({
		...u,
		latest: uploads?.latest[u.uploader_id],
		latest_successful: uploads?.latest_successful[u.uploader_id],
	}));

	return (
		<Table
			loading={uploadersLoading || uploadsLoading}
			rows={populatedUploaders}
			renderCell={(r, c) => renderCell(r, c, refetchLatestUploads)}
			rowKey="uploader_id"
			headings={[
				{
					id: 'Info',
				},
				{
					id: 'Name',
					label: 'Name',
				},
				{
					id: 'Date',
					label: 'Last successful upload',
				},
				{
					id: 'Status',
				},
				{
					id: 'Buttons',
				},
			]}
		/>
	);
};

export default UploadsTable;
