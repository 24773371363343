import {
	GetLocationsFiltersResponseDTO,
	GetLocationsIdsResponseDTO,
	GetLocationsResponseDTO,
	GetLocationsSchemaResponseDTO,
} from '../models/schema';
import { vulcanApi as api } from './api';

export const GET_LOCATIONS = (
	searchParams: URLSearchParams
): Promise<GetLocationsResponseDTO> => {
	return api.get('api/v1/locations', { searchParams }).json();
};

export const GET_LOCATIONS_SCHEMA =
	(): Promise<GetLocationsSchemaResponseDTO> => {
		return api.get('api/v1/locations/schema').json();
	};

export const GET_LOCATIONS_FILTERS =
	(): Promise<GetLocationsFiltersResponseDTO> => {
		return api.get('api/v1/locations/filters').json();
	};

export const GET_LOCATIONS_IDS = (
	searchParams: URLSearchParams
): Promise<GetLocationsIdsResponseDTO> => {
	return api.get('api/v1/locations/ids', { searchParams }).json();
};
