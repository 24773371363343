import { Transition } from '@headlessui/react';
import { FunctionComponent, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useKeyPress from '../../hooks/useKeyPress';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Logo from '../Branding/Logo';
import TriangleDownIcon from '../Icons/TriangleDown';

interface Props {
	activePlatform: string;
}

const IS_VULCAN_AVAILABLE: boolean = window._ENV_.REACT_APP_VULCAN_ENABLED;
const IS_STRATOS_AVAILABLE: boolean = window._ENV_.REACT_APP_STRATOS_ENABLED;
const IS_SWITCHABLE: boolean = IS_VULCAN_AVAILABLE && IS_STRATOS_AVAILABLE;

const PlatformSelect: FunctionComponent<Props> = ({ activePlatform }) => {
	const ref = useRef(null);
	const [isVisible, setIsVisible] = useState(false);
	const history = useHistory();

	useOnClickOutside(ref, () => setIsVisible(false));
	useKeyPress('Escape', () => setIsVisible(false));

	if (IS_SWITCHABLE) {
		return (
			<div className="relative inline-block text-left" ref={ref}>
				<button
					onClick={() => setIsVisible(!isVisible)}
					className="flex items-center"
				>
					<div className="flex flex-col items-center">
						<div className="flex items-center">
							<Logo className="w-12 h-full" />
							<TriangleDownIcon className="shrink-0 text-ca-gray ml-2 h-2 pointer-events-none" />
						</div>
						<p className="text-sm text-ca-purple uppercase">{activePlatform}</p>
					</div>
				</button>
				<Transition show={isVisible}>
					<div className="origin-top-right absolute left-0 mt-2 min-w-full w-auto py-1 bg-white rounded-lg shadow-ca cursor-pointer overflow-auto focus:outline-none z-20">
						<Link to="/strategy">
							<div className="flex justify-between items-center text-ca-black px-4 py-2 text-sm transition-colors hover:bg-ca-silver">
								<span>Markdown</span>
							</div>
						</Link>
						<Link to="/stock">
							<div className="flex justify-between items-center text-ca-black px-4 py-2 text-sm transition-colors hover:bg-ca-silver">
								<span>Stock</span>
							</div>
						</Link>
					</div>
				</Transition>
			</div>
		);
	}

	return (
		<div
			role="button"
			tabIndex={0}
			className="relative cursor-pointer"
			onClick={() => history.push(window._ENV_.REACT_APP_DEFAULT_PATH)}
			onKeyDown={() => history.push(window._ENV_.REACT_APP_DEFAULT_PATH)}
		>
			<Logo className="w-12 h-full" />
		</div>
	);
};

export default PlatformSelect;
