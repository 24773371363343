import clsx from 'clsx';
import { PropsWithChildren } from 'react';

const StackedGrid = (props: PropsWithChildren & { className?: string }) => {
	return (
		<div
			className={clsx(
				'grid grid-cols-1 grid-rows-1 *:col-start-1 *:row-start-1',
				props.className
			)}
		>
			{props.children}
		</div>
	);
};

export default StackedGrid;
