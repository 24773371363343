import Checkmark from '../../shared/components/Icons/Checkmark';
import Cross from '../../shared/components/Icons/Cross';

type UploadsValidationTooltipCategoryProps = {
	successText: string;
	failText: string;
	errors?: string[];
};

export default function UploadsValidationTooltipCategory({
	successText,
	failText,
	errors,
}: UploadsValidationTooltipCategoryProps) {
	if (errors === undefined || errors.length === 0) {
		return (
			<li className="flex flex-row items-center gap-2">
				<Checkmark className="w-4 text-ca-green" />
				<span>{successText}</span>
			</li>
		);
	}

	return (
		<>
			<li className="flex flex-row items-center gap-2">
				<Cross className="w-4 text-ca-red" />
				<span>{failText}</span>
			</li>
			<ul className="list-disc list-inside text-xs text-ca-gray-500 ml-6 pb-2">
				{errors.map((e) => (
					<li key={e}>{e}</li>
				))}
			</ul>
		</>
	);
}
