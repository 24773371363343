import { FunctionComponent } from 'react';
import {
	FixBrokenSizeChartsSecondaryObjective,
	PenalizeBrokenSizeChartsSecondaryObjective,
	SecondaryObjective,
	SecondaryObjectiveType,
} from '../../../shared/models/configuration';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import ScopeDescription from './ScopeDescription';

type WithIds<Type> = Type & {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
};

interface Props {
	secondaryObjective: SecondaryObjective;
	isInProgress?: boolean;
	isProposal?: boolean;
}

export const NoSecondaryObjectives: FunctionComponent = () => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<p className="text-ca-gray">
			No secondary objectives. Focus everything on attaining as much revenue as
			possible.
		</p>
	</div>
);

const FixBrokenSizeChartsSecondaryObjectiveOption: FunctionComponent<
	WithIds<FixBrokenSizeChartsSecondaryObjective>
> = ({ scope, weight, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between items-center">
			<p className="text-ca-gray">Attempt to fix broken size charts.</p>
			<div className="flex gap-4 margin-auto w-24">
				<p className="text-ca-gray">Weight: {weight}</p>
			</div>
		</div>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const PenalizeBrokenSizeChartsSecondaryObjectiveOption: FunctionComponent<
	WithIds<PenalizeBrokenSizeChartsSecondaryObjective>
> = ({ scope, weight, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between items-center">
			<p className="text-ca-gray">Attempt to penalize broken size charts</p>
			<div className="flex gap-4 margin-auto w-24">
				<p className="text-ca-gray">Weight: {weight}</p>
			</div>
		</div>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const SecondaryObjectiveOption: FunctionComponent<WithIds<Props>> = ({
	secondaryObjective,
	...ids
}) => {
	const renderSecondaryObjectiveOption = () => {
		switch (secondaryObjective.type) {
			case SecondaryObjectiveType.FixBrokenSizeCharts:
				return (
					<FixBrokenSizeChartsSecondaryObjectiveOption
						{...secondaryObjective}
						{...ids}
					/>
				);
			case SecondaryObjectiveType.PenalizeBrokenSizeCharts:
				return (
					<PenalizeBrokenSizeChartsSecondaryObjectiveOption
						{...secondaryObjective}
						{...ids}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div className="flex gap-3 items-center">
			{renderSecondaryObjectiveOption()}
			{/* {isInProgress && ( */}
			{/* 	<p className="text-ca-gray max-w-sm"> */}
			{/* 		Calculating the effect this secondary objective has on your revenue */}
			{/* 		gain... */}
			{/* 	</p> */}
			{/* )} */}
			{/* {isProposal && ( */}
			{/* 	<p className="text-ca-gray max-w-sm"> */}
			{/* 		Due to this objective you miss out on{' '} */}
			{/* 		<strong className="text-ca-red">{`~${secondaryObjective.estimatedMissedRevenue?.toLocaleString( */}
			{/* 			'en-GB', */}
			{/* 			{ */}
			{/* 				style: 'currency', */}
			{/* 				currency: 'EUR', */}
			{/* 				currencySign: 'standard', */}
			{/* 				currencyDisplay: 'symbol', */}
			{/* 			} */}
			{/* 		)}`}</strong>{' '} */}
			{/* 		revenue. */}
			{/* 	</p> */}
			{/* )} */}
		</div>
	);
};

export default SecondaryObjectiveOption;
