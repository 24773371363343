import { useMutation, UseMutationOptions } from 'react-query';
import { constructChannelQueryKey } from '../../../strategy/v2/utils';
import useChannelStore from '../../data/channels';
import { COPY_BUSINESS_RULE } from '../business-rules';

const COPY_BUSINESS_RULE_MUTATION_KEY = ['businessRuleCopy'];

export const useBusinessRuleCopy = (
	id: string,
	onSuccess: UseMutationOptions['onSuccess']
) => {
	const { activeChannel } = useChannelStore();
	return useMutation({
		mutationKey: constructChannelQueryKey(activeChannel, [
			...COPY_BUSINESS_RULE_MUTATION_KEY,
			id,
		]),
		mutationFn: async () => {
			return COPY_BUSINESS_RULE(id);
		},
		onSuccess,
	});
};
