import { useMemo } from 'react';
import { useMarkdownBucketsQuery } from '../../shared/api/queryHooks/useMarkdownBucketsQuery';
import useMarkdownTransferMutation from '../../shared/api/queryHooks/useMarkdownTransferMutation';
import { GET_RECOMMENDATIONS_FILTERS } from '../../shared/api/recommendations';
import { FilterMap } from '../../shared/components/Filter/FullFilterMenu.types';
import useStrategyStore from '../../shared/data/strategies';
import useChannelQuery from '../../shared/hooks/useChannelQuery';
import useModal from '../../shared/hooks/useModal';
import filterMapToSearchParams from '../../shared/utils/filters';
import MarkdownTransferModal from '../components/MarkdownTransferModal';

interface Result {
	isPossible: boolean;
	transferInProgress: boolean;
	openModal: (...args: any[]) => any;
	closeModal: () => any;
}

const useMarkdownTransferModal = (
	filters: FilterMap,
	cumulRef: React.RefObject<any>
): Result => {
	const { activeStrategy } = useStrategyStore();
	// This is notably the same query (with the same queryKey) as executed elsewhere.
	const filtersQuery = useChannelQuery(
		['recommendations-filters', activeStrategy],
		() => GET_RECOMMENDATIONS_FILTERS(activeStrategy),
		{ staleTime: 1000 * 60 * 5 }
	);

	if (activeStrategy === null) {
		throw new Error('Please create a strategy first');
	}

	useMarkdownBucketsQuery({
		strategy_id: activeStrategy,
		filters: filterMapToSearchParams(filters),
	});
	const { open: openModal, close: closeModal } = useModal();

	const { mutate: transferFunction, isLoading: transferInProgress } =
		useMarkdownTransferMutation();

	const isPossible = useMemo(() => {
		if (filtersQuery.isSuccess && Array.isArray(filtersQuery?.data)) {
			const toFind = ['markdown_type', 'overwrite_markdown'];
			(filtersQuery.data ?? []).forEach((filter: any) => {
				const id = toFind.findIndex((filterId) => filterId === filter.id);
				if (id !== -1) {
					delete toFind[id];
				}
			});
			return true;
		}
		return true;
	}, [filtersQuery.isSuccess]);

	return {
		isPossible,
		transferInProgress,
		openModal: isPossible
			? () =>
					openModal(
						<MarkdownTransferModal
							transferMarkdownFn={transferFunction}
							activeFilters={filters}
							cumulRef={cumulRef}
						/>
					)
			: () => {},
		closeModal,
	};
};

export default useMarkdownTransferModal;
