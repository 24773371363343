import PropTypes from 'prop-types';

const Minus = ({ className }) => (
	<svg
		width="10"
		height="2"
		viewBox="0 0 10 2"
		fill="none"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M1 1H9" stroke="#7000FF" strokeWidth={1.2} strokeLinecap="round" />
	</svg>
);

Minus.propTypes = {
	className: PropTypes.string,
};

Minus.defaultProps = {
	className: '',
};

export default Minus;
