import { MutationDTO } from '../models/API';
import {
	CreateReportBodyDTO,
	CreateReportParametersDTO,
	CreateReportResponseDTO,
	GetReportConstraintsResponseDTO,
	GetReportDashboardParametersDTO,
	GetReportResponseDTO,
	GetReportSecondaryObjectivesResponseDTO,
	GetReportWithLiveStatusParametersDTO,
	GetReportWithLiveStatusResponseDTO,
	InventoryAllocationDTO,
	ReportDTO,
	UpdateReportBodyDTO,
	UpdateReportParametersDTO,
	UpdateReportResponseDTO,
} from '../models/schema';
import { vulcanApi as api } from './api';

export const GET_INVENTORY_ALLOCATION_REPORT = (
	inventoryAllocationId: InventoryAllocationDTO['id'],
	id: ReportDTO['id']
): Promise<GetReportResponseDTO> => {
	return api.get(`api/v1/reports/${inventoryAllocationId}/${id}`).json();
};

export const GET_INVENTORY_ALLOCATION_REPORT_CONSTRAINTS =
	(): Promise<GetReportConstraintsResponseDTO> => {
		return api.get('api/v1/reports/constraints').json();
	};

export const GET_INVENTORY_ALLOCATION_REPORT_SECONDARY_OBJECTIVES =
	(): Promise<GetReportSecondaryObjectivesResponseDTO> => {
		return api.get('api/v1/reports/secondary_objectives').json();
	};

export const CREATE_INVENTORY_ALLOCATION_REPORT: MutationDTO<
	CreateReportParametersDTO,
	CreateReportBodyDTO,
	CreateReportResponseDTO
> = ({ allocation_id, ...payload }) => {
	return api.post(`api/v1/reports/${allocation_id}`, { json: payload }).json();
};

export const UPDATE_INVENTORY_ALLOCATION_REPORT: MutationDTO<
	UpdateReportParametersDTO,
	UpdateReportBodyDTO,
	UpdateReportResponseDTO
> = ({ report_id, allocation_id, ...payload }) => {
	return api
		.patch(`api/v1/reports/${allocation_id}/${report_id}`, { json: payload })
		.json();
};

export const GET_INVENTORY_ALLOCATION_REPORT_DASHBOARD = (
	allocationId: GetReportDashboardParametersDTO['allocation_id'],
	id: GetReportDashboardParametersDTO['report_id']
) => {
	return api
		.get(`api/v1/dashboards/proposed_allocation/${allocationId}/${id}/sso`)
		.json();
};

export const GET_INVENTORY_ALLOCATION_MOVEMENTS_DASHBOARD = (
	allocationId: GetReportDashboardParametersDTO['allocation_id'],
	id: GetReportDashboardParametersDTO['report_id']
) => {
	return api
		.get(`api/v1/dashboards/proposed_movements/${allocationId}/${id}/sso`)
		.json();
};

export const GET_REPORT_WITH_LIVE_STATUS = (
	inventoryAllocationId: GetReportWithLiveStatusParametersDTO['allocation_id'],
	id: GetReportWithLiveStatusParametersDTO['report_id']
): Promise<GetReportWithLiveStatusResponseDTO> => {
	return api
		.get(`api/v1/engine/retrieve_updated_status/${inventoryAllocationId}/${id}`)
		.json();
};
