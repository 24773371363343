import { MutationDTO } from '../models/API';
import { InventoryAllocation } from '../models/inventoryAllocation';
import {
	CreateInventoryAllocationBodyDTO,
	CreateInventoryAllocationParametersDTO,
	CreateInventoryAllocationResponseDTO,
	DeleteInventoryAllocationBodyDTO,
	DeleteInventoryAllocationParametersDTO,
	DeleteInventoryAllocationResponseDTO,
	GetInventoryAllocationResponseDTO,
	GetInventoryAllocationsResponseDTO,
	SubmitInventoryAllocationBodyDTO,
	SubmitInventoryAllocationParametersDTO,
	SubmitInventoryAllocationResponseDTO,
	UpdateInventoryAllocationBodyDTO,
	UpdateInventoryAllocationParametersDTO,
	UpdateInventoryAllocationResponseDTO,
} from '../models/schema';
import { vulcanApi as api } from './api';

export interface InventoryAllocationsQueryResult {
	items: InventoryAllocation[];
}

export const GET_INVENTORY_ALLOCATIONS = (
	searchParams: URLSearchParams
): Promise<GetInventoryAllocationsResponseDTO> => {
	return api.get('api/v1/allocations', { searchParams }).json();
};

export const GET_INVENTORY_ALLOCATION = (
	id: InventoryAllocation['id']
): Promise<GetInventoryAllocationResponseDTO> => {
	return api.get(`api/v1/allocations/${id}`).json();
};

export const CREATE_INVENTORY_ALLOCATION: MutationDTO<
	CreateInventoryAllocationParametersDTO,
	CreateInventoryAllocationBodyDTO,
	CreateInventoryAllocationResponseDTO
> = (payload) => {
	return api.post('api/v1/allocations/', { json: payload }).json();
};

export const DELETE_INVENTORY_ALLOCATION: MutationDTO<
	DeleteInventoryAllocationParametersDTO,
	DeleteInventoryAllocationBodyDTO,
	DeleteInventoryAllocationResponseDTO
> = ({ allocation_id }) => {
	return api.delete(`api/v1/allocations/${allocation_id}`).json();
};

export const UPDATE_INVENTORY_ALLOCATION: MutationDTO<
	UpdateInventoryAllocationParametersDTO,
	UpdateInventoryAllocationBodyDTO,
	UpdateInventoryAllocationResponseDTO
> = ({ allocation_id, ...payload }) => {
	return api
		.put(`api/v1/allocations/${allocation_id}`, {
			json: payload,
		})
		.json();
};

export const SUBMIT_INVENTORY_ALLOCATION: MutationDTO<
	SubmitInventoryAllocationParametersDTO,
	SubmitInventoryAllocationBodyDTO,
	SubmitInventoryAllocationResponseDTO
> = ({ report_id, allocation_id }) => {
	return api
		.post(`api/v1/allocations/${allocation_id}/reports/${report_id}`)
		.json();
};
