import dayjs from 'dayjs';
import * as React from 'react';
import Button from '../../shared/components/Button/Button';
import {
	Calendar,
	CalendarProps,
} from '../../shared/components/Calendar/Calendar';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '../../shared/components/Popover/Popover';
import {
	DayjsInstanceType,
	getWeekPeriod,
	today,
} from '../../shared/utils/dates';
import { SelectableDatesType } from '../types/seasons';

type SelectedWeekType = {
	from: Date;
	to: Date;
};

type WeekPickerProps = React.PropsWithChildren<{
	defaultValue?: Date;
	selectableDates?: SelectableDatesType;
	onSave?: (date?: DayjsInstanceType) => void;
}> &
	Pick<CalendarProps, 'numberOfMonths' | 'showOutsideDays' | 'fixedWeeks'>;
export const WeekPicker = ({
	children,
	defaultValue,
	selectableDates,
	onSave,
	fixedWeeks = false,
	showOutsideDays = false,
	numberOfMonths = 1,
}: WeekPickerProps) => {
	const [open, setOpen] = React.useState(false);
	const [selectedWeek, setSelectedWeek] = React.useState<
		SelectedWeekType | undefined
	>(() => {
		if (!defaultValue) {
			return defaultValue;
		}
		const [start, end] = getWeekPeriod(defaultValue);
		return { from: start.toDate(), to: end.toDate() };
	});
	const [hoveredWeek, setHoveredWeek] = React.useState<
		SelectedWeekType | undefined
	>();
	const [selectedDay, setSelectedDay] = React.useState(defaultValue);
	const [month, setMonth] = React.useState(defaultValue);

	const handleDateClick = (date: Date) => {
		const [startDate, endDate] = getWeekPeriod(date);

		setSelectedWeek({ from: startDate.toDate(), to: endDate.toDate() });
		setSelectedDay(date);
	};

	const handleDateMouseEnter = (date: Date) => {
		const [startDate, endDate] = getWeekPeriod(date);
		// TODO: check if hovered date is not in selected week -> if it is, do not set hovered week, so that the hovered week styling does not override the selected week styling.

		setHoveredWeek({ from: startDate.toDate(), to: endDate.toDate() });
	};

	const handleDateMouseLeave = () => {
		setHoveredWeek(undefined);
	};

	const handleThisWeekClick = () => {
		const now = today();
		const [startDate, endDate] = getWeekPeriod(now);

		setSelectedWeek({ from: startDate.toDate(), to: endDate.toDate() });
		setSelectedDay(now.toDate());
		setMonth(now.toDate());
	};

	const handleSaveClick = () => {
		onSave?.(selectedDay ? dayjs(selectedDay) : undefined);
		setOpen(false);
	};

	let disabledDates: CalendarProps['disabled'];
	if (selectableDates) {
		disabledDates = selectableDates.disabledDates
			? selectableDates.disabledDates.map(([start, end]) => ({
					from: start.toDate(),
					to: end.toDate(),
			  }))
			: [];
		if (selectableDates.lastDay) {
			disabledDates.push({ after: selectableDates.lastDay.toDate() });
		}
		if (selectableDates?.firstDay) {
			disabledDates.push({ before: selectableDates.firstDay.toDate() });
		}
	}

	const modifiers = {
		...(hoveredWeek && { hoveredWeek }),
		...(selectedWeek && { selectedWeek }),
	};

	const modifiersClassNames = {
		hoveredWeek: 'bg-ca-purple text-white opacity-50 transition-colors',
		selectedWeek: 'bg-ca-purple text-white opacity-100 transition-colors',
		selectedDay: '',
		today: 'border border-gray-400 rounded-md',
	};
	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>{children}</PopoverTrigger>
			<PopoverContent className="w-auto p-4 round" align="start">
				<div className="-mx-2">
					<Calendar
						showWeekNumber
						mode="single"
						selected={selectedDay}
						modifiers={modifiers}
						modifiersClassNames={modifiersClassNames}
						onDayClick={handleDateClick}
						onDayMouseEnter={handleDateMouseEnter}
						onDayMouseLeave={handleDateMouseLeave}
						month={month}
						onMonthChange={setMonth}
						initialFocus
						disabled={disabledDates}
						weekStartsOn={1}
						fixedWeeks={fixedWeeks}
						showOutsideDays={showOutsideDays}
						numberOfMonths={numberOfMonths}
					/>
				</div>
				{/* <div>{`Selected: ${selectedDay?.toDateString()}`}</div>
				<div>{`${selectedWeek?.from.toDateString()} - ${selectedWeek?.to.toDateString()}`}</div>
				<div>{`${hoveredWeek?.from.toDateString()} - ${hoveredWeek?.to.toDateString()}`}</div> */}
				<div className="flex flex-row justify-between">
					<Button
						variant="secondary"
						size="small"
						className="flex flex-row items-center gap-3 whitespace-nowrap"
						onClick={handleThisWeekClick}
					>
						This week
					</Button>
					<Button
						variant="primary"
						size="small"
						className="flex flex-row items-center gap-3 whitespace-nowrap"
						onClick={handleSaveClick}
					>
						Select
					</Button>
				</div>
			</PopoverContent>
		</Popover>
	);
};
