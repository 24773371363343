import { ComponentProps } from 'react';
import Tooltip from '../Tooltip/Tooltip';

type MissingValueProps = {
	tooltip?: ComponentProps<typeof Tooltip>['content'];
};

const MissingValue = (props: MissingValueProps) => {
	let jsx = (
		<span className="border border-red-500 rounded font-mono px-1 py-0.5 text-xs text-ca-black">
			<span className="text-ca-destructive mr-1">?</span>
			Missing value
		</span>
	);

	if (props.tooltip) {
		jsx = <Tooltip content={props.tooltip}>{jsx}</Tooltip>;
	}
	return jsx;
};

export default MissingValue;
