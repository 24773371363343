import { FunctionComponent } from 'react';
import { UseFieldArrayAppend } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReportMapper from '../../../domains/reports/ReportMapper';
import { GET_INVENTORY_ALLOCATION_REPORT_SECONDARY_OBJECTIVES } from '../../../shared/api/inventory-allocation-reports';
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import {
	Configuration,
	SecondaryObjective,
	SecondaryObjectiveType,
} from '../../../shared/models/configuration';
import {
	GetReportSecondaryObjectivesParametersDTO,
	GetReportSecondaryObjectivesResponseDTO,
} from '../../../shared/models/schema';

interface Props {
	configuredTypes: SecondaryObjectiveType[];
	append: UseFieldArrayAppend<Configuration, 'secondaryObjectives'>;
}

const LABEL: Record<SecondaryObjectiveType, string> = {
	[SecondaryObjectiveType.FixBrokenSizeCharts]:
		'Attempt to fix broken size charts.',
	[SecondaryObjectiveType.PenalizeBrokenSizeCharts]:
		'Penalize broken size charts.',
	[SecondaryObjectiveType.Unknown]: 'Unknown secondary objective.',
};

const SECONDARY_OBJECTIVES: Record<SecondaryObjectiveType, SecondaryObjective> =
	{
		[SecondaryObjectiveType.FixBrokenSizeCharts]: {
			type: SecondaryObjectiveType.FixBrokenSizeCharts,
			scope: { locations: [], products: [] },
			estimatedMissedRevenue: 0,
			weight: 1,
		},
		[SecondaryObjectiveType.PenalizeBrokenSizeCharts]: {
			type: SecondaryObjectiveType.PenalizeBrokenSizeCharts,
			scope: { locations: [], products: [] },
			estimatedMissedRevenue: 0,
			weight: 1,
		},
		[SecondaryObjectiveType.Unknown]: {
			type: SecondaryObjectiveType.FixBrokenSizeCharts,
			scope: { locations: [], products: [] },
			estimatedMissedRevenue: 0,
			weight: 1,
		},
	};

const isNotUnknown = (type: SecondaryObjectiveType): boolean =>
	type !== SecondaryObjectiveType.Unknown;

const SecondaryObjectivePicker: FunctionComponent<Props> = ({
	append,
	configuredTypes,
}) => {
	const { isLoading, data } = useQuery<
		GetReportSecondaryObjectivesParametersDTO,
		unknown,
		GetReportSecondaryObjectivesResponseDTO
	>(
		'secondary-objectives',
		GET_INVENTORY_ALLOCATION_REPORT_SECONDARY_OBJECTIVES
	);
	const mapper = new ReportMapper();
	const secondaryObjectiveTypes = (data?.items || [])
		.map(({ secondary_objective_type }) => secondary_objective_type)
		.map(mapper.secondaryObjectiveType.toInternal);

	if (isLoading) {
		return <p>Loading...</p>;
	}

	const isNotAlreadyConfigured = (type: SecondaryObjectiveType): boolean =>
		!configuredTypes.some((configuredType) => configuredType === type);

	const onChange = (type: SecondaryObjectiveType): void => {
		append(SECONDARY_OBJECTIVES[type]);
	};

	const appendableTypes = secondaryObjectiveTypes
		.filter(isNotUnknown)
		.filter(isNotAlreadyConfigured);

	if (appendableTypes.length === 0) {
		return null;
	}

	return (
		<Dropdown
			onChange={onChange}
			className="max-w-sm w-full -ml-4"
			placeholder="Add a secondary objective."
			options={appendableTypes.map((type) => ({
				label: LABEL[type],
				value: type,
			}))}
		/>
	);
};

export default SecondaryObjectivePicker;
