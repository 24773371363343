import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { GET_CHANNELS } from '../api/channels';
import useChannelStore from '../data/channels';
import useModal from '../hooks/useModal';

import Modal from '../components/Modal/Modal';
import CircularProgress from '../components/Progress/CircularProgress';
import Text from '../components/Text/Text';
import useStrategyStore from '../data/strategies';

const ChannelContext = createContext();

const ChannelProvider = ({ children }) => {
	const [counter, setCounter] = useState(0);
	const { setChannels, activeChannel, setActiveChannel } = useChannelStore();
	const { reset: resetStrategyStore } = useStrategyStore();
	const [prevActiveChannel, setPrevActiveChannel] = useState(activeChannel);
	const { open, close } = useModal();
	const IS_STRATOS_ENABLED = window._ENV_.REACT_APP_STRATOS_ENABLED;
	const { isAuthenticated } = useAuth0();

	const { isFetching } = useQuery('channels', GET_CHANNELS, {
		staleTime: 15 * 60 * 1000,
		enabled: IS_STRATOS_ENABLED && isAuthenticated,
		onSuccess: (data) => {
			if (data.length) {
				setChannels(data);
				// check if the active channel from cache exists
				const active = data?.find(({ name }) => name === activeChannel);

				// if no active channel was set, default set it to the first channel in the list
				if (!active) {
					const channel = data?.[0]?.name;
					setActiveChannel(channel || '');
				}
			}
		},
	});

	useEffect(() => {
		// active channel changed
		if (activeChannel && activeChannel !== prevActiveChannel) {
			setPrevActiveChannel(activeChannel);
			open(
				<Modal.Root>
					<Modal.Content>
						<Modal.Title>
							<span className="block text-center">Switching channel</span>
						</Modal.Title>
						<div className="flex flex-wrap justify-center text-center space-y-8">
							<CircularProgress
								onAnimationEnded={() => setCounter((i) => i + 1)}
							/>
							<Text className="w-full">
								Just a moment please. This won&apos;t take long.
							</Text>
						</div>
					</Modal.Content>
				</Modal.Root>
			);

			resetStrategyStore();
		}
	}, [activeChannel, prevActiveChannel]);

	useEffect(() => {
		// close the loading modal after 2 iterations of the spinner
		if (counter >= 2) {
			close();
			setCounter(0);
		}
	}, [counter]);

	if (!IS_STRATOS_ENABLED) {
		return children;
	}

	if (isFetching && !activeChannel) {
		return (
			<div className="min-h-screen flex justify-center items-center">
				<CircularProgress />
			</div>
		);
	}

	return children;
};

ChannelProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { ChannelContext, ChannelProvider };
