import clsx from 'clsx';
import PropTypes from 'prop-types';

const InputWithLabel = ({
	label,
	htmlFor,
	children,
	labelPosition,
	className,
	labelClassName,
}) => (
	<div
		className={clsx(
			'text-ca-gray focus-within:text-ca-black',
			labelPosition === 'same-line' && 'flex items-center',
			labelPosition === 'label-above-input' && 'flex flex-col gap-1 -ml-1',
			className
		)}
	>
		<p
			className={clsx(
				'text-sm',
				labelClassName,
				clsx(labelPosition === 'label-above-input' && 'ml-1')
			)}
		>
			<label htmlFor={htmlFor}>{label}</label>
		</p>
		{children}
	</div>
);

InputWithLabel.propTypes = {
	labelPosition: PropTypes.oneOf(['same-line', 'label-above-input']),
	label: PropTypes.node.isRequired,
	htmlFor: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	labelClassName: PropTypes.string,
};

InputWithLabel.defaultProps = {
	labelPosition: 'same-line',
	className: '',
	labelClassName: '',
};

export default InputWithLabel;
