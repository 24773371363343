import { FunctionComponent } from 'react';
import useStrategyStore from '../../data/strategies';

import ActualsIcon from '../Icons/Actual';
import BarChartIcon from '../Icons/BarChart';
import StrategyIcon from '../Icons/Strategy';
import ValidationIcon from '../Icons/Validation';
import Sidebar, { SidebarLinkType } from '../Sidebar/Sidebar';

const StratosSidebar: FunctionComponent = () => {
	const { activeStrategy } = useStrategyStore();
	const links: SidebarLinkType[] = [
		{
			id: 'actuals',
			label: 'Actuals',
			to: '/actuals',
			icon: <ActualsIcon />,
			isEnabled: window._ENV_.REACT_APP_ACTUALS_ENABLED,
		},
		{
			id: 'strategy',
			label: 'Strategy',
			to: '/strategy',
			icon: <StrategyIcon />,
			isEnabled: window._ENV_.REACT_APP_STRATEGY_ENABLED,
		},
		{
			id: 'recommendations',
			label: 'Recommendations',
			to: '/recommendations',
			icon: <BarChartIcon />,
			isEnabled: window._ENV_.REACT_APP_RECOMMENDATIONS_ENABLED,
			disabled: activeStrategy === null,
			tooltip:
				activeStrategy === null
					? 'You must create a strategy before you can view recommendations'
					: undefined,
		},
		{
			id: 'support',
			label: 'Data Validation',
			to: '/support',
			icon: <ValidationIcon />,
			isEnabled: false,
		},
		{
			id: 'global',
			label: 'Global',
			to: '/global',
			icon: <ActualsIcon />,
			isEnabled: false,
		},
	];

	return <Sidebar activePlatform="Markdown" links={links} />;
};

export default StratosSidebar;
