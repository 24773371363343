import { Redirect, Route } from 'react-router-dom';

import { useState } from 'react';
import { FilterMap } from '../../shared/components/Filter/FullFilterMenu.types';
import Page from '../../shared/components/Page/Page';
import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import ActualsHeader from '../components/ActualsHeader';
import ActualsInventory from './ActualsInventory';
import ActualsSales from './ActualsSales';
import ActualsYoYComparison from './ActualsYoYComparison';

// TODO WARD: rework redirects from legacy paths during react 18 upgrade ticket
const ActualsView = () => {
	const [filters, setFilters] = useState<FilterMap>(new Map());

	return (
		<Page>
			<ActualsHeader filters={filters} setFilters={setFilters} />
			<RouterSwitch>
				<Redirect from="/actuals" to="/actuals/inventory" exact />
				<Redirect from="/actuals/product" to="/actuals/inventory" exact />
				<Route
					exact
					path="/actuals/inventory"
					component={() => ActualsInventory({ filters })}
				/>
				<Route
					exact
					path="/actuals/sales"
					component={() => ActualsSales({ filters })}
				/>

				<Route
					exact
					path="/actuals/yoycomparison"
					component={() => ActualsYoYComparison({ filters })}
				/>
			</RouterSwitch>
		</Page>
	);
};

export default ActualsView;
