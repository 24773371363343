import PropTypes from 'prop-types';

const Repeat = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 16 19"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m14 21.1918265c0 3.057548-2.692 6.1150959-6 6.1150959s-6-2.7436397-6-6.1150959 2.692-6.1150959 6-6.1150959v2.0383653l5-3.0575479-5-3.057548v2.0383653c-4.418 0-8 3.6507123-8 8.1534612 0 4.502749 3.582 8.1534612 8 8.1534612s8-3.0575479 8-8.1534612z"
			transform="translate(0 -11.029191)"
			fill="currentColor"
		/>
	</svg>
);

Repeat.propTypes = {
	className: PropTypes.string,
};

Repeat.defaultProps = {
	className: '',
};

export default Repeat;
